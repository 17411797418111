import './index.css'

function Blog(){
  return(
    <div>
        blog teste
    </div>
  )
}

export default Blog;