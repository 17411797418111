import './index.css';
import './responsive-termos.css'
import Header from '../../components/header';
import Footer from '../../components/footer';

function termosEcond(){
  return (
    <div className='body_termospage'>
      <Header backgroundcolor="#2d2d2f"/>

      
      <h1 className='title_termospage'>Políticas de uso do site</h1>

      <div className="container_text_body">
        <h1>TERMOS E CONDIÇÕES DE USO</h1>
        <p>
          São partes deste instrumento, de um lado, SGMOBILE SERVIÇOS,
          CONSULTORIA E COMÉRCIO DE PRODUTOS DE TI LTDA, pessoa jurídica de
          direito privado, com sede social na Av. Domingos de Moraes 2187 Cj 706
          Torre Paris, bairro Vila Mariana, município de São Paulo/SP, CEP:
          04035-000, inscrita no CNPJ 23.429.752/0001-78, doravante denominada
          simplesmente SGMobile e, do outro, o USUÁRIO, pessoa jurídica, assim
          doravante designado.
        </p>
        <p> - </p>

        <p>CONSIDERANDO QUE:</p>

        <p> - </p>

        <p>
          
          A SG MOBILE oferece aos USUÁRIOS, a opção da aquisição de produtos e
          serviços através do aplicativo e web site mysimtravel.com.br;
        </p>

        <p> - </p>

        <p>
          O USUÁRIO deseja receber os produtos por ele escolhidos na forma
          oferecida pela SGMobile neste termo.
        </p>

        <p> - </p>

        <p>
          As partes acima identificadas e qualificadas resolvem firmar o
          presente acordo, que se regerá pelas seguintes cláusulas e condições:
        </p>

        <p> - </p>

        <h1>CLÁUSULA PRIMEIRA - APLICATIVO E SITE</h1>

        <p>
          1.1. Ao se cadastrar no aplicativo ou no Site da SGMobile, o USUÁRIO
          terá a sua disposição ferramentas para aquisição de soluções, produtos
          e serviços, que permitem aos usuários utilizar, nos países de
          cobertura específica, serviços de comunicação móvel por VOZ, TEXTO e
          DADOS, interagir com aplicativos, bem como, acessar dados de mapas,
          navegação na web e sua conta de e-mail, fazer a gestão de seus saldos
          e consumos.
        </p>

        <p>
          1.2. A aquisição de produtos ou serviços por meio do aplicativo ou
          site poderá ser realizada por pessoas físicas, nacionais ou
          estrangeiras, com idade igual ou superior a 18 (dezoito) anos.
        </p>

        <p>
          1.3. Neste ato o Usuário, sob as penas da lei, declara não se
          enquadrar em qualquer hipótese impeditiva, legal ou contratual, para
          cadastrar-se no aplicativo.
        </p>

        <h1>CLÁUSULA SEGUNDA - DO CADASTRO</h1>

        <p>
          2.1. O USUÁRIO declara que leu e concorda com todos os termos e
          condições previstos neste instrumento.
        </p>
        <p>
          2.2. A partir do cadastro no site ou aplicativo, a SGMobile procederá
          à checagem das informações cadastradas pelo USUÁRIO.
        </p>
        <p>
          2.3. A SGMobile reserva-se no direito de excluir o USUÁRIO do programa
          na hipótese de qualquer indício de fraude ou irregularidade no
          cadastro, bem como insuficiência de informações.
        </p>
        <p>
          2.4. O USUÁRIO reconhece que a atribuição de confiabilidade e
          segurança às transações eletrônicas é um ativo intangível do Serviço.
          PORTANTO, A SGMOBILE RESERVA-SE O DIREITO DE UTILIZAR TODOS OS MEIOS
          VÁLIDOS E POSSÍVEIS PARA, SE ENTENDER NECESSÁRIO E A QUALQUER TEMPO,
          CONFIRMAR OS DADOS FORNECIDOS PELO USUÁRIO QUANDO DE SEU CADASTRAMENTO
          E A SITUAÇÃO ECONÔMICO-FINANCEIRA E DE CRÉDITO, ESPECIALMENTE PARA
          ASSEGURAR O CUMPRIMENTO DAS TRANSAÇÕES COMERCIAIS QUE VIEREM A SER
          CONTRATADAS NOS TERMOS DESTE CONTRATO, ESTANDO AUTORIZADA A CONSULTAR
          BANCOS DE DADOS MANTIDOS POR TERCEIROS E BASES DE RESTRIÇÕES
          CREDITÍCIAS, TAIS COMO SPC E SERASA. Caso a SGMOBILE constate haver,
          entre as informações fornecidas pelo USUÁRIO, informações incorretas,
          incompletas e/ou inverídicas, caso o USUÁRIO deixe de enviar os
          documentos e esclarecimentos solicitados, ou caso, ainda, a SGMobile
          constate haver restrições à situação econômico-financeira e/ou ao
          crédito do USUÁRIO, está autorizada poderá bloquear a aquisição de
          produtos pelo USUÁRIO.
        </p>
        <p>
          2.5. O USUÁRIO é responsável pela atualização permanente de seus dados
          cadastrais.
        </p>
        <p>
          2.6. O USUÁRIO, para utilizar os serviços aqui descritos, deverá ter
          capacidade jurídica para atos civis e deverá, necessariamente, prestar
          as informações exigidas no cadastro, assumindo integralmente a
          responsabilidade (inclusive cível e criminal) pela exatidão e
          veracidade das informações fornecidas no cadastro.
        </p>

        <p>
          2.7. Após a validação do cadastro, o USUÁRIO será liberado para o uso
          do APLICATIVO.
        </p>
        <h1>CLÁUSULA TERCEIRA - DO FUNCIONAMENTO DA COMPRA</h1>

        <p>
          3.1. O USUÁRIO cadastrado no Site ou Aplicativo, ao aderir ao presente
          termo, terá uma senha (password) para o uso do Serviço, a qual deverá
          ser informada, juntamente com o login cadastrado, toda vez que desejar
          utilizar as funcionalidades do aplicativo ou Site. O USUÁRIO obriga-se
          a manter sua senha em sigilo e a não a revelar a qualquer terceiro.
        </p>
        <p>
          3.2. O USUÁRIO obriga-se a tomar todas as precauções necessárias a fim
          de evitar que terceiros utilizem o Serviço em seu nome. O USUÁRIO
          concorda em responsabilizar-se pelas Transações Comerciais e
          Pagamentos feitos em seu nome por terceiros, ainda que sem sua
          autorização, caso esses terceiros tenham, sem culpa exclusiva da
          SGMobile, tido acesso à senha ou a outros dados do USUÁRIO.
        </p>
        <p>
          3.3. As compras dos produtos poderão ser pagas através do aplicativo
          ou Site, nos casos de pagamento com cartão de crédito, débito e boleto
          bancário.
        </p>

        <p>
          3.4. O USUÁRIO se obriga, a pagar integralmente o preço dos
          produtos/serviços por si solicitados e entregues a si ou a terceiros
          por sua ordem, seja pela modalidade on-line, seja por qualquer outra
          forma, diretamente ao portador, dos produtos encomendados por meio do
          site.
        </p>

        <p>
          3.5. A SGMobile se reserva no direito de excluir soluções, produtos e
          serviços deste programa, a qualquer tempo e a seu critério.
        </p>

        <p>
          3.6. A entrega do chip mysimtravel será feita por um dos
          Distribuidores da SGMobile, quando o produto for adquirido dentro do
          estabelecimento destes, ou por correio, no endereço do USUÁRIO, quando
          a compra ocorrer sem vinculação aos distribuidores SGMobile.
        </p>

        <p>
          3.10. Existindo custos com frete, serão pagos pelo USUÁRIO e
          informados no aplicativo ou Site, no momento de aquisição do produto.
        </p>

        <p>
          3.11. O status dos pedidos, finalizados pelos USUÁRIOS, poderá ser
          consultado no aplicativo ou Site.
        </p>

        <p>
          3.12. Para estabelecer contato entre SGMobile e o USUÁRIO, fica
          disponibilizado o endereço eletrônico deste link da internet
          www.mysimtravel.com.br e telefone, sendo certo que o USUÁRIO se
          obriga, igualmente, a manter em seu cadastro endereço eletrônico atual
          por intermédio do qual se farão as comunicações a ele dirigidas pelo
          SGMobile, desde logo emprestando-se validade jurídica e efetividade a
          esse meio eletrônico de troca de informações recíprocas.
        </p>

        <p>
          
          3.13. O USUÁRIO RECONHECE E CONCORDA QUE A SGMOBILE NÃO SERÁ
          RESPONSÁVEL, PELO CONTEÚDO, PROPAGANDA, PRODUTOS, SERVIÇOS OU OUTROS
          MATERIAIS CONTIDOS OU DISPONIBILIZADOS POR ANUNCIANTES, ATRAVÉS DE
          LINKS CONTIDOS NO SITE, NO APLICATIVO OU ONDE O SERVIÇO ESTÁ SENDO
          DISPONIBILIZADO.
        </p>

        <h1>CLÁUSULA QUARTA - RESPONSABILIDADE</h1>

        <p>
        4.1. A SGMOBILE ACONSELHA AOS USUÁRIOS QUE MANTENHAM OS SEUS ANTIVÍRUS ATUALIZADOS E UTILIZEM FIREWALL, DIMINUINDO CONSIDERAVELMENTE AS CHANCES DE TEREM SEUS DADOS INTERCEPTADOS POR PESSOAS MAL INTENCIONADAS.

        </p>

        <p>
        4.2. Tendo em vista as características inerentes ao ambiente da Internet, a SGMobile não se responsabiliza por interrupções ou suspensões de conexão ocasionadas por caso fortuito ou de força maior, bem como por falhas, interrupções, suspensões de conexão, configurações e/ou mau funcionamento eletrônico de qualquer rede, “hardware”ou “software”, disponibilidade e acesso à internet por parte do USUÁRIO, assim como qualquer informação incorreta ou incompleta fornecida por este no seu cadastro.

        </p>

        <p>
        4.3. O USUÁRIO declara, reconhece e aceita como de sua responsabilidade e risco de operar o sistema e utilizar os serviços. O USUÁRIO atesta que utiliza sua própria escolha livre e desimpedida para participar voluntariamente nesta atividade. O USUÁRIO se compromete a tomar o devido cuidado durante a compartilhamento, participação, uso e/ou operação dos serviços. O USUÁRIO reconhece, compreende e tem consciência de todos os riscos que se relacionam ao uso dos SERVIÇOS.

        </p>

        <p>

        4.4. A SGMobile não se responsabilizará, em hipótese alguma, por roubo, perdas ou danos relacionados ao uso dos serviços.

        </p>

        <p>

          
4.5. A SGMobile não poderá ser responsabilizada por qualquer falha ou não funcionamento dos serviços provenientes de terceiros ou decorrentes de caso fortuito ou força maior.

        </p>

        <p>

        4.6. O USUÁRIO isenta a SGMobile de toda responsabilidade, a qualquer título, por quaisquer danos, tais como danos materiais, pessoais, morais, estéticos, que venha a causar a si ou a terceiros, ou que venha a sofrer em razão de quaisquer eventos ocorridos ou relacionados ao uso do aplicativo ou dos serviços.


        </p>

        <p>

        4.7. O Site e o Aplicativo permitem que o USUÁRIO acesse e veja uma variedade de conteúdos, inclusive, sem limitações, imagens fotográficas, textos, dados de mapas e terrenos, listagens de empresas, comentários, tráfego e outras informações relacionadas fornecidas pela SGMobile, seus licenciantes e seus usuários (o “Conteúdo”). O USUÁRIO entende e concorda com o seguinte: (a) dados de mapas, trânsito, rotas e conteúdo relacionado são fornecidos somente para fins de referência e a SGMobile se exime de qualquer tipo de responsabilidade no que se refere aos serviços prestados. Ademais, é possível que as condições do tempo, obras, interdições ou outros eventos alterem as condições das estradas ou as rotas sejam diferentes dos resultados do mapa. O USUÁRIO deve usar o bom senso ao usar esse Conteúdo; (b) imagens, textos e conteúdos de terceiros são fornecidos sem qualquer tipo de responsabilidade por parte da SGMobile. Caso o USUÁRIO se sinta ofendido ou infrinja direito próprio ou de terceiros, requer a SGMobile que seja imediatamente notificada, a fim de que tome as devidas providências perante os devidos responsáveis; (c) Determinado Conteúdo pode ser fornecido nos termos de licença de outras sociedades e empresas que não sejam a SGMobile, e está sujeito aos direitos autorais e a outros direitos de propriedade intelectual detidos por outros terceiros. O USUÁRIO poderá ser responsabilizado por qualquer cópia ou divulgação não autorizada do Conteúdo.

        </p>


        <h1>CLÁUSULA QUINTA - PRIVACIDADE DA INFORMAÇÃO</h1>

        <p>

        5.1. A SGMobile tomará todas as medidas possíveis para manter a confidencialidade e a segurança das compras efetuadas, porém não responderá por prejuízo que possa ser derivado da violação dessas medidas por parte de terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações de influenciadores e clientes.

        </p>

        <h1>
          CLÁUSULA SEXTA - VIOLAÇÃO NO SISTEMA OU BASE DE DADOS
        </h1>

        <p>
        6.1. Não é permitida a utilização de nenhum dispositivo, software, ou outro recurso que venha a interferir nas atividades e operações dos produtos ou serviços da SGMobile, bem como nas publicações das ofertas, descrições, contas ou seus bancos de dados.

        </p>

        <p>
        6.2. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste termo, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.

        </p>

        <p>

        6.3. A SGMobile se reserva no direito de comunicar as autoridades, sem aviso prévio ao USUÁRIO, caso constate qualquer indício de fraude ou violação de segurança do aplicativo ou Site.

        </p>

        <h1>CLÁUSULA SÉTIMA - DISPOSIÇÕES GERAIS</h1>

        <p>7.1. Este Termo de Adesão vigorará por tempo indeterminado, a contar da aceitação do USUÁRIO.
</p>

        <p>
        7.2. A qualquer tempo, qualquer das partes pode denunciar este ajuste imotivadamente, bastando que comunique a outra parte por escrito ou através dos canais de atendimento.

        </p>

        <p>

        7.3. O USUÁRIO declara que avaliou todos os termos e condições do presente instrumento, e que aceita e concorda todas as disposições aqui contidas. Reconhece, também, que a SGMobile poderá, a qualquer tempo, solicitar informações do USUÁRIO, por qualquer motivo que seja.

        </p>

        <p>

        7.4. O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços disponibilizados.

        </p>

        <p>

        7.5. As novas condições entrarão em vigência assim que sejam veiculadas no site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos termos modificados, desde que o faça por escrito, através do site SGMobile, o que gerará o cancelamento automático de seu CADASTRO.


        </p>

        <h1>CLAÚSULA OITAVA: Do Foro</h1>

        <p>8.1. As partes elegem o Foro do domicílio de São Paulo – SP, como competente para dirimir quaisquer dúvidas ou controvérsias oriundas deste instrumento.
</p>


        <p>SGMobile – Soluções Globais para Mobilidade
</p>

        <p>Distribuidor Autorizado para BRASIL e AMÉRICA LATINA
</p>  
        <p>Chip Internacional mysimtravel
</p>

        <h2>Copyright © 2020 SGMobile, All rights reserved
</h2>
        <h2>Copyright © 2020 mysimtravel, All rights reserved.</h2>

      
      </div>

      <Footer />
    </div>
  );
}

export default termosEcond;