import './index.css';
import './responsive-aeroportos.css';
import Header from '../../components/header';
import Footer from '../../components/footer';

function Airport() {

  function animation(id){

    const accordionItemHeaders = document.getElementById(`${id}`);console.log(accordionItemHeaders)

        
        accordionItemHeaders.classList.toggle("active");
        const accordionItemBody = accordionItemHeaders.nextElementSibling;
        if(accordionItemHeaders.classList.contains("active")) {
          accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
        }
        else {
          accordionItemBody.style.maxHeight = 0;
        }

}



  return(
    <div>
      <Header/>
      <div className="midlow-mapa-revendas-aero">
      <div className="text-midlow-mapa-aero">
        <h1 className="h1midlow-mapa-aero">Revendas Autorizadas</h1>
        <p className="p-midlow-mapa-aero">O mysimtravel conta com +700 representantes no Brasil e no mundo!<br/>Encontre a loja mais próxima de você!</p>
      </div>
      <div className="map-midlow-mapa-aero">
        <img className="map-img-mapa-aero" src="/assets/img/Mapa_banner_site.svg" alt="Mapa Revendas"/>
      </div>
    </div>

  
    <div class="main-container">

    <div class="info-mid-aer">
      <div class="info-text">
        <h1 class="info-h1">
          Compre online e  <span class="info-span">retire no Aeroporto</span>
        </h1>
        <p>Cliente mysimtravel também consegue efetuar a compra online e retirar o seu chip nos aeroportos parceiros, confira a nossa lista abaixo:</p>
      </div>
    </div>

      <div class="accordion-aero">

        {/* <div class="accordion-item-aero">
          <div class="accordion-item-header-aero" id="aeroporto-guarulhos" onClick={() => {animation("aeroporto-guarulhos")}}>
            <h1>Aeroporto de Guarulhos</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="/assets/img/GRU.svg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional de Guarulhos - GRU</h2>
                  <h3>São Paulo - SP</h3>
                  <h3><span class="info-span">Endereço:</span> Terminal T2 OESTE - Térreo Area Pública Externa</h3>
                  <h3><span class="info-span">Loja:</span> Guarda Volumes - Malex</h3>
                  <h3><span class="info-span">Horário:</span> Segunda a Domingo - 24 horas</h3>
                  <h3><span class="info-span">Telefone:</span> (11) 2445-2501</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/mysimtravelgrut3"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero"  id="aeroporto-viracopos" onClick={() => {animation("aeroporto-viracopos")}}>
          <h1>Aeroporto de Viracopos</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="https://parquedpedro.com.br/lojas_files/19166.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional de Viracopos - VCP</h2>
                  <h3>Campinas - SP</h3>
                  <h3><span class="info-span">Endereço:</span> Parque Viracopos - Portão de embarque nacional</h3>
                  <h3><span class="info-span">Loja:</span> LIKE CELL (Acessórios para Celular)</h3>
                  <h3>
                  <span class="info-span">Horário:</span> Segunda a Sábado – 6:00 às 20:00 / Domingo 8:00 às 20:00<br/>
                  </h3>
                  <h3><span class="info-span">Telefone:</span> (19) 98460-6969</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/mysimtravel-vcp"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero" id="aeroporto-brasilia" onClick={() => {animation("aeroporto-brasilia")}}>
          <h1>Aeroporto de Brasília</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="/assets/img/bsbBrasilia2.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional de Brasilia - BSB</h2>
                  <h3>Brasília - DF</h3>
                  <h3><span class="info-span">Endereço:</span> Guarda Volumes Praça Picap - Área externa</h3>
                  <h3><span class="info-span">Loja:</span>Security Bag</h3>
                  <h3>
                  <span class="info-span">Horário:</span> Seg à Sex: 24:00hrs <br/> Sáb, Dom, Feriados: das 06 às 22hrs
                  </h3>
                  <h3><span class="info-span">Telefone:</span> (61) 9 9620-8037</h3>
                  <h3><span class="info-span">Operação:</span> VENDA E ENTREGA CHIP</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/aerop_bsb_simfnity"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero" id="aeroporto-santos-dumont" onClick={() => {animation("aeroporto-santos-dumont")}}>
          <h1>Aeroporto de Santos Dummont</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="http://vaiviajaristepo.com/wp-content/uploads/2017/10/dscn1753-1024x768.jpg?w=474"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional Santos Dumont - SDU</h2>
                  <h3>Rio de Janeiro - RJ</h3>
                  <h3><span class="info-span">Endereço:</span> Área de embarque - Próximo entrada Check-In</h3>
                  <h3><span class="info-span">Loja:</span> Guarda Volumes - Malex</h3>
                  <h3><span class="info-span">Horário:</span> (Funcionamento do Aeroporto)</h3>
                  <h3><span class="info-span">Telefone:</span> (21) 3814-7606</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/malexsdu"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero" id="aeroporto-galeao" onClick={() => {animation("aeroporto-galeao")}}>
          <h1>Aeroporto do Galeão - RJ</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="https://www.falandodeviagem.com.br/imagens21/GuardaVolumeGIG1.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional TOM JOBIM - GIG</h2>
                  <h3>Rio de Janeiro - RJ</h3>
                  <h3><span class="info-span">Endereço:</span> Terminal 2 - Desembarque Internacional</h3>
                  <h3><span class="info-span">Loja:</span> Guarda Volumes - Malex</h3>
                  <h3><span class="info-span">Horário:</span> Segunda a Domingo - das 06:00 às 23:00</h3>
                  <h3><span class="info-span">Telefone:</span> (11) 98734-1818</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/malexgig"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero"  id="aeroporto-salvador" onClick={() => {animation("aeroporto-salvador")}}>
          <h1>Aeroporto de Salvador</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="/assets/img/salvador-aero.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional de Salvador - SSA</h2>
                  <h3>Salvador - BA</h3>
                  <h3><span class="info-span">Endereço:</span> Praça Gago Coutinho, S / N - Bairro São Galvão - Salvador - BA - CEP 41510-045</h3>
                  <h3><span class="info-span">Loja:</span> Guarda Volumes MALEX</h3>
                  <h3>
                    <span class="info-span">Horário de atendimento:</span> Segunda a Sexta - Das 08:00 às 17:00 Hrs
                  </h3>
                  <h3><span class="info-span">Telefone:</span> (73) 98245-6616</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/aerop-ssa"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero" id="aeroporto-confins" onClick={() => {animation("aeroporto-confins")}}>
          <h1>Aeroporto de Confins</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content-aero">
              <div class="accordion-item1-aero">
                <img
                  class="gru-img"
                  src="https://www.drogarias.net/imgempresas/air-farma-88378-H4oG.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto de Confins - CNF</h2>
                  <h3>Confins - MG</h3>
                  <h3><span class="info-span">Endereço:</span> LMG-800 Km 7,9 s/n, Confins – MG, 33500-900</h3>
                  <h3><span class="info-span">Loja:</span> AIRFARMA – Farmácia</h3>
                  <h3>
                  <span class="info-span">Horário:</span> Segunda a Sexta 06:00 às 20:00 <br/>/ Sábado: 06:00 às
                    13:00 / Domingos: 10:00 às 17:00hrs
                  </h3>
                  <h3>Telefone: (11) 98734-1818</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/mysimtravel-cnf"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item-aero">
          <div class="accordion-item-header-aero" id="aeroporto-recife" onClick={() => {animation("aeroporto-recife")}}>
          <h1>Aeroporto de Recife</h1>
          </div>
          <div class="accordion-item-body-aero">
            <div class="accordion-item-body-content recife-aero">
              <div class="accordion-item1-aero">
                <div className='gru-img'>
                <img
                  className='gru-img'
                  src="/assets/img/brasilnet.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                </div>
                <div class="div-items">
                  <h2>Aeroporto de Recife - REC</h2>
                  <h3>Recife - PE</h3>
                  <h3>
                  <span class="info-span">Endereço:</span> Desembarque Sul. Ao lado do receptivo da Luck. 
                  </h3>
                  <h3><span class="info-span">Loja:</span> BRASILNET</h3>
                  <h3>
                  <span class="info-span">Horário:</span> Segunda a Sexta: das 07:00 ás 22:00hrs
                    <br/>Sáb,Dom, Feriados:  das 08 as 16hrs
                  </h3>
                  <h3><span class="info-span">Operação:</span> VENDA E ENTREGA CHIP</h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/brasilnetrec_simfinity"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>

              <div className="accordion-item2">
                <img
                  class="gru-img"
                  src="/assets/img/viajabrasil.jpg"
                  alt="Aeroporto de Guarulhos"
                  srcset=""
                />
                <div class="div-items">
                  <h2>Aeroporto Internacional do Recife - Guararapes</h2>
                  <h3>Recife - PE</h3>
                  <h3>
                  <span class="info-span">Endereço:</span> Ala Sul - Quiosque frente ao check-in da Gol
                  </h3>
                  <h3><span class="info-span">Loja:</span> VIAJA BRASIL</h3>
                  <h3>
                  <span class="info-span">Horário:</span> Segunda a Domingo - 24 Horas
                  </h3>
                  <h3>
                  <span class="info-span">Operação:</span> VENDA E ENTREGA CHIP / Telefone: (81) 98557-0500
                  </h3>
                </div>

                <div class="button-accordion">
                  <button class="button-acc">
                    <a href="https://mystore.mysimtravel.com/viajabrasil"
                      >Comprar</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>


    

    <Footer />
    </div>
  )
}


export default Airport;