import './index.css';
import './responsive-samsungj.css';
import Header from "../../header/index";
import Footer from '../../footer/index';
import { Link, useNavigate } from 'react-router-dom';

export default function J_A(){
  const navigate = useNavigate();
  


  function link_to_models(){
    navigate("/como-funciona");
  
     setTimeout(() => {
      let divPrincipal = document.querySelector(".marcas-container-config");
      let divMostrar1 = document.querySelector("#samsung12");
  
      divPrincipal.style.display = "none";
      divMostrar1.style.display = "block";
  },50)
  
  }


    function animation(){
        // CHIP FÍSICO
let samsungModels = document.querySelector('#samsung-models'); //CONFIGURAÇÃO INICIAL
let btnModels = document.querySelector('#btnmodels'); // Botão config inicial
let samsungApp = document.querySelector("#samsungapp") // APP físico




let samsungRecharge = document.querySelector('#samsungteste')
let btnRecharge = document.querySelector('#rechargeteste')

// retornar

let btnReturn = document.querySelector('#btnreturn') 
let containerSettings = document.querySelector('#containersettings') // TELA DO CHIP FÍSICO




// dicas btn


containerSettings.style.display = "flex"


// ------------------------------------------------------------------------






// CHIP FÍSICO FUNÇÕES

btnModels.addEventListener("click", function(){
    samsungModels.style.display = "none"
    samsungApp.style.display = "block"
    samsungRecharge.style.display = "none"
    
    document.getElementById("h1text1").style.color = "#2d2d2d"
    document.getElementById("h1text2").style.color = "#ff5000"
    document.getElementById("h1text3").style.color = "#2d2d2d"
    
});


btnRecharge.addEventListener("click", function(){
    samsungModels.style.display = "none"  
    samsungApp.style.display = "none"
    samsungRecharge.style.display = "block"
    
    document.getElementById("h1text1").style.color = "#2d2d2d";
    document.getElementById("h1text2").style.color = "#2d2d2d";
    document.getElementById("h1text3").style.color = "#ff5000";
    document.getElementById("h1textdicas").style.color = "#2d2d2d";
});

btnReturn.addEventListener("click", function(){
    samsungModels.style.display = "block"  
    samsungApp.style.display = "none"
    samsungRecharge.style.display = "none"
    
    document.getElementById("h1text1").style.color = "#ff5000";
    document.getElementById("h1text2").style.color = "#2d2d2d";
    document.getElementById("h1text3").style.color = "#2d2d2d";
    document.getElementById("h1textdicas").style.color = "#2d2d2d";
});

     }

     function accordionIphone(id){

        const accordionItemHeaders = document.getElementById(`${id}`);console.log(accordionItemHeaders)
  
            
            accordionItemHeaders.classList.toggle("active");
            const accordionItemBody = accordionItemHeaders.nextElementSibling;
            if(accordionItemHeaders.classList.contains("active")) {
              accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
            }
            else {
              accordionItemBody.style.maxHeight = 0;
            }
  
    }
   
     setTimeout(() => {
       animation()
    },10)

    return(
        <div>
            
        <div class="content-config">
        <Header backgroundcolor="#2d2d2f"/>

            <div className="text_mobile_iphone7_top">
                <h1>Samsung</h1>
                <h2>Tutorial para configuração Samsung Linha J</h2>
                <p>Suporte e dicas para configurar seu celular antes da viagem</p>
            </div>
         <div class="config-div">
            <div class="config-text">
            <h1>Samsung</h1>
            <h2>Tuturial para configuração Samsung linha J</h2>
            <p>Suporte e dicas para configurar seu celular antes da sua viagem</p>
            </div>

            <div class="config-samsung2">
            <img src="/assets/img/config-samsung/samsung-mockupconfig.svg" alt="Mockup config Iphone"/>
            </div>
         </div>
        </div>

        <div class="container-config">
            <div class="links-config"><Link to="/como-funciona">Configuração</Link><span> {">"} </span>  <div onClick={link_to_models}>Modelos </div> <span> {">"} </span>    <Link to="" class="spanlink">SAMSUNG LINHA J</Link></div>

            <div class="config-content">
            <h1 class="textprimary">Configure seu Samsung modelos J | A para utilizar o <span class="span-orange">mysimtravel</span></h1>
            
            <div class="flex-grid">
                
                <div class="fisico" id="divfisico">
                <h1 id="chipfisico">Chip Físico</h1>
                </div>

            </div>
            </div>




            <div class="settings js-scroll" id="containersettings">
                    <div class="settings-text">
                        <div class="inicial ativo" id="btnreturn">
                        <h1 class="text-h1" id="h1text1">Configuração inicial</h1>
                        </div>


                        <div class="app" id="btnmodels">
                        <h1 id="h1text2">Ativação pelo app mysimtravel FOR YOU</h1>
                        </div>

                        <div class="recarga" id="rechargeteste">
                        <h1 id="h1text3">Recarga</h1>
                        </div>
                    </div>

                    <div class="settings-models" id="samsung-models">

                        <h1 class="roaming">Ativação Roaming</h1>

                        <div class="settings-instru">
                        <div class="config-1">
                        <h1>1 - Vá em <span>Configurações</span> e depois <span>Conexões</span></h1>
                        <div class="imgconfig-1">
                            <img src="/assets/img/config-samsung/config-1.svg" alt="Print config 1"/>
                            </div>
                        </div>
                        <div class="config-2">
                            <h1>2 - Selecione <span>Redes Móveis</span> e ative o <span>Dados em Roaming</span></h1>
                            <div class="imgconfig-2">
                            <img src="/assets/img/config-samsung/config-2.svg" alt="Print config 2"/>
                            <img src="/assets/img/config-samsung/config-4.svg" alt="Print config 3"/>
                            </div>
                            </div>

                        <div class="config-3">
                            
                            <h1>3 - Depois volte em <span>Conexões</span> e vá em <span>Gerenc. de cartão SIM</span> e lembre-se de deixar o mysimtravel <span>SEMPRE</span> no SIM1 durante a viagem, conforme a imagem abaixo:</h1>
                            <div class="imgconfig-3">
                                <img src="/assets/img/config-samsung/config-5.svg" alt="Print config 5"/>
                                <img src="/assets/img/config-samsung/config-6.svg" alt="Print config 6"/>
                            </div>
                        </div>

      
                          </div>


                    </div>

                    

                    <div class="settings-app" id="samsungapp">
                        <h1 class="configapp">Configuração Aplicativo</h1>
                        <div class="settings-appins">
                        <div class="app-1">
                        <h1>1 - Faça o login com <span>email (ou  nº do chip) e a senha cadastrada no ato da compra</span></h1>
                        <div class="imgapp-1">
                            <img src="/assets/img/config-samsung/linha-j/app-1.svg" alt="Print app 1"/>
                            </div>
                        </div>
                        <div class="app-2">
                            <h1>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado) E para ativar o plano selecione <span>Tarifas</span></h1>
                            <div class="imgapp-2">
                            <img src="/assets/img/config-samsung/linha-j/app-2.svg" alt="Print app 2"/>
                            </div>
                            </div>

                            <div class="app-3">
                            <h1>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span>Barra de pesquisa</span> ou <span>procurar na lista de países</span></h1>
                            <div class="imgapp-3">
                                <img src="/assets/img/config-samsung/linha-j/app-3.svg" alt="Print app 3"/>
                                
                                </div>
                            </div>

                            <div class="app-3">
                                <h1>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na <span>etapa 2 ou 3)</span></h1>
                                <div class="imgapp-3">
                                <img src="/assets/img/config-samsung/linha-j/app-4.svg" alt="Print app 3"/>
                                <img src="/assets/img/config-samsung/linha-j/app-5.svg" alt="Print app 4"/>
                                </div>
                                </div>

                                <div class="app-3">
                                <h1>5 - <span>Atenção: esse procedimento deve ser feito somente no dia da viagem</span><br/>Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative seu plano</h1>
                                <div class="imgapp-3">
                                    <img src="/assets/img/config-samsung/linha-j/app-6.svg" alt="Print app 6"/>
                                    <img src="/assets/img/config-samsung/linha-j/app-7.svg" alt="Print app 7"/>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="recarga-setting" id="samsungteste">
                        <h1 class="configrecargas">Recarga</h1>
                        <div class="settings-recarga1">

                            <div class="recarga-1">
                                <h1>1 - Na tela inicial, vá em <span>Comprar créditos</span> no menu inferior</h1>
                                <div class="imgrecarga-1">
                                    <img src="/assets/img/config-samsung/linha-j/recarga-1.svg" alt="Print app 1"/>
                                </div>
                            </div>

                        <div class="recarga-2">
                            <h1>2 - Selecione o nº do chip que gostaria de recarregar</h1>
                            <div class="imgapp-2">
                               <img src="/assets/img/config-samsung/linha-j/recarga-2.svg" alt="Print app 2"/>
                            </div>
                        </div>

                        <div class="recarga-3">
                            <h1>3 - Insira o valor de acordo com o <span>plano que deseja recarregar</span> e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no máximo 5 minutos</h1>
                            <div class="imgapp-3">
                                <img src="/assets/img/config-samsung/linha-j/recarga-3.svg" alt="Print app 3"/>
                            </div>

                            <h1>Pronto, agora basta renovar o seu plano novamente, qualquer dúvida te ajudamos por <a href="#">aqui</a></h1>
                        </div>  

                        </div>
                    </div>
    </div>
          </div>


          <div className="view_mobile">
            <h1 className="mobile_text_paragrafo">Configure seu Samsung para utilizar o <span>mysimtravel</span> </h1>
          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="configinicialiphone7" onClick={ () => {accordionIphone("configinicialiphone7")}}>
                <h1>Configuração inicial</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação em Roaming</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Vá em <span className="mobile_iphone7_span">Configurações</span> e depois <span className="mobile_iphone7_span">Conexões</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/config-1.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione <span className="mobile_iphone7_span">Redes Móveis</span> e ative o <span className="mobile_iphone7_span">Dados em Roaming</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/config-2.svg" alt="Print config 1"/>
                        <img src="/assets/img/config-samsung/config-4.svg" alt="Print config 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Depois volte em <span className="mobile_iphone7_span">Conexões</span> e vá em <span className="mobile_iphone7_span">Gerenc. de cartão SIM</span> e lembre-se de deixar o mysimtravel <span className="mobile_iphone7_span">SEMPRE</span> no SIM1 durante a viagem, conforme a imagem abaixo:</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/config-5.svg" alt="Print config 5"/>
                        <img src="/assets/img/config-samsung/config-6.svg" alt="Print config 6"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="ativacaopeloapp7" onClick={ () => {accordionIphone("ativacaopeloapp7")}}>
                <h1>Ativação pelo app mysimtravel FORYOU</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação pelo aplicativo</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Faça o login com o email (ou n° do chip) e a senha cadastrada no ato da compra</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/app-1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado)
E para ativar o plano selecione Tarifas</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/app-2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span className="mobile_iphone7_span">Barra de pesquisa</span> ou <span className="mobile_iphone7_span">procurar na lista de países</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/app-3.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na etapa 2 ou 3)</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/app-4.svg" alt="Print app 1"/>

                        <img src="/assets/img/config-samsung/linha-j/app-5.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Atenção: esse procedimento deve ser feito somente no dia da viagem
Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative o seu plano</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/app-6.svg" alt="Print app 1"/>
                        <img src="/assets/img/config-samsung/linha-j/app-7.svg" alt="Print app 7"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="recargaapp7" onClick={ () => {accordionIphone("recargaapp7")}}>
                <h1>Recarga</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Recarga</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Na tela inicial, vá em <span className="mobile_iphone7_span">Comprar Créditos</span> no menu anterior</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/recarga-1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione o nº do chip que gostaria de recarregar</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/recarga-2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o valor de acordo com o plano que deseja recarregar e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no maximo 5 minutos</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/config-samsung/linha-j/recarga-3.svg" alt="Print app 1"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          </div>
          <Footer/>
        </div>
    )
}