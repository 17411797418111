import './index.css'

function MysimGroups(){
  return(
    <div>
        my sim groups teste
    </div>
  )
}

export default MysimGroups;