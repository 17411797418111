import './index.css'
import './responsive-corp.css';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import axios from 'axios';
import Reaptcha from 'reaptcha';
import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SolucoesCorp(){
  const captchaRef = useRef(null);


  const error = (message) => toast.error(message);
  

  async function send_mail(){
    let username = document.getElementById("username").value;
    let email = document.getElementById("email").value;
    let message = document.getElementById("message").value;
    let phone = document.getElementById("phone").value;

    if(username === '' | phone === '' || email === '' || message === ''){
      error("Todos os campos são obrigatórios."); return
    }


    
     await axios.post("https://api.staticforms.xyz/submit",{//Jean's access key
      name:username,
      phone:phone,
      email:email,
      message:message,
      accessKey:"9c436fd3-b6dd-49bd-8f88-6f1bd4bb9a0a",
      subject:"Formulário Soluções Corporativas do site MySimTravel."
    }) 

    
  }


  const verify = () =>{
    captchaRef.current.getResponse().then(res => {
        let button = document.getElementById("button_sent");
        button.style.display = "block"
    })

}


  return(
    <div className='body-corp'>
      <ToastContainer />
        <Header backgroundcolor="transparent"/>
        <div className='container-predios'>
          <div className='container-text'>
          <h1>Soluções corporativas <span className='span-corp'>mysimtravel</span></h1>
          <h2>Somos <span className='span-corp'>a primeira empresa</span> de SIM CARD internacional a lançar plano corporativos<br/>Pré-Pagos no Brasil, com alcance global em +150 países</h2>
          </div>
        </div>
  
            <p className='article-1'>Desenhado para atender necessidade de empresas que possuem frequentes viagens internacionais, nossos planos Corporativos Pré-Pagos possuem<br/>características especiais, fundamentadas em três pilares:</p>

            <div className='grid-selection'>
              <div className='h1-grid-corp'><h1 className='inovacao'>Inovação</h1></div>
              <div className='h1-grid-corp'><h1 className='reducao'>Redução de custos</h1></div>
              <div className='h1-grid-corp'><h1 className='gestao'>Gestão simplificada</h1></div>
            </div>
          


        <div className='section-article'>
          <div className='article-div'>
            <div>
            <img alt="" src='/assets/img/lampada.svg'/>
            <h1>Inovação</h1>
            </div>

            <ul>
              <li>Chip internacional, válido globalmente em +200 países.</li>
              <li>Chip físico (Regular, Micro e Nano) - Chip eletrônico eSIM</li>
              <li>Possibilidade de preservar seu número pessoal no Whatsapp e para 
                <span> RECEBER</span> ligações em qualquer país de nossa cobertura global.</li>
              <li>Possibilidade de manter seu número pessoal como identificador de chamadas</li>
              <li>Planos de internet de 1GB a 20GB</li>
              <li>Número Local do Brasil associado ao Chip mysimtravel (são +47 prefixos disponíveis no Brasil)</li>
              <li>Disponibilidade de Números Locais de +60 países para prover soluções de mobilidade global específicas para necessidades da sua empresa</li>
              <li>Temos acordo global com +340 operadoras de telefonia celular em todas os continentes.Somos o único SIM Card capaz de fornecer Contingência / Redundância de<br/>redes,permitindo aos nosso usuários manter conectividade permanente</li>
              <li>Sem limite de velocidades de Download / Upload de dados. Permitindo a qualidade do serviço e a necessidade de altas demandas de transferência de dados em curto<br/>espaço de tempo</li>

              <li>Use seu aplicativo mysimtravel CORP, disponivel para IOS e Android para controle e gestão dos pacotes de dados ativados em seu SmartPhone, tablet ou HotSpot.</li>

              <li>Pacotes específicos para CHINA que permitem utilizar todos os recursos ocidentais (Google, Google Maps, Waze, Facebook, etc)</li>
            </ul>
          </div>


          <div className='article-div1'>
            <div>
            <img alt="" src='/assets/img/cubo.svg'/>
            <h1>Gestão simplificada</h1>
            </div>

            <ul>
              <li>Conta para gestão corporativa de todos os SIM Card adquiridos pela empresa</li>
              <li>Aplicativos para IOS e Android para gestão individual de cada SIM Card</li>
              <li>Relatórios e extratos On Line</li>
              <li>Perfil de Administrador e gestão de usuários cadastrados</li>
              <li>Políticas de recarga automática por SIM Card</li>
              <li>Transferência de créditos de um chip para outro</li>
              <li>Gestão centralizada</li>
              
            
            </ul>
          </div>


          <div className='article-div2'>
            <div>
            <img alt="" src='/assets/img/dolar.svg'/>
            <h1>Redução de Custos</h1>
            </div>

            <ul>
              <li>Faturamento pós-pago com créditos disponibilizados imediatamente</li>
              <li>Créditos não utilizados no mês corrente, permanecem válidos para meses posteriores</li>
              <li>Plano PAY-PER-USE, sem contrato fixo e tarifas mínimas mensais</li>
              <li>Pacotes de VOZ Globais com tarifas exclusivas</li>
              <li>Tarifas de VOZ globais especiais mysimtravel</li>
            </ul>
          </div>


          <h1 className='conheca-plan'>Conheça agora as nossas Soluções</h1>
          <p className='conheca-p'>Entre com contato agora mesmo e conheça em detalhes todas as nossas opções de roaming internacional</p>

          <form action="https://api.staticforms.xyz/submit" method="post" className='form-corp'>

            <input type="hidden" name="accessKey" value="297ee6a7-18f9-47db-9713-6195585c79c9"></input>{/* Fernando's access key */}
            <input type="hidden" name="subject" value="Formulário Soluções Corporativas do site MySimTravel."></input>
            <input type="hidden" name="redirectTo" value="https://homologacao-site.mysimtravel.com/#/solucoes-corporativas"/>

          <label for="GET-name" className='label-corp'>Nome completo
          <input id="username-corp" type="text" name="name" placeholder='Nome Completo' className='input-corp' required/>
          </label>

          <label for="GET-name" className='label-corp'>Telefone
          <input id="phone-corp" type="tel" name="phone" placeholder='(XX) 9XXXX-XXXX' className='input-corp' required/>
          </label>

          <label for="GET-name" className='label-corp'>Email para Contato
          <input id="email-corp" type="email" name="email" placeholder='email@email.com' className='input-corp' required/>
          </label>

          <label for="GET-name" className='label-corp'>Digite seu Texto
          <input id="message-corp" type="text" name="message" placeholder='Digite seu texto...' className='input-text-corp' required/>
          </label>

          <Reaptcha onVerify={verify} sitekey="6LeIe0AkAAAAABO9U7cLdUbZAVY36Pwb2etFIwx3" ref={captchaRef}/>
          <button id="button_sent" className='submit-send' onClick={send_mail}>Enviar Mensagem</button>
          
          </form>

        </div>

        <Footer/>
    </div>
  )
}

export default SolucoesCorp;