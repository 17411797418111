import './index.css';
import './responsive-m2m.css'
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Reaptcha from 'reaptcha';
import { useRef } from 'react';


function M2m(){
  const captchaRef = useRef(null);

  function animation(){  
    const accordionItemHeaders = document.querySelectorAll(".accordion-item-header-m2m");
    accordionItemHeaders.forEach(accordionItemHeader => {
        
       
        accordionItemHeader.classList.toggle("active");
        const accordionItemBody = accordionItemHeader.nextElementSibling;console.log(accordionItemBody)
        if(accordionItemHeader.classList.contains("active")) {
          accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
        }
        else {
          accordionItemBody.style.maxHeight = 0;
        }
    });  
  }

  

  function toogleAnimation(){
    const accordionList = document.getElementById("sub-menu-m2m");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }
  function toogleAnimationdois(){
    const accordionList = document.getElementById("sub-menu-m2m-dois");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }
  function toogleAnimationtres(){
    const accordionList = document.getElementById("sub-menu-m2m-tres");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }
  function toogleAnimationquatro(){
    const accordionList = document.getElementById("sub-menu-m2m-quatro");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }
  function toogleAnimationcinco(){
    const accordionList = document.getElementById("sub-menu-m2m-cinco");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }
  function toogleAnimationseis(){
    const accordionList = document.getElementById("sub-menu-m2m-seis");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }
  function toogleAnimationsete(){
    const accordionList = document.getElementById("sub-menu-m2m-sete");
    console.log(accordionList);
    accordionList.classList.toggle('ativo');
  }

  


  

const verify = () =>{
        captchaRef.current.getResponse().then(res => {
            let button = document.getElementById("button_sent");
            button.style.display = "block"
        })

    }


  return(
    <div>
      <Header />
    
    <div className="iot-container">

        <img src="/assets/img/raio-chip.svg" alt="" className="raio"/>

        <div className="texto-iot">
            <h1>M2M <span className="span">mysimtravel</span></h1>
            <h2>Velos IoT / Top Connect e mysimtravel M2M & IoT é a solução perfeita para gestão e monitoração de ativos de valor distribuídos globalmente.</h2>
        </div>

        <div className="chip-iot">
        <img src="/assets/img/chip.svg" alt="Chip"/>
        </div>
    </div>

<div className='div-main'>
    
    <div className="solucoes">
        <h1>A Solução M2M/ Iot mysimtravel é <span className="span">completa</span></h1>
        <p>Nossas soluções de mobilidade oferecem conexões M2M & IOT combinando mais de 500 provedores de telecomunicações em uma única rede<br/>móvel global.</p>
    </div>


    <div className="cards-info">
        <img src="/assets/img/card-info/card-cobertura.svg" alt="" srcset=""/>
        <img src="/assets/img/card-info/card-gestao.svg" alt="" srcset=""/>
        <img src="/assets/img/card-info/card-pagamento.svg" alt="" srcset=""/>
        <img src="/assets/img/card-info/card-plano-de-dados.svg" alt="" srcset=""/>
        <img src="/assets/img/card-info/card-seguranca.svg" alt="" srcset=""/>
        <img src="/assets/img/card-info/card-chip.svg" alt="" srcset=""/>
    </div>

    <div className="info-m2m">
        <div className="info1">
            <h1 className="h1-info m2m-h1-e">Sempre a conexão mais robusta</h1>
                <div className="div-p">
            <p>Queremos que seu dispositivo esteja conectada à rede mais forte.</p>
            <p>É por isso que oferecemos roaming nacional sem impor a rede local e permitimos que seus dispositivo se conectem à melhor rede em cada
                <br/>local, sem favorecer uma operadora específica.</p>
            <p>Isso garante uma conexão forte e estável em todos os momentos</p>
            </div>
        </div>

        <div className="info2">
            <h1 className="h1-info m2m-h1-e">Preço justo, controle total</h1>
            <div className="div-p">
            <p>Ao trabalhar com <span className="span">mysimtravel M2M</span>, você se beneficia de uma estrutura de preços clara e alta flexibilidade.</p>
            <p>Nossos planos de dados para +135 países garantem economias adicionais.</p>
            <p>Você só paga pelos cartões SIM ativos.</p>
            <p>Os cartões SIM podem ser ativados e desativados a qualquer momento através do nosso portal do cliente.</p>
            </div>
        </div>

        <div className="info3">
            <h1 className="h1-info m2m-h1-e">Conecte-se em +135 países</h1>
            <div className="div-p">
            <p><span className="span">mysimtravel M2M </span>pode se conectar a mais de 350 redes em mais de 135 países diferentes.</p>
            <p>Para você, isso significa que você não precisa gerenciar contratos diferentes, portais de clientes diferentes ou pessoas de contato diferentes<br/>para cada país. Com mysimtravel M2M, você tem um pacote tudo-em-um.</p>
            
            </div>
        </div>
    </div>

    <div className="info-services">
        <div className='article-m2m'>
                <h1>Recursos de gestão avançados disponíveis para redes complexas M2M e IoT</h1>
            </div>

            <div className="list-services">
                <ul>
                    <li id='li-sub-menu-m2m' onClick={toogleAnimation}><img src='./assets/img/plus-icon.png'/>Serviço APN privado</li>
                    <p id='sub-menu-m2m'>A organização do serviço M2M padrão implica o uso de um serviço de APN padrão, provisionado para todos os chips M2M da Top Connect. <br/>Conexões diretas entre os chips em um serviço de APN padrão são proibidas e os chips são isolados uns dos outros em um espaço de IP Privado, sob o controle da Top Connect.<br/> Todo chip provisionado com um serviço de APN padrão pode acessar a Internet Pública e todos os serviços disponíveis nela!<br/>

Se o seu aplicativo M2M tiver um endereço de Internet Público e o serviço estiver configurado para coletar apenas comunicações unidirecionais (do chip para o servidor) ou se seus dispositivos Endpoint estabelecerem túneis VPN diretos da Internet Pública para sua Rede Privada, permitindo conectividade bidirecional (adicionando o seu IP de rede local a todos os dispositivos endpoint através da conexão VPN), o serviço padrão é ideal para você!</p>
                    <li id='li-sub-menu-m2m' onClick={toogleAnimationdois}><img src='./assets/img/plus-icon.png'/>Serviço de VPN</li>
                    <p id='sub-menu-m2m-dois'>Uma VPN (sigla em inglês para Rede Privada Virtual) permite que os clientes utilizem suas aplicações de LAN (Rede Local) de forma segura através de um túnel por meio da Internet pública.<br/> Em vez de usar uma conexão dedicada entre o dispositivo e um servidor, como uma linha alugada, um túnel VPN fornece conexões ‘virtuais’ seguras e criptografadas roteadas por meio da Internet pública diretamente do dispositivo móvel para a rede privada (local) da empresa.<br/> O túnel IPSec VPN para Top Connect é uma conexão estática, altamente criptografada e “sempre ativa” entre sua Rede Privada e seu APN privado (domínio de rede privada virtual dos chips).<br/>

Esse recurso adiciona uma camada extra de segurança, permitindo que seu servidor de Aplicações esteja isolado da Internet Pública e que o transporte de dados não seja exposto à Internet Pública! Além da conectividade rede-a-rede direta, você tem a opção de controlar o acesso público à Internet de todos os chips em sua configuração de APN privado!<br/>

Esta é uma ótima opção para proteger sua empresa contra ataques DOS e sniffing ou manipulação de dados por hackers!<br/>

Nosso serviço de VPN está disponível apenas para clientes que usam o serviço de APN Privado da Top Connect.<br/>
Um túnel VPN é um pré-requisito para os clientes que desejam usar os endereços IP estáticos privados atribuídos a seus chips.<br/></p>
                    <li onClick={toogleAnimationtres}><img src='./assets/img/plus-icon.png'/>Serviço de IP estático privado</li>
                    <p id='sub-menu-m2m-tres'>Um endereço IP estático privado é um número de endereço do Protocolo de Internet (IP) atribuído a um dispositivo móvel (chip). Endereços IP de chips no domínio da rede privada são atribuídos dinamicamente por padrão.<br/> É possível fornecer endereços de IP estáticos privados a cada chip para uma comunicação bidirecional fácil entre seu servidor de Aplicações e seus dispositivos Endpoint através de um túnel VPN. A atribuição de endereços IP é fácil; você pode gerenciar o vínculo chip-IP na interface de gerenciamento ou por meio da API XML.<br/>

Os serviços de APN e VPN privados são pré-requisitos para aqueles clientes que desejam utilizar o serviço de IP estático privado!<br/></p>
                    <li onClick={toogleAnimationquatro}><img src='./assets/img/plus-icon.png'/>Ferramentas de solução de problemas</li>
                    <p id='sub-menu-m2m-quatro'>Embora os problemas mais comuns possam ser resolvidos com nosso software SIM especialmente projetado para M2M/IoT, existem várias ferramentas de solução de problemas para chips M2M disponíveis na interface de gerenciamento e na API, incluindo:<br/>

Ferramenta para solução de problemas de conectividade da rede<br/>
Ferramenta de monitoramento de serviços ativos<br/>
Ferramenta de tratamento de registro de rede<br/>
Ferramenta SMS ping</p>
                    <li onClick={toogleAnimationcinco}><img src='./assets/img/plus-icon.png'/>Formato de chips disponíveis</li>
                    <p id='sub-menu-m2m-cinco'>Os chips estão disponíveis em três formatos: <br/>2FF, 3FF, 4FF<br/>
 

 2FF (mini)<br/>
 
 25mm x 15mm x 0.76mm
 (0.984″ x 0.590″ x 0.029″)<br/>
 
  
 
 3FF (micro)<br/>
 
 25mm x 15mm x 0.76mm
 (0.984″ x 0.590″ x 0.029″)<br/>
 
 4FF (nano)<br/>
 25mm x 15mm x 0.76mm
 (0.984″ x 0.590″ x 0.029″)<br/>
 
 MFF2 UICC (chip SIM)<br/>
 Os formatos de chip estão disponíveis no pacote MFF2 (SON-8). O UICC (chip SIM) pode ser soldado diretamente na placa de circuito durante o processo de fabricação do dispositivo. Os chips embutidos melhoram a segurança dos dispositivos M2M, limitando a substituição do provedor de conectividade.<br/>
 
  
 
 MFF2 (UICC/eUICC)<br/>
 
 6mm × 5mm × 0.9 mm
 (0.236″ x 0.196″ x 0.035″)<br/>
 
 Chip embutido (eSIM)/cartão de circuito integrado universal embutido (eUICC) – Perfil SIM Eletrônico
 O eUICC pode ser implementado em chips MFF2 ou em chips de plástico. A solução eSIM segue um novo padrão GSMA, que permite a entrega de perfis eletrônicos pelo ar (OTA). <br/>A Top Connect fornece soluções eSIM turn-key (SMDP/SMSR/OTA) para parceiros dispostos a desenvolver um produto com base nesta tecnologia nova e muito eficiente.<br/></p>
                    <li onClick={toogleAnimationseis}><img src='./assets/img/plus-icon.png'/>Software do cartão SIM</li>
                    <p id='sub-menu-m2m-seis'>Multi-IMSI M2M SIM
Nosso software SIM suporta backup automático de assinatura (IMSI) para melhor conectividade em regiões onde a cobertura de rede móvel é difícil. O backup do IMSI pode ser pré-carregado em um chip durante o estágio de personalização ou diretamente no estágio de produção. Novas assinaturas alternativas podem ser carregadas remotamente, a qualquer momento, através do protocolo Over The Air (OTA).<br/>

Diversas assinaturas “básicas” podem ser armazenadas em um chip. Uma lista de redes preferenciais é configurada para definir qual IMSI será usado para cada combinação MCC/MNC como uma assinatura padrão e, se necessário, qualquer outro IMSI disponível pode ser usado como backup.<br/>

IMSI lógica de fallback
Se nenhum IMSI puder ser registrado na rede, o applet do SIM mudará automaticamente para um backup alternativo do IMSI. Nenhuma comunicação com a operadora de rede é necessária, o software SIM tem total controle dessa tarefa. Enquanto estiver em um IMSI de backup, o applet usará o protocolo USSD para perguntar aos servidores de backend se o SIM deve permanecer em backup ou tentar recarregar a assinatura básica.<br/>

Execução remota (OTA) do “SIM Refresh”
Quando um processo de registro de rede é interrompido ou alguns dos serviços não estão funcionando, há uma maneira de iniciar o procedimento de “SIM Refresh” em um dispositivo remoto. Isso ajuda a resolver problemas que antes só eram possíveis de serem resolvidos ao realizar uma reinicialização do hardware.<br/>

Comando API para localização do Cell-ID
Um método alternativo para detectar a localização geográfica do chip com base nos dados da rede GSM. Isso é útil caso o dispositivo tenha um módulo GPS/GLONASS defeituoso ou esteja localizado em um local onde a cobertura GSM é a única opção disponível, como em minas subterrâneas, túneis, metrôs ou similares.<br/>

Design de software específico para a indústria
Juntamente com os recursos mencionados acima, nosso software SIM limita algumas funções da interface do usuário que não estão sendo usadas nos dispositivos M2M/IoT, já que são propensas a causar problemas.<br/> Recursos como ‘SIM Menu’, ‘Display text’ e similares estão normalmente disponíveis em chips genéricos.<br/> No entanto, dispositivos M2M não sugerem qualquer interação com o cliente e, como resultado, a execução de comandos desconhecidos pode impedir o funcionamento do software no dispositivo M2M.<br/> O software da Top Connect para chips M2M/IoT impede que estes tipos de problemas aconteçam!</p>
                    <li onClick={toogleAnimationsete}><img src='./assets/img/plus-icon.png'/>Plataforma eSIM</li>
                    <p id='sub-menu-m2m-sete'>A plataforma de hospedagem e entrega de perfis eSIM padrão GSMA permite o gerenciamento eletrônico do perfil SIM via OTA em chips inteligentes e dispositivos habilitados para e-SIM.<br/></p>
                </ul>
            </div>
    </div>

    <div className="accordion-item-m2m">
        <div className="accordion-item-header-m2m" onClick={animation}>
          <h1>Confira a nossa cobertura</h1>
        </div>
        <div className="accordion-item-body-m2m">
          <div className="accordion-item-body-content-m2m">
            <div className="accordion-item1-m2m">


                <div className="column1">
                    <ul>
                        <h1>Américas</h1>
                      <li>Argentine</li> 
                      <li>Brazil</li> 
                      <li>Canada</li> 
                      <li>Colombia</li> 
                      <li>Costa Rica</li>
                      <li>Dominican Republic</li>
                      <li>Ecuador</li>
                      <li>El Salvador</li>
                      <li>Guatemala</li>
                      <li>Honduras</li>
                      <li>Mexico</li>
                      <li>Nicaragua</li>
                      <li>Panama</li>
                      <li>Paraguay</li>
                      <li>Peru</li>
                      <li>Puerto Rico</li>
                      <li>St. Barthelemy</li>
                      <li>St. Martin Island</li>
                      <li>Uruguay</li>
                      <li>USA</li>
                    </ul>
                </div>

                <div className="column1">
                    <ul>
                        <h1>Europa</h1>
                      <li>Andorra, Armenia, Austria</li>
                      <li>Belarus, Belgium, Bulgaria</li> 
                      <li>Croatia, Cyprus, Czech.Rep.</li> 
                      <li>Denmark, Estonia</li>
                      <li>Finland, France</li>
                      <li>Georgia, Germany, Gibraltar, Greece</li>
                      <li>Hungary</li>
                      <li>Guatemala</li>
                      <li>Iceland, Ireland, Italy</li>
                      <li>Latvia, Liechtenstein, Lithuania, Luxembourg</li>
                      <li>Netherlands, Norway</li>
                      <li>Poland, Portugal</li>
                      <li>Romania,</li>
                      <li>San Marino,</li>
                      <li>Serbia, </li>
                      <li>Slovakia,</li>
                      <li>Slovenia,</li>
                      <li>Spain,</li>
                      <li>Sweden,</li>
                      <li>Switzerland,</li>
                      <li>Turkey</li>
                      <li>Ukraine, United Kingdom</li>
                      <li>Vatican City</li>

                    </ul>
                </div>

                <div className="column1">
                        <ul>
                            <h1>Ásia, África, Oceania</h1>
                          <li>Australia</li>
                          <li>Azerbaijan</li> 
                          <li>China</li> 
                          <li>Egypt</li>
                          <li>HongKong</li>
                          <li>India, Indonesia,</li>
                          <li>Israel</li>
                          <li>Japan</li>
                          <li>Kazakhstan,</li>
                          <li>Korea (South)</li>
                          <li>Malaysia,</li>
                          <li>Morocco</li>
                          <li>New Zealand</li>
                          <li>Philippines</li>
                          <li>Russia</li>
                          <li>Singapore,</li>
                          <li>Sri Lanka,</li>
                          <li>Seychelles,</li>
                          <li>South Africa,</li>
                          <li>Taiwan,</li>
                          <li>Tajikistan,</li>
                          <li>Thailand,</li>
                          <li>Uzbekistan,</li>
                          <li>United Arab Em.</li>

                        </ul>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>


            <h1 className='conheca-plan'>Seu negócio não pode parar!</h1>
          <p className='conheca-p'>Entre com contato agora mesmo e conheça em detalhes todas as nossas soluções globais para M2M e IoT.</p>

        <form action="https://api.staticforms.xyz/submit" method="post" className='form-m2m'>

            <input type="hidden" name="accessKey" value="9c436fd3-b6dd-49bd-8f88-6f1bd4bb9a0a"></input>{/* Jean's access key */}
            <input type="hidden" name="subject" value="Formulário m2m do site MySimtravel"></input>
            <input type="hidden" name="redirectTo" value="https://homologacao-site.mysimtravel.com/#/m2m-iot"/>

            <label for="GET-name" className='label-m2m'>Nome completo
              <input id="GET-name" type="text" name="name" placeholder='Nome Completo' className='input-m2m' required/>
            </label>

            <label for="GET-name" className='label-m2m'>Telefone
              <input id="GET-name" type="tel" name="phone" placeholder='(XX) 9XXXX-XXXX' className='input-m2m' required/>
            </label>

            <label for="GET-name" className='label-m2m'>Email para Contato
              <input id="GET-name" type="email" name="email" placeholder='email@email.com' className='input-m2m' required/>
            </label>
  
            <label for="GET-name" className='label-m2m'>Digite seu Texto
              <input id="GET-name" type="text" name="message" placeholder='Digite seu texto...' className='input-text-m2m' required/>
            </label>
  

            <Reaptcha onVerify={verify} sitekey="6LeIe0AkAAAAABO9U7cLdUbZAVY36Pwb2etFIwx3" ref={captchaRef}/>
            <button id="button_sent" className='submit-send-m'>Enviar Mensagem</button>
          
        </form>



          </div>
          

      <Footer/> 
    </div>
    
  )
}


export default M2m;