import './index.css'

function Contatos(){
  return(
    <div>
        Contato teste
    </div>
  )
}

export default Contatos;