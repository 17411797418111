import { Link } from 'react-router-dom';
import './index.css';
import './responsive-selling-card.css'



export default function SellingCard(props){
    return(
        <>
            <div className="grid-selling-card">
                <h1 className="h1-grid">{props.title}</h1>
                <div className="grid_cards_comprar_autorizadas">

                    <img src={props.image}/>
                             
                    {/* <p className="p-cards-comprar">
                        Temos mais de 700 revendas <br />
                        espalhadas no Brasil e +20 representantes ao redor do mundo.
                        Escolha a revenda mais proxima de você no link abaixo e retire
                        na mesma hora*
                    </p> */}
                </div>

                <div className="grid_cards_low_comprar_autorizadas">
        
                    <button className="button-selling-card"> <a className="a-button-selling-card" href='https://mystore.mysimtravel.com/mysimtravel-simfinity'>Compre Aqui</a> </button>{" "}
                    
                </div>
          </div>
        </>
    )
}