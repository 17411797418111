import "./compra.css";
import "./responsive-comprar.css";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import SellingCard from "../../components/selling_card/index";
import { Link } from "react-router-dom";

function compra() {
  function virtual_accordion() {
    let accordionFisicos = document.querySelector("#accordion-fisico");
    let accordionVirtuais = document.querySelector("#accordion-virtual");

    accordionFisicos.style.display = "none";
    accordionVirtuais.style.display = "block";

    document.getElementById("optionfisico").style.borderColor = "#c2c2c2";
    document.getElementById("textfisico").style.color = "#c2c2c2";

    document.getElementById("optionvirtual").style.borderColor = "#ff5000";
    document.getElementById("textvirtual").style.color = "#ff5000";
  }

  function physycal_accordion() {
    let accordionFisicos = document.querySelector("#accordion-fisico");
    let accordionVirtuais = document.querySelector("#accordion-virtual");

    accordionFisicos.style.display = "block";
    accordionVirtuais.style.display = "none";

    document.getElementById("optionvirtual").style.borderColor = "#c2c2c2";
    document.getElementById("textvirtual").style.color = "#c2c2c2";

    document.getElementById("optionfisico").style.borderColor = "#ff5000";
    document.getElementById("textfisico").style.color = "#ff5000";
  }

  function animation(id) {
    const accordionItemHeaders = document.getElementById(`${id}`);
    console.log(accordionItemHeaders);

    accordionItemHeaders.classList.toggle("active");
    const accordionItemBody = accordionItemHeaders.nextElementSibling;
    if (accordionItemHeaders.classList.contains("active")) {
      accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
    } else {
      accordionItemBody.style.maxHeight = 0;
    }
  }

  return (
    <div className="body-comprar">
      <Header backgroundcolor="#2d2d2f" />
      <div className="container-top">
        <div className="div-top-comprar">
          <h1>
            Chip internacional <span className="span-orange">mysimtravel</span>
          </h1>
          <p>
            Compre aqui e garanta o melhor plano de internet para sua viagem no
            exterior
          </p>
        </div>

        <div className="img-top">
          <img
            src="/assets/img/chip-mockup.svg"
            alt="Mockup"
            className="img-chip"
          />
        </div>
      </div>

      <div className="main-comprar">


        <div className="acoordion-plans">
          <h1 className="texto_planos">Conheça os nossos planos</h1>

          <div className="option-chip">
            <div
              className="option-fisico"
              id="optionfisico"
              onClick={physycal_accordion}
            >
              <h1 className="text-fisico" id="textfisico">
                Chip Físico
              </h1>
            </div>

            <div
              className="option-virtual"
              id="optionvirtual"
              onClick={virtual_accordion}
            >
              <h1 className="text-virtual" id="textvirtual">
                eSIM
              </h1>
            </div>
          </div>

          <div id="accordion-fisico" className="accordion-fisico-class">
          <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="planomax"
                  onClick={() => {
                    animation("planomax");
                  }}
                >
                  <h1>INTERNET ESTADOS UNIDOS (USA) - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                          <p className="p-info-planomax">
                            <span className="span-comprar">Américas: </span>
                            Estados Unidos (USA)
                          </p>

                       
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>5GB - U$35</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">15 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>10GB - U$44</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>20GB - U$66</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>30GB - U$86</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
                          </div>

                         {/*  <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FORYOU</p>
                            <h2>20GB 10 DIAS - R$340,00</h2>
                            <h2>20GB 20 DIAS - R$392,00</h2>
                            <h2>20GB 30 DIAS - R$475,00</h2>
                          </div> */}

                        </div>
                      </div>
                      <div className="button">
                        <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="megapack"
                  onClick={() => {
                    animation("megapack");
                  }}
                >


                 <h1>INTERNET EUROPA - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
              </div>
              <div className="accordion-item-body-comprar">
                <div className="accordion-item-body-content-comprar">
                  <div className="accordion-item1-comprar">
                    <div className="text-accordion-planomax">
                      <div className="content-text-planomax">
                        <h1 className="texto-content-planomax">Cobertura</h1>

                        <p className="p-info-planomax">
                          <span className="span-comprar">Europa: </span>
                          Albânia, Alemanha, Áustria, Bélgica, Bósnia e Herzegovina, Bulgária, Croácia, Chipre, Republica Checa, Dinamarca, Eslováquia, Eslovênia, Espanha, Estônia, Ilhas Faroe, Finlândia, França, Gibraltar, Grécia, Holanda, Hungria, Islândia, Irlanda, Itália, Letônia, Lituânia, Luxemburgo, Malta, Montenegro, Noruega, Polônia, Portugal, Reino Unido (Inglaterra, Escócia, País de Gales), Romênia, Rússia, Servia, Suécia, Suíça e Ucrânia.
                        </p>
                      </div>

                      <div className="valores-planomax">
                        <div className="valor-plano">
                          <h1>Valores</h1>
                          <p>Incluso Chip + Plano de dados</p>
                        </div>

                        <div className="valores-planos">
                          <h1>6GB - US$37</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">15 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>10GB - US$53</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>20GB - US$86</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>30GB - US$107</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                        </div>

                        {/* <div className="valor-recargas">
                          <h1>Valores Recarga</h1>
                          <p>Feito pelo app mysimtravel FOR YOU</p>
                          <h2>1GB - R$ 33,00</h2>
                          <h2>5GB - R$ 169,00</h2>
                        </div> */}

                      </div>
                    </div>
                    <div className="button">
                        <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                    </div>
                  </div>
                </div>
              </div>


              </div>
            </div>


            <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="especial"
                  onClick={() => {
                    animation("especial");
                  }}
                >
                  

                <h1>INTERNET JAPÃO - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
              </div>
              <div className="accordion-item-body-comprar">
                <div className="accordion-item-body-content-comprar">
                  <div className="accordion-item1-comprar">
                    <div className="text-accordion-planomax">
                      <div className="content-text-planomax">
                        <h1 className="texto-content-planomax">Cobertura</h1>
                        <p className="p-info-planomax">
                          <span className="span-comprar">Ásia: </span>
                          Japão
                        </p>

                      </div>

                      <div className="valores-planomax">
                        <div className="valor-plano">
                          <h1>Valores</h1>
                          <p>Incluso valor do Chip + Plano de dados</p>
                        </div>

                        <div className="valores-planos">
                          <h1>7GB - US$41</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">15 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>10GB - US$48</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">20 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>15GB - US$51</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>30GB - US$84</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                        </div>

                        {/* <div className="valor-recargas">
                          <h1>Valores Recarga</h1>
                          <p>Feito pelo app mysimtravel FOR YOU</p>
                          <h2>1GB - R$ 58,00</h2>
                          <h2>2GB - R$ 110,00</h2>
                          <h2>5GB - R$ 210,61</h2>
                          <h2>10GB - R$ 429,01</h2>
                        </div> */}

                      </div>
                    </div>
                    <div className="button">
                        <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                    </div>
                  </div>
                </div>
              </div>


              </div>
            </div>

            <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="eurotrip"
                  onClick={() => {
                    animation("eurotrip");
                  }}
                >

                  <h1>INTERNET DUBAI - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                        
                          <p className="p-info-planomax">
                            <span className="span-comprar">Ásia: </span>
                            Emirados Árabes (Dubai)
                          </p>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>3GB - US$33</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>5GB - US$43</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>10GB - US$65</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>30GB - US$160</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
            

                          </div>

                          {/* <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>5GB - R$ 124,81</h2>
                            <h2>10GB - R$ 257,41</h2>
                          </div> */}

                        </div>
                      </div>
                      <div className="button">
                        <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                            <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="simfinity-australia"
                onClick={() => {
                  animation("simfinity-australia");
                }}
              >

              <h1>INTERNET AUSTRÁLIA - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                        
                          <p className="p-info-planomax">
                            <span className="span-comprar">Oceania: </span>
                            Austrália
                          </p>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>10GB - US$51</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>20GB - US$76</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>30GB - U$100</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
            

                          </div>

                          {/* <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>5GB - R$ 124,81</h2>
                            <h2>10GB - R$ 257,41</h2>
                          </div> */}

                        </div>
                      </div>
                      <div className="button">
                      <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                      </a>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
          </div>

            <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="topdestino"
                  onClick={() => {
                    animation("topdestino");
                  }}
                >
                  <h1>
                    PLANO TOP DESTINO
                  </h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                          <p className="p-info-planomax">
                            <span className="span-comprar">Américas: </span>
                            Brasil, Argentina, Canada, Costa Rica, Colômbia, El
                            Salvador, Equador, Estados Unidos, Guadalupe,
                            Guatemala, Honduras, México, Nicarágua, Peru,
                            Paraguai, Porto Rico, República Dominicana, Uruguai
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">Europa: </span>
                            Alemanha, Albania, Áustria, Belarus, Bélgica,
                            Bulgária, Chipre, Croácia, Dinamarca, Eslováquia,
                            Eslovênia, Espanha, Estônia, Finlândia, França,
                            Grécia, Gibraltar, Holanda, Hungria, Islândia, Ilhas
                            Faroe, Irlanda, Itália, Letônia, Liechtenstein,
                            Lituânia, Luxemburgo, Malta, Montenegro, Noruega,
                            Portugal, Polônia, Reino Unido (Inglaterra, Escócia,
                            País de Gales), República Checa, Romênia, Suíça,
                            Suécia.
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">ÁSIA/OCEANIA: </span>
                            Austrália, Azerbaidjão, Cazaquistão, Coreia do Sul,
                            China, Emirados Árabes, Georgia, Hong Kong,
                            Indonésia, Israel, Malásia, Qatar,
                            Singapura, Tailândia, Taiwan, Turquia , Ucrânia,
                            Uzbequistão, Vietnam.
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">ÁFRICA: </span>
                            Egito
                          </p>

                          <h3 className="warning-text-compra">
                            {" "}
                            <span className="span-comprar">
                              OS PAÍSES A SEGUIR NÃO POSSUEM COBERTURA PARA CHIP
                              VIRTUAL (eSIM):{" "}
                            </span>{" "}
                            Hong Kong, Indonésia, Singapura, Tailândia, Taiwan e
                            Egito.{" "}
                          </h3>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>1GB - R$218,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>2GB - R$348,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>3GB - R$470,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>5GB - R$713,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
                          </div>

                          <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>1GB - R$ 147,00</h2>
                            <h2>2GB - R$ 277,00</h2>
                            <h2>3GB - R$ 399,00</h2>
                            <h2>5GB - R$ 642,00</h2>
                          </div>
                        </div>
                      </div>
                      <div className="button">
                        <a href="https://mystore.mysimtravel.com/sgmobile-br_topdestino" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="pacifico"
                  onClick={() => {
                    animation("pacifico");
                  }}
                >
                  <h1>PLANO PACIFICO - Ásia | Oceania</h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>

                          <p className="p-info-planomax">
                            <span className="span-comprar">ÁSIA: </span>
                            China, Coreia do Sul, Filipinas, Hong Kong, India,
                            Indonésia, Japão, Macau, Malásia, Singapura, Taiwan,
                            Tailândia, Vietnam.
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">PACÍFICO: </span>
                            Australia, Nova Zelândia
                          </p>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>3GB - R$356,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
                          </div>

                          <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>3GB - R$ 285,00</h2>
                          </div>
                        </div>
                      </div>
                      <div className="button">
                        <a href="https://mystore.mysimtravel.com/sgmobile-br_asiapacifico" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-comprar">
              <div className="accordion-item-comprar">
                <div
                  className="accordion-item-header-comprar"
                  id="global"
                  onClick={() => {
                    animation("global");
                  }}
                >
                  <h1>PLANO GLOBAL</h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>

                          <p className="p-info-planomax">
                            <span className="span-comprar">AMÉRICAS: </span>
                            Anguilla, Antigua & Barbuda, Antilhas Holandesas,
                            Argentina, Aruba, Brasil, Barbados, Belarus,
                            Bermuda, Canada, Chile, Colômbia, Costa Rica,
                            Dominica, El Salvador, Equador, Estados Unidos (USA)
                            Grenada, Guatemala, Haiti, Honduras, Ilhas Virgens
                            Americanas, Ilhas Virgens Britânicas, Ilhas Caiman,
                            Jamaica, México, Monteserrat, Nicarágua,
                            Paraguai, Peru, Porto Rico, Republica Dominicana,
                            St. Kitts, St. Lucia, São Vicente & Granadinas,
                            Suriname, Turks & Caicos, Uruguai.
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">EUROPA: </span>
                            Albânia, Alemanha, Armênia, Áustria, Bélgica,
                            Bulgária, Croácia, Chipre, República Checa,
                            Dinamarca, Eslováquia, Eslovênia, Espanha, Estônia,
                            Finlândia, Ilhas Faroe, França, Georgia, Gibraltar,
                            Grécia, Groelândia, Holanda, Hungria, Islândia,
                            Irlanda, Itália, Letônia, Lituânia, Luxemburgo,
                            Kosovo, Malta,Montenegro, Noruega, Polônia,
                            Portugal, Romênia, Sérvia, Suécia, Suíça, Reino
                            Unido, Cidade Vaticano, Turquia.
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">ÁSIA: </span>
                            Arabia Saudita, Azerbaijão, China, Emirados Árabes,
                            Filipinas, Hong Kong, Indonésia, Índia, Israel,
                            Japão, Cazaquistão, Coreia do Sul, Kuwait, Macau,
                            Malásia, Moldova, Qatar, Singapura, Sri
                            Lanka, Taiwan, Tajaquistão, Tailândia, Ucrânia,
                            Uzbequistão, Vietnam.
                          </p>

                          <p className="p-info-planomax">
                            <span className="span-comprar">
                              Africa / Oceania:{" "}
                            </span>
                            África do Sul, Austrália, Congo DR, Egito,
                            Mauricius, Nova Zelândia
                          </p>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">

                            <h1>1GB - R$518,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>2GB - R$795,00</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
                          </div>

                          <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>500MB - R$ 285,00</h2>
                            <h2>1GB - R$ 447,00</h2>
                            <h2>2GB - R$ 724,00</h2>
                          </div>
                        </div>
                      </div>
                      <div className="button">
                        <a href="https://mystore.mysimtravel.com/sgmobile-br_global" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="accordion-virtual">
        <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="maxesim"
                onClick={() => {
                  animation("maxesim");
                }}
              >


                <h1>INTERNET ESTADOS UNIDOS (USA) - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                          <p className="p-info-planomax">
                            <span className="span-comprar">Américas: </span>
                            Estados Unidos (USA)
                          </p>

                       
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>5GB - U$35</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">15 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>10GB - U$44</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>20GB - U$66</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>30GB - U$86</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
                          </div>

                         {/*  <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FORYOU</p>
                            <h2>20GB 10 DIAS - R$340,00</h2>
                            <h2>20GB 20 DIAS - R$392,00</h2>
                            <h2>20GB 30 DIAS - R$475,00</h2>
                          </div> */}

                        </div>
                      </div>
                      <div className="button">
                      <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>



            </div>
          </div>

          <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="megapackesim"
                onClick={() => {
                  animation("megapackesim");
                }}
              >


                <h1>INTERNET EUROPA - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
              </div>
              <div className="accordion-item-body-comprar">
                <div className="accordion-item-body-content-comprar">
                  <div className="accordion-item1-comprar">
                    <div className="text-accordion-planomax">
                      <div className="content-text-planomax">
                        <h1 className="texto-content-planomax">Cobertura</h1>

                        <p className="p-info-planomax">
                          <span className="span-comprar">Europa: </span>
                          Albânia, Alemanha, Áustria, Bélgica, Bósnia e Herzegovina, Bulgária, Croácia, Chipre, Republica Checa, Dinamarca, Eslováquia, Eslovênia, Espanha, Estônia, Ilhas Faroe, Finlândia, França, Gibraltar, Grécia, Holanda, Hungria, Islândia, Irlanda, Itália, Letônia, Lituânia, Luxemburgo, Malta, Montenegro, Noruega, Polônia, Portugal, Reino Unido (Inglaterra, Escócia, País de Gales), Romênia, Rússia, Servia, Suécia, Suíça e Ucrânia.
                        </p>
                      </div>

                      <div className="valores-planomax">
                        <div className="valor-plano">
                          <h1>Valores</h1>
                          <p>Incluso Chip + Plano de dados</p>
                        </div>

                        <div className="valores-planos">
                          <h1>6GB - US$37</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">15 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>10GB - US$53</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>20GB - US$86</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>30GB - US$107</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                        </div>

                        {/* <div className="valor-recargas">
                          <h1>Valores Recarga</h1>
                          <p>Feito pelo app mysimtravel FOR YOU</p>
                          <h2>1GB - R$ 33,00</h2>
                          <h2>5GB - R$ 169,00</h2>
                        </div> */}
                        
                      </div>
                    </div>
                    <div className="button">
                      <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>

          <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="especialesim"
                onClick={() => {
                  animation("especialesim");
                }}
              >


                <h1>INTERNET JAPÃO - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
              </div>
              <div className="accordion-item-body-comprar">
                <div className="accordion-item-body-content-comprar">
                  <div className="accordion-item1-comprar">
                    <div className="text-accordion-planomax">
                      <div className="content-text-planomax">
                        <h1 className="texto-content-planomax">Cobertura</h1>
                        <p className="p-info-planomax">
                          <span className="span-comprar">Ásia: </span>
                          Japão
                        </p>
                      </div>

                      <div className="valores-planomax">
                        <div className="valor-plano">
                          <h1>Valores</h1>
                          <p>Incluso valor do Chip + Plano de dados</p>
                        </div>

                        <div className="valores-planos">
                          <h1>7GB - US$41</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">15 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>10GB - US$48</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">20 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>15GB - US$51</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>30GB - US$84</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                        </div>

                        {/* <div className="valor-recargas">
                          <h1>Valores Recarga</h1>
                          <p>Feito pelo app mysimtravel FOR YOU</p>
                          <h2>1GB - R$ 58,00</h2>
                          <h2>2GB - R$ 110,00</h2>
                          <h2>5GB - R$ 210,61</h2>
                          <h2>10GB - R$ 429,01</h2>
                        </div> */}

                      </div>
                    </div>
                    <div className="button">
                        <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                        </a>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="eurotripesim"
                onClick={() => {
                  animation("eurotripesim");
                }}
              >

               <h1>INTERNET DUBAI - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                        
                          <p className="p-info-planomax">
                            <span className="span-comprar">Ásia: </span>
                            Emirados Árabes (Dubai)
                          </p>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>3GB - US$33</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>5GB - US$43</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>10GB - US$65</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>30GB - US$160</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
            

                          </div>

                          {/* <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>5GB - R$ 124,81</h2>
                            <h2>10GB - R$ 257,41</h2>
                          </div> */}

                        </div>
                      </div>
                      <div className="button">
                        <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                            <button className="button-comprar">Comprar</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
          </div>

          <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="simfinity-australia-esim"
                onClick={() => {
                  animation("simfinity-australia-esim");
                }}
              >

              <h1>INTERNET AUSTRÁLIA - <span className="orange-text">PROMOÇÕES ATÉ 20%</span></h1>
                </div>
                <div className="accordion-item-body-comprar">
                  <div className="accordion-item-body-content-comprar">
                    <div className="accordion-item1-comprar">
                      <div className="text-accordion-planomax">
                        <div className="content-text-planomax">
                          <h1 className="texto-content-planomax">Cobertura</h1>
                        
                          <p className="p-info-planomax">
                            <span className="span-comprar">Oceania: </span>
                            Austrália
                          </p>
                        </div>

                        <div className="valores-planomax">
                          <div className="valor-plano">
                            <h1>Valores</h1>
                            <p>Incluso valor do Chip + Plano de dados</p>
                          </div>

                          <div className="valores-planos">
                            <h1>10GB - US$51</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>20GB - US$76</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>

                            <h1>30GB - U$100</h1>
                            <p>
                              Validos por{" "}
                              <span className="span-comprar">30 dias</span> após
                              ativação ou
                              <br />
                              quando atingir o limite de franquia
                            </p>
            

                          </div>

                          {/* <div className="valor-recargas">
                            <h1>Valores Recarga</h1>
                            <p>Feito pelo app mysimtravel FOR YOU</p>
                            <h2>5GB - R$ 124,81</h2>
                            <h2>10GB - R$ 257,41</h2>
                          </div> */}

                        </div>
                      </div>
                      <div className="button">
                      <a href="https://mystore.mysimtravel.com/mysimtravel-simfinity" target="_blank">
                          <button className="button-comprar">Comprar</button>
                      </a>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
          </div>

          <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="topdestinoesim"
                onClick={() => {
                  animation("topdestinoesim");
                }}
              >
                <h1>PLANO TOP DESTINO</h1>
              </div>
              <div className="accordion-item-body-comprar">
                <div className="accordion-item-body-content-comprar">
                  <div className="accordion-item1-comprar">
                    <div className="text-accordion-planomax">
                      <div className="content-text-planomax">
                        <h1 className="texto-content-planomax">Cobertura</h1>
                        <p className="p-info-planomax">
                          <span className="span-comprar">Américas: </span>
                          Brasil, Argentina,Costa Rica, Colômbia, El Salvador,
                          Equador, Estados Unidos, Guadalupe, Guatemala,
                          Honduras, México, Nicarágua, Peru, Paraguai,
                          Porto Rico, República Dominicana, Uruguai
                        </p>

                        <p className="p-info-planomax">
                          <span className="span-comprar">Europa: </span>
                          Alemanha, Albania, Áustria, Belarus, Bélgica,
                          Bulgária, Chipre, Croácia, Dinamarca, Eslováquia,
                          Eslovênia, Espanha, Estônia, Finlândia, França,
                          Grécia, Gibraltar, Holanda, Hungria, Islândia, Ilhas
                          Faroe, Irlanda, Itália, Letônia, Liechtenstein,
                          Lituânia, Luxemburgo, Malta, Montenegro, Noruega,
                          Portugal, Polônia, Reino Unido (Inglaterra, Escócia,
                          País de Gales), República Checa, Romênia, Suíça,
                          Suécia.
                        </p>

                        <p className="p-info-planomax">
                          <span className="span-comprar">ÁSIA/OCEANIA: </span>
                          Austrália, Azerbaidjão, Cazaquistão, Coreia do Sul,
                          China, Georgia, Israel, Malásia, Qatar,
                          Turquia , Ucrânia, Uzbequistão, Vietnam.
                        </p>

                        <h3 className="warning-text-compra">
                          {" "}
                          <span className="span-comprar">
                            OS PAÍSES A SEGUIR NÃO POSSUEM COBERTURA PARA CHIP
                            VIRTUAL (eSIM):{" "}
                          </span>{" "}
                          Hong Kong, Indonésia, Singapura, Tailândia, Taiwan e
                          Egito.{" "}
                        </h3>
                      </div>

                      <div className="valores-planomax">
                        <div className="valor-plano">
                          <h1>Valores</h1>
                          <p>Incluso valor do Chip + Plano de dados</p>
                        </div>

                        <div className="valores-planos">
                          <h1>1GB - R$218,00</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>2GB - R$348,00</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>3GB - R$470,00</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>5GB - R$713,00</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>
                        </div>

                        <div className="valor-recargas">
                          <h1>Valores Recarga</h1>
                          <p>Feito pelo app mysimtravel FOR YOU</p>
                          <h2>1GB - R$ 147,00</h2>
                          <h2>2GB - R$ 277,00</h2>
                          <h2>3GB - R$ 399,00</h2>
                          <h2>5GB - R$ 642,00</h2>
                        </div>
                      </div>
                    </div>
                    <div className="button">
                      <a href="https://mystore.mysimtravel.com/sgmobile-br_topdestino" target="_blank">
                        <button className="button-comprar">Comprar</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-comprar">
            <div className="accordion-item-comprar">
              <div
                className="accordion-item-header-comprar"
                id="globalesim"
                onClick={() => {
                  animation("globalesim");
                }}
              >
                <h1>PLANO GLOBAL</h1>
              </div>
              <div className="accordion-item-body-comprar">
                <div className="accordion-item-body-content-comprar">
                  <div className="accordion-item1-comprar">
                    <div className="text-accordion-planomax">
                      <div className="content-text-planomax">
                        <h1 className="texto-content-planomax">Cobertura</h1>

                        <p className="p-info-planomax">
                          <span className="span-comprar">AMÉRICAS: </span>
                          Anguilla, Antigua & Barbuda, Antilhas Holandesas,
                          Argentina, Aruba, Brasil, Barbados, Belarus, Bermuda,
                          Canada, Chile, Colômbia, Costa Rica, Dominica, El
                          Salvador, Equador, Estados Unidos (USA) Grenada,
                          Guatemala, Haiti, Honduras, Ilhas Virgens Americanas,
                          Ilhas Virgens Britânicas, Ilhas Caiman, Jamaica,
                          México, Monteserrat, Nicarágua, Paraguai,
                          Peru, Porto Rico, Republica Dominicana, St. Kitts, St.
                          Lucia, São Vicente & Granadinas, Suriname, Turks &
                          Caicos, Uruguai.
                        </p>

                        <p className="p-info-planomax">
                          <span className="span-comprar">EUROPA: </span>
                          Albânia, Alemanha, Armênia, Áustria, Bélgica,
                          Bulgária, Croácia, Chipre, República Checa, Dinamarca,
                          Eslováquia, Eslovênia, Espanha, Estônia, Finlândia,
                          Ilhas Faroe, França, Georgia, Gibraltar, Grécia,
                          Groelândia, Holanda, Hungria, Islândia, Irlanda,
                          Itália, Letônia, Lituânia, Luxemburgo, Kosovo,
                          Malta,Montenegro, Noruega, Polônia, Portugal, Romênia,
                          Sérvia, Suécia, Suíça, Reino Unido, Cidade Vaticano,
                          Turquia.
                        </p>

                        <p className="p-info-planomax">
                          <span className="span-comprar">ÁSIA: </span>
                          Arabia Saudita, Azerbaijão, China, Filipinas, Hong
                          Kong, Indonésia, Índia, Israel, Japão, Cazaquistão,
                          Coreia do Sul, Kuwait, Macau, Malásia, Moldova, Qatar,
                          Singapura, Sri Lanka, Taiwan, Tajaquistão,
                          Tailândia, Ucrânia, Uzbequistão, Vietnam.
                        </p>

                        <p className="p-info-planomax">
                          <span className="span-comprar">
                            Africa / Oceania:{" "}
                          </span>
                          África do Sul, Austrália, Congo DR, Egito, Mauricius,
                          Nova Zelândia
                        </p>
                      </div>

                      <div className="valores-planomax">
                        <div className="valor-plano">
                          <h1>Valores</h1>
                          <p>Incluso valor do Chip + Plano de dados</p>
                        </div>

                        <div className="valores-planos">

                          <h1>1GB - R$518,00</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>

                          <h1>2GB - R$795,00</h1>
                          <p>
                            Validos por{" "}
                            <span className="span-comprar">30 dias</span> após
                            ativação ou
                            <br />
                            quando atingir o limite de franquia
                          </p>
                        </div>

                        <div className="valor-recargas">
                          <h1>Valores Recarga</h1>
                          <p>Feito pelo app mysimtravel FOR YOU</p>
                          <h2>500MB - R$ 285,00</h2>
                          <h2>1GB - R$ 447,00</h2>
                          <h2>2GB - R$ 724,00</h2>
                        </div>
                      </div>
                    </div>
                    <div className="button">
                      <a href="https://mystore.mysimtravel.com/sgmobile-br_global" target="_blank">
                        <button className="button-comprar">Comprar</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="att-tarifas">
            <h1>Tarifas atualizadas em 06/2024</h1>
        </div>


{/* new insertion */}
        <h1 className="text-grid-columns">x
          O chip internacional <span className="span-orange">mysimtravel</span>{" "}
          disponibiliza diversas opções de
          <br /> retirada e te damos várias dicas para facilitar a sua viagem:
        </h1>

        <div className="grid-rows-comprar">
          <div className="grid-autorizadas">
            <h1 className="h1-grid">Revenda Autorizada</h1>
            <div className="grid_cards_comprar_autorizadas">
              <p className="p-cards-comprar">
                Temos mais de 700 revendas <br />
                espalhadas no Brasil e +20 representantes ao redor do mundo.
                Escolha a revenda mais proxima de você no link abaixo e retire
                na mesma hora*
              </p>
            </div>

            <div className="grid_cards_low_comprar_autorizadas">
              <Link to="/distribuidores">
                <button className="button-autorizadas button3-comprar-page">
                  Compre Aqui
                </button>{" "}
              </Link>
              <p className="p-final">
                *consultar horário e disponibilidade no local
              </p>
            </div>
          </div>

          <div className="grid-aeroportos">
            <h1 className="h1-grid">Revenda Aeroportos</h1>
            <div className="grid_cards_comprar_aeroportos">
              <p className="p-cards-comprar">
                Faça a compra online e retire o nosso chip em revendas parceiras
                nos aeroportos converniados.
                <br /> Os aeroportos disponiveis são:
                <br />{" "}
                <span className="bold-cards-comprar">
                  Guarulhos (SP), Viracopos (SP), Galeão (RJ), Santos Dumont
                  (RJ), Confins (MG), Recife (PE), Brasília (DF), Salvador (BA).
                </span>
              </p>
            </div>

            <div className="grid_cards_low_comprar_aeroportos">
              <Link to="/chip-internet-aeroportos">
                <button className="button-autorizadas button1-comprar-page">
                  Compre Aqui
                </button>{" "}
              </Link>
            </div>
          </div>

          <div className="grid-dicas-comprar">
            <h1 className="h1-grid">Dicas de viagens</h1>
            <div className="grid_cards_comprar_dicas">
              <p className="p-cards-comprar">
                Confira as melhores opções e dicas
                <br /> para sua viagem e fique sempre conectado com o chip
                internacional mysimtravel
                <br /> O melhor do{" "}
                <span className="bold-cards-comprar">Grupo Dicas</span> em
                parceria com o mysimtravel
              </p>
            </div>

            <div className="grid_cards_low_comprar_dicas">
              <Link to="/dicas-para-viagem">
                <button className="button-autorizadas button-comprar-page">
                  Compre Aqui
                </button>{" "}
              </Link>
            </div>
          </div>
        </div>
{/* new insertion */}

       <div className="info-work">
          <h1>
            Sabia que também é possivel comprar e retirar em nossa matriz ?
          </h1>
          <p>
            Estamos localizados na Rua Domingos de Morais, 2187 - CJ 706 - Torre
            Paris | Vila Mariana
          </p>
          <p>Localizados à 5 min do metrô Santa Cruz</p>
          <p>Horário de Funcionamento: Segunda à Sexta das 09h às 18h</p>
        </div>

        <div className="infos_buttom">
          <h1 className="texto_infos_buttom">
            Algumas informações importantes sobre o nosso chip
          </h1>

          <ol className="lista_infos">
            <li>
              <p>
                <span>
                  É necessário ativar o seu plano de internet pelo nosso app
                  mysimtravel for you para começar a utilizar a internet
                </span>
              </p>
            </li>
            <li>
              <p>
                O Chip mysimtravel{" "}
                <span>aceita um único pacote de dados de cada vez.</span>
              </p>
            </li>
            <li>
              <p>
                Pacotes desativados pelo usuário são perdidos e não
                reembolsados.
              </p>
            </li>
            <li>
              <p>
                Todos os pacotes de dados possuem validade em dias após
                ativados, vencendo pelo consumo ou pelo prazo, o que ocorrer
                primeiro.
              </p>
            </li>
            <li>
              <p>
                Pacotes ativados em destinos sem cobertura{" "}
                <span>não serão reembolsados.</span> O usuário deverá desativar
                e ativar outro pacotes com cobertura no país desejado.
              </p>
            </li>
            <li>
              <p>
                Na primeira compra, estão inclusos nos preços dos pacotes
                apresentados : 01 Chip mysimtravel + Créditos para ativar plano
                de dados escolhido.
              </p>
            </li>
            <li>
              <p>
                <span>
                  O Chip Internacional mysimtravel não perde a validade, se
                  movimentado uma vez por ano.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Os Créditos (em Reais) não utilizados, não perdem a validade,
                  se movimentado uma vez por ano.
                </span>
              </p>
            </li>
            <li>
              <p>
                Pelo nosso aplicativo mysimtravel FORYOU é possível efetuar
                recargas, consultar tarifas, ativar e consultar o consumo do
                pacote ativado.
              </p>
            </li>
            <li>
              <p>
                Você utiliza o mesmo Chip mysimtravel para todos os destinos, o
                que altera são os Planos de Dados.
              </p>
            </li>
          </ol>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default compra;
