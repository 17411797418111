import "./index.css";
import "./responsive-app.css"
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Link } from "react-router-dom";


export default function recarga(){
    return(
        <div>
            <Header  backgroundcolor="#2d2d2d"/>
            <div class="content-main-app-page">
      <div class="content">
      <div class="content-text">
          <h1>mysimtravel FORYOU</h1>
          <p> 
            Conheça o app que faz a gestão completa do seu chip em toda sua
            viagem!
          </p>
        </div>
        <div class="logo-content">
         *  <img
            src="/assets/img/img-app/app-01.svg"
            alt="Mockup Aplicativo"
          /> 
        </div>

        
      </div>
    </div>

    <div class="maincontent">
      <div class="maintext1">
        <h1 class="h1-1">
          Através do <span>mysimtravel FORYOU</span> você controla tudo no seu
          chip!
        </h1>
        <p>
          É Através dele que você faz suas recargas, ativação do plano de
          internet e também acompanha o seu consumo.<br />Veja abaixo como
          funciona:
        </p>
      </div>

      <div class="ativacao layout-info">
        <li>Ativação</li>
        <p>
          É através do app que você faz a ativação do seu plano de internet,
          isso pode ser feito no Brasil ou até mesmo no país de destino, e uma
          vez ativado você já consegue ter acesso a internet no mesmo ato, não
          existe um "tempo" para começar a funcionar. Ativou, funcionou
        </p>
        <div class="grid-ativacao layout-grid">
          <img
            src="/assets/img/img-app/aplicativo/ativacao-1.svg"
            alt="Ativacao 1"
          />
          <img
            src="/assets/img/img-app/aplicativo/ativacao-2.svg"
            alt="Ativacao 2"
          />
          <img
            src="/assets/img/img-app/aplicativo/ativacao-3.svg"
            alt="Ativacao 3"
          />
          <img
            src="/assets/img/img-app/aplicativo/ativacao-4.svg"
            alt="Ativacao 4"
          />
        </div>
      </div>

      <div class="recarga layout-info">
        <li>Recarga</li>
        <p>
          A recarga pelo app é muito simples e fácil, todo o valor recarregado
          em nosso chip <span>SEMPRE</span> será em Reais, ou seja, não há custo
          de IOF ou de conversão de moeda, independente do país em que está. O
          processo é bem simples em nosso app, no Menu selecione a opção de
          <span>"Comprar Créditos"</span> e insira o valor referente ao plano que gostaria de ativar. Fácil né?
        </p>
        <div class="grid-recarga">
          <img
            src="/assets/img/img-app/aplicativo/menu-app-1.svg"
            alt="Menu app 1"
          />
          <img
            src="/assets/img/img-app/aplicativo/menu-app-2.svg"
            alt="Menu app 2"
          />
        </div>
      </div>

      <div class="Consumo layout-info">
        <li>Consumo</li>
        <p>
          Essa é uma das partes mais importantes, o consumo, é nele que você vê
          o quanto ainda tem de internet restante e se precisa recarregar ou
          não. A visualização dele é bem fácil em nosso app, assim que o plano é
          ativado, o consumo aparece na primeira tela do app abaixo do seu
          saldo, informando o plano ativado a quantidade consumida em MB e a
          porcentagem do que já foi utilizado
        </p>
        <div class="grid-consumo">
          <img
            src="/assets/img/img-app/aplicativo/consumo-1.svg"
            alt="Consumo"
          />
        </div>
      </div>

      <div class="div-icons">
        <h1>
          O app está disponivel nas principais plataformas de compra do seu celular
        </h1>
        <div class="flex-icons">
          
          <div class="playstore">
            <a href="https://play.google.com/store/apps/details?id=wr.mysimtravel.foryou&hl=pt_BR&gl=US">
            <img
              src="/assets/img/img-app/aplicativo/playstore.svg"
              alt="Play Store"
            />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=wr.mysimtravel.foryou&hl=pt_BR&gl=US"
              >Play Store</a
            >
          </div>


          <div class="applestore">
            <a href="https://apps.apple.com/br/app/mysimtravel-foryou/id1224801404">
            <img
              src="/assets/img/img-app/aplicativo/apple-store.svg"
              alt="Apple Store"
            />
            </a>
            <a
              href="https://apps.apple.com/br/app/mysimtravel-foryou/id1224801404"
              >Apple Store</a
            >
          </div>
        </div>
      </div>
    </div>
    <Footer/>
        </div>
    )
}