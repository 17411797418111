import './index.css';
import './responsive-map.css';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import { Loader } from '@googlemaps/js-api-loader';
import revendas from '../../json/revendas.json';
import axios from 'axios';
let map;


function RevendasInt(){
  const loader = new Loader({
    apiKey: "AIzaSyD6OwfSBLPbZixcEOPjSEdBDwZrjVRhFRM",
    version: "weekly",
  });

 
  /* loader.load().then((google) => {

    function initMap() {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -23.595544,  lng: -46.636574 },
        zoom: 11,
        mapTypeControl:false 
      });



      const styledMapType = new google.maps.StyledMapType(
        [
         {
           featureType: "poi",
           stylers: [
             { visibility: "off"}
            ]
         }
      ]
     )
      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map");



      revendas.forEach((store) => {

              const content_string = 
              '<div id="content">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<h1 id="firstHeading" class="firstHeading">${store.name_of_store}</h1>` +
              `<div id="bodyContent">` +
              `<div>${store.address}</div>` +
              "<br>" +
              `<div>${store.contact}</div>`+
              "<br>" +
              `<a href=${store.link_of_store}/>COMPRE AQUI seu chip mysimtravel</a>` +
              "</div>";
        
              const infowindow = new google.maps.InfoWindow({
                content: content_string,
                ariaLabel: "Uluru",
              });
                  
              let coordinates = new google.maps.LatLng(store.coordinates[0],store.coordinates[1])
              let marker = new google.maps.Marker({
                  position:coordinates,
                  title:"Revenda autorizada mysimtravel",
                  icon:"/assets/img/PIN_mysimtravel.png"
              })

              marker.setMap(map);
              marker.addListener("click", () => {
                  infowindow.open({
                    anchor: marker,
                    map,
                  });
                  
              });

        });
        

            

            const input = document.getElementById("pac-input");      
            const autocomplete =new google.maps.places.Autocomplete(input, {
              fields: ["place_id", "geometry", "name", "formatted_address"],
            }) 
            autocomplete.bindTo("bounds", map);
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); 


            autocomplete.addListener("place_changed", async () => {

                  const place = autocomplete.getPlace();
                  const request = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${input.value}&key=AIzaSyD6OwfSBLPbZixcEOPjSEdBDwZrjVRhFRM`).catch((err) => {console.log(err)});
          
                    if(request.data.status !== 'ZERO_RESULTS'){
                        let location = request.data.results[0].geometry.location;
                        map.setCenter({lat:location.lat, lng:location.lng});
                        map.setZoom(12);
                    }else{
                      window.alert("O local que você digitou não existe. Verifique se não escreveu algo errado. '" + place.name + "'");
                      return;
                    }

              });


            input.value = "";
    }
    initMap()

  }); */

 

  return(
    <div>
      <Header/>

      <div className="midlow-mapa-revendas">
      <div className="text-midlow-mapa">
        <h1 className="h1midlow-mapa">Revendas Autorizadas</h1>
        <p className="p-midlow-mapa">O mysimtravel conta com +700 representantes no Brasil e no mundo!<br/>Encontre a loja mais próxima de você!</p>
      </div>
      <div className="map-midlow-mapa">
        <img className="map-img-mapa" src="/assets/img/Mapa_banner_site.svg" alt="Mapa Revendas"/>
      </div>
    </div>

      <div className="info-text-mapa">
    
        <h2 className="info-h1-mapa">Para saber a revenda mais próxima a você, entre em contato com o nosso time de suporte técnico.</h2>

      </div>    

   

  {/*   <div className="map_box">

        <div className="sobre_input">
            <input
              id="pac-input"
              className="controls"
              type="text"
              placeholder="Digite o seu endereço ou CEP"
            /> 
      </div>

        <div id="map"></div>

        <div id="infowindow-content">
            <span id="place-name" className="title"></span><br />
            <strong>Place ID</strong>: <span id="place-id"></span><br />
            <span id="place-address"></span>
        </div>


    </div> */}



    <Footer/>
    </div>
  )
}

export default RevendasInt;