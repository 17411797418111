import { Routes, Route, BrowserRouter } from "react-router-dom";

//pages
import Home from "./pages/home";
import Blog from "./pages/blog/index";
import ChipInternetAirport from "./pages/chip-internet-aeroportos/index";
import ComoFunciona from "./pages/como-funciona";
import Compra from "./pages/compra/compra";
import Contatos from "./pages/contatos";
import DicasToViagens from "./pages/dicas-para-viagem";
import M2m from "./pages/m2m";
import MysimGroups from "./pages/mysimtravel-grupos";
import Privacidade from "./pages/privacidade";
import RecargaForyou from "./pages/recarga-foryou";
import RevendasInt from "./pages/revendas-internacionais";
import SolucoesCorp from "./pages/solucoes-corporativas";
import ListaOperadoras from "./pages/lista-global/index";
import TermosEcond from "./pages/termos-e-condicoes";
import VozSms from "./pages/voz-sms";
import NotFound from "./pages/notFound";
import WebDocs from "./pages/websiteDocs";

//components
import Iphone7 from "./components/iphone_cards/iphone7+/index";
import Iphone11 from "./components/iphone_cards/iphone11+/index";
import IphoneSE from "./components/iphone_cards/iphoneSE/index";
import Galaxy from "./components/samsung_cards/galaxy/index";
import JA from "./components/samsung_cards/j_a/index";
import Xiaomi from "./components/xiaomi";
import Motog from "./components/motorola/motorola_g";
import Motorazr from "./components/motorola/motorola_razr";
import Seja_uma_revenda from "./pages/seja-uma-revenda";
import ScrollToTop from "./components/scrollToTop";

export default function Rotes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/comprar" element={<Compra />} />
        <Route path="/lista-global-de-operadoras"element={<ListaOperadoras />}/>
        <Route path="/m2m-iot" element={<M2m />} />
        <Route path="/como-funciona" element={<ComoFunciona />} />
        <Route path="/contatos" element={<Contatos />} />
        <Route path="/privacidade" element={<Privacidade />} />
        <Route path="/termos-e-condicoes" element={<TermosEcond />} />
        <Route path="/recarga-foryou" element={<RecargaForyou />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/voz-sms" element={<VozSms />} />
        <Route path="/solucoes-corporativas" element={<SolucoesCorp />} />
        <Route path="/mysimtravel-grupos" element={<MysimGroups />} />
        <Route path="/chip-internet-aeroportos"element={<ChipInternetAirport />}/>
        <Route path="/dicas-para-viagem" element={<DicasToViagens />} />
        <Route path="/distribuidores" element={<RevendasInt />} />
        <Route path="/iphone7+" element={<Iphone7 />} />
        <Route path="/iphone11" element={<Iphone11 />} />
        <Route path="/iphoneSE" element={<IphoneSE />} />
        <Route path="/galaxy" element={<Galaxy />} />
        <Route path="/ja" element={<JA />} />
        <Route path="/xiaomi" element={<Xiaomi />} />
        <Route path="/motog" element={<Motog />} />
        <Route path="/motorazr" element={<Motorazr />} />
        <Route path="/SejaUmaRevenda" element={<Seja_uma_revenda />} />
        <Route path="/informacoes-mysimtravel" element={<WebDocs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
