import "./index.css";
import "./responsive-iphonese.css";
import Header from "../../header/index";
import Footer from '../../footer/index';
import { Link, useNavigate } from 'react-router-dom';

export default function Iphone11(){
  const navigate = useNavigate();
  


  function link_to_models(){
    navigate("/como-funciona");
  
     setTimeout(() => {
      let divPrincipal = document.querySelector(".marcas-container-config");
      let divMostrar1 = document.querySelector("#iphone");
  
      divPrincipal.style.display = "none";
      divMostrar1.style.display = "block";
  },50)
  
  }

    function animation(){
        let samsungModels = document.querySelector('#samsung-models'); //CONFIGURAÇÃO INICIAL
        let btnModels = document.querySelector('#btnmodels'); // Botão config inicial
        let samsungApp = document.querySelector("#samsungapp") // APP físico
        
        
        let textPrimary = document.querySelector('#textprimary2')
        
        let samsungRecharge = document.querySelector('#samsungteste')
        let btnRecharge = document.querySelector('#rechargeteste')
        
        // retornar
        let btnReturn = document.querySelector('#btnreturn') 
        let containerSettings = document.querySelector('#containersettings') // TELA DO CHIP FÍSICO
        let btnFisico = document.querySelector('#divfisico')
        containerSettings.style.display = "flex";//aqui eu estou falando que eu quero exibir para o cliente primeiro a opçõ do físico, Se eu não quero mostrar nada, eu apenas devo remover o display:flex
        
        // dicas btn
        let dicasModels = document.querySelector('#dicasmodels'); //dicas models
        let dicasEconomy = document.querySelector('#dicasiphone-models');
        
        // dicas eSIM
        
        let esimDicas = document.querySelector('#dicaseconomia-esim');
        let esimEconomia = document.querySelector('#dicas-virtual');
        
        // CHIP VIRTUAL
        
        let containerVirtual = document.querySelector('#containersettingsvirtual')
        let btnVirtual = document.querySelector('#btnvirtual')
        
        
        // Chip virtual instalação eSIM
        let btnInstall = document.querySelector('#install-esim')
        let installModels = document.querySelector('#virtual-install')
        
        // Chip virtual Configuração inicial eSIM
        let btnconfigEsim = document.querySelector('#settingsinicial')
        let configesimModels = document.querySelector('#virtual-config')
        
        // Chip virtual app
        let btnAppesim = document.querySelector('#esim-app')
        let appesimModels = document.querySelector('#virtual-app')
        
        // Chip virtual recharge
        let btnRechargeesim = document.querySelector('#rechargeesim')
        let rechargeesimModels = document.querySelector('#virtual-recharge')
        
        
        
        
        
        // Função de esconder e aparecer o CHIP VIRTUAL OU CHIP FÍSICO
        btnFisico.addEventListener("click", function(){
        
            textPrimary.style.display = 'none'
        
            containerSettings.style.display = 'flex'
            containerVirtual.style.display = 'none'
            
            window.scrollTo({
                top: 600,
                behavior: 'smooth'
            });
        
        
            document.getElementById("divfisico").style.borderColor = "#ff5000";
            document.getElementById("chipfisico").style.color = "#ff5000";
            document.getElementById("btnvirtual").style.borderColor = "#C4C4C4";
            document.getElementById("chipvirtual").style.color = "#C4c4C6";
        });
        
        btnVirtual.addEventListener("click", function(){
            textPrimary.style.display = 'none'
            containerSettings.style.display = 'none';
            containerVirtual.style.display = 'flex'
        
            window.scrollTo({
                top: 600,
                behavior: 'smooth'
            });
        
            document.getElementById("btnvirtual").style.borderColor = "#ff5000";
            document.getElementById("chipvirtual").style.color = "#ff5000";
            document.getElementById("divfisico").style.borderColor = "#C4C4C4";
            document.getElementById("chipfisico").style.color = "#C4c4C6";
        
        });
        
        
        // ------------------------------------------------------------------------
        
        dicasModels.addEventListener("click", function(){
            dicasEconomy.style.display = "block"
            samsungModels.style.display = "none"
            samsungApp.style.display = "none"
            samsungRecharge.style.display = "none"
            document.getElementById("h1text1").style.color = "#2d2d2d"
            document.getElementById("h1text2").style.color = "#2d2d2d"
            document.getElementById("h1text3").style.color = "#2d2d2d" 
            document.getElementById("h1textdicas").style.color = "#ff5000"
        });
        
        
        
        
        // CHIP FÍSICO FUNÇÕES
        
        btnModels.addEventListener("click", function(){
            samsungModels.style.display = "none"
            samsungApp.style.display = "block"
            samsungRecharge.style.display = "none"
            dicasEconomy.style.display = "none"
            document.getElementById("h1text1").style.color = "#2d2d2d"
            document.getElementById("h1text2").style.color = "#ff5000"
            document.getElementById("h1text3").style.color = "#2d2d2d"
            document.getElementById("h1textdicas").style.color = "#2d2d2d";
        });
        
        
        btnRecharge.addEventListener("click", function(){
            samsungModels.style.display = "none"  
            samsungApp.style.display = "none"
            samsungRecharge.style.display = "block"
            dicasEconomy.style.display = "none"
            document.getElementById("h1text1").style.color = "#2d2d2d";
            document.getElementById("h1text2").style.color = "#2d2d2d";
            document.getElementById("h1text3").style.color = "#ff5000";
            document.getElementById("h1textdicas").style.color = "#2d2d2d";
        });
        
        btnReturn.addEventListener("click", function(){
            samsungModels.style.display = "block"  
            samsungApp.style.display = "none"
            samsungRecharge.style.display = "none"
            dicasEconomy.style.display = "none"
            document.getElementById("h1text1").style.color = "#ff5000";
            document.getElementById("h1text2").style.color = "#2d2d2d";
            document.getElementById("h1text3").style.color = "#2d2d2d";
            document.getElementById("h1textdicas").style.color = "#2d2d2d";
        });
        
        // VIRTUAL FUNÇÕES
        
        
        esimDicas.addEventListener("click", function(){
            installModels.style.display = "none"
            esimEconomia.style.display = "block"
            configesimModels.style.display = "none"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "none"
            
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-esimdicas").style.color = "#ff5000"
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        
        });
        
        btnInstall.addEventListener("click", function(){
            installModels.style.display = "block"
            esimEconomia.style.display = "none"
            configesimModels.style.display = "none"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "none"
            
            document.getElementById("h1-install").style.color = "#ff5000"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            document.getElementById("h2-esimdicas").style.color = "#2d2d2d"
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        
        });
        
        btnconfigEsim.addEventListener("click", function(){
            installModels.style.display = "none"
            esimEconomia.style.display = "none"
            configesimModels.style.display = "block"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "none"
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#ff5000"
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            document.getElementById("h2-esimdicas").style.color = "#2d2d2d"
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        });
        
        btnAppesim.addEventListener("click", function(){
            installModels.style.display = "none"
            esimEconomia.style.display = "none"
            configesimModels.style.display = "none"
            appesimModels.style.display = "block"
            rechargeesimModels.style.display = "none"
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-app-esim").style.color = "#ff5000"
            document.getElementById("h2-esimdicas").style.color = "#2d2d2d"
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        
        });
        
        btnRechargeesim.addEventListener("click", function(){
            installModels.style.display = "none"
            esimEconomia.style.display = "none"
            configesimModels.style.display = "none"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "block"
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            document.getElementById("h2-esimdicas").style.color = "#2d2d2d"
            document.getElementById("h2-recharge-esim").style.color = "#ff5000"
        
        });
                
     } 

     function accordionIphone11(id){

      const accordionItemHeaders = document.getElementById(`${id}`);console.log(accordionItemHeaders)

          
          accordionItemHeaders.classList.toggle("active");
          const accordionItemBody = accordionItemHeaders.nextElementSibling;
          if(accordionItemHeaders.classList.contains("active")) {
            accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
          }
          else {
            accordionItemBody.style.maxHeight = 0;
          }

  }


  function physycal_accordion_iphone11(){
    let accordionFisicosiphone11 = document.querySelector('#section_mobile_iphone11_accordion_fisico');
    let accordionVirtuaisiphone11 = document.querySelector('#section_mobile_iphone11_accordion_virtual');
  
  
    accordionFisicosiphone11.style.display = 'block';
    accordionVirtuaisiphone11.style.display = 'none';
    
    
    document.getElementById("option_mobile_virtual_iphone11").style.borderColor = "#C4C4C4";
    document.getElementById("option_mobile_virtual_iphone11_h1").style.color = "#C4C4C4";
    
    document.getElementById("option_mobile_fisico_iphone11").style.borderColor = "#ff5000";
    document.getElementById("option_mobile_fisico_iphone11_h1").style.color = "#ff5000";
  }
  
  
  
  
  
  
  function virtual_accordion_iphone11(){
    let accordionFisicosiphone11 = document.querySelector('#section_mobile_iphone11_accordion_fisico');
    let accordionVirtuaisiphone11 = document.querySelector('#section_mobile_iphone11_accordion_virtual');
  
    accordionFisicosiphone11.style.display = 'none';
    accordionVirtuaisiphone11.style.display = 'block';
  
  
    document.getElementById("option_mobile_fisico_iphone11").style.borderColor = "#C4C4C4";
    document.getElementById("option_mobile_fisico_iphone11_h1").style.color = "#C4C4C4";
  
    document.getElementById("option_mobile_virtual_iphone11").style.borderColor = "#ff5000";
    document.getElementById("option_mobile_virtual_iphone11_h1").style.color = "#ff5000";
  
  }
   
     setTimeout(() => {
       animation()
    },10)
   
    return(
        <div>
            <Header backgroundcolor="#2d2d2f"/>
             <div class="content-config">

             <div className="text_mobile_iphone7_top">
                <h1>Apple</h1>
                <h2>Tutorial para configuração Iphone SE</h2>
                <p>Suporte e dicas para configurar seu celular antes da viagem</p>
            </div>
                    <div class="config-div">
                    <div class="config-text">
                    <h1>Apple</h1>
                    <h2>Tuturial para configuração Iphone SE e modelos superiores</h2>
                    <p>Suporte e dicas para configurar seu celular antes da sua viagem</p>
                    </div>

                    <div class="config-samsung2">
                    <img src="/assets/img/iphone-atual/mockup-iphone11.svg" alt="Mockup config Iphone"/>
                    </div>
                </div>
           </div>

           <div class="container-config">
                <div class="links-config">   <Link to="/como-funciona">Configuração</Link>   <span> {">"} </span>   <div onClick={link_to_models}>Modelos </div>   <span> {">"} </span>   <Link to="" class="spanlink">Iphone SE</Link></div>

             <div class="config-content">
                <h1 class="textprimary">Configure seu Iphone para utilizar o <span class="span-orange">mysimtravel</span></h1>
                <h3 class="textprimary1" id="textprimary2">Qual o modelo do seu CHIP ?</h3>
                <div class="flex-grid">
                    
                    <div class="fisico" id="divfisico">
                    <h1 id="chipfisico">Chip Físico</h1>
                    </div>

                    <div class="virtual" id="btnvirtual">
                    <h1 id="chipvirtual">Chip Virtual</h1>
                    </div>
                </div>
              </div>




              <div class="settings js-scroll" id="containersettings">
                    <div class="settings-text">
                        <div class="inicial ativo" id="btnreturn">
                        <h1 class="text-h1" id="h1text1">Configuração inicial</h1>
                        </div>

                        <div class="app" id="dicasmodels">
                        <h1 id="h1textdicas">Dicas de economia de dados</h1>
                        </div>

                        <div class="app" id="btnmodels">
                        <h1 id="h1text2">Ativação pelo app mysimtravel FOR YOU</h1>
                        </div>

                        <div class="recarga" id="rechargeteste">
                        <h1 id="h1text3">Recarga</h1>
                        </div>
                    </div>

                    <div class="settings-models" id="samsung-models">

                        <h1 class="roaming">Ativação Roaming</h1>

                        <div class="settings-instru">
                        <div class="config-1">
                        <h1>1 - Após inserir o mysimtravel no seu celular vá na tela de ajustes e selecione a opção <span>Celular</span></h1>
                        <div class="imgconfig-1">
                            <img src="/assets/img/iphone-atual/config-1.svg" alt="Print config 1"/>
                            </div>
                        </div>
                        <div class="config-2">
                            <h1>2 - Selecione <span>Principal</span> ou <span>Opções de dados celulares</span> e ative o <span>Dados em Roaming</span></h1>
                            <div class="imgconfig-2">
                            <img src="/assets/img/iphone-atual/config-2.svg" alt="Print config 2"/>
                            <img src="/assets/img/iphone-atual/config-3.svg" alt="Print config 3"/>
                            </div>
                            </div>

                        <div class="config-3">
                            
                            <h1>3 - Depois vá em <span>Rede de dados Celulares</span> e no primeiro <span>APN</span> insira: <span>internet</span> Caso já esteja, não é necessário alterar</h1>
                            <div class="imgconfig-3">
                                <img src="/assets/img/iphone-atual/config-4.svg" alt="Print config 5"/>
                                <img src="/assets/img/iphone-atual/config-5.svg" alt="Print config 6"/>
                            </div>
                        </div>

                            <div class="config-3">
                                <h1>4 - Após isso é <span>IMPORTANTE</span> que você siga as dicas de economia de dados do iphone <a href="#">clicando aqui</a></h1>
                            </div>
                          </div>


                    </div>

                    <div class="settings-models-dicas" id="dicasiphone-models">
                        <h1 class="roaming">Dicas de economia de dados</h1>
                        <div class="settings-instru">
                        <div class="config-1">
                        <h1>1 - Vá em <span>Ajustes</span> e depois <span>Celular</span></h1>
                        <div class="imgconfig-1">
                            <img src="/assets/img/iphone-atual/dicas-1.svg" alt="Print config 1"/>
                            </div>
                        </div>
                        <div class="config-2">
                            <h1>2 - Role a tela até o final para encontrar as opções de <span>Assist. Wifi</span> e <span>ICloud Drive</span></h1>
                            <div class="imgconfig-2">
                            <img src="/assets/img/iphone-atual/dicas-2.svg" alt="Print config 2"/>
                            </div>
                            </div>

                            <div class="config-3">
                            <h1>3 - Depois volte novamente em <span>Ajustes</span> e procure pela opção <span>Geral</span> e em seguida <span>Atualização em 2º Plano</span></h1>
                            <div class="imgconfig-3">
                                <img src="/assets/img/iphone-atual/dicas-3.svg" alt="Print config 5"/>
                                <img src="/assets/img/iphone-atual/dicas-4.svg" alt="Print config 6"/>
                                </div>
                            </div>

                            
                            <div class="config-3">
                                <h1>4 - Selecione <span>Atualização em 2º Plano</span> e depois selecione <span>Wi-fi</span></h1>
                                <div class="imgconfig-3">
                                <img src="/assets/img/iphone-atual/dicas-5.svg" alt="Print config 5"/>
                                <img src="/assets/img/iphone-atual/dicas-6.svg" alt="Print config 6"/>
                                </div>
                                </div>

                                <div class="config-3">
                                <h1>5 - Por Último, volte em <span>Ajustes</span> selecione a opção <span>Apple Store</span> e em <span>Dados Celulares</span> desative <span>Downloads Automáticos</span></h1>
                                <div class="imgconfig-3">
                                    <img src="/assets/img/iphone-atual/dicas-7.svg" alt="Print config 5"/>
                                    <img src="/assets/img/iphone-atual/dicas-8.svg" alt="Print config 6"/>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="settings-app" id="samsungapp">
                        <h1 class="configapp">Configuração Aplicativo</h1>
                        <div class="settings-appins">
                        <div class="app-1">
                        <h1>1 - Faça o login com <span>email (ou  nº do chip) e a senha cadastrada no ato da compra</span></h1>
                        <div class="imgapp-1">
                            <img src="/assets/img/iphone-atual/app-1.svg" alt="Print app 1"/>
                            </div>
                        </div>
                        <div class="app-2">
                            <h1>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado) E para ativar o plano selecione <span>Tarifas</span></h1>
                            <div class="imgapp-2">
                            <img src="/assets/img/iphone-atual/app-2.svg" alt="Print app 2"/>
                            </div>
                            </div>

                            <div class="app-3">
                            <h1>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span>Barra de pesquisa</span> ou <span>procurar na lista de países</span></h1>
                            <div class="imgapp-3">
                                <img src="/assets/img/iphone-atual/app-3.svg" alt="Print app 3"/>
                                
                                </div>
                            </div>

                            <div class="app-3">
                                <h1>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na <span>etapa 2 ou 3)</span></h1>
                                <div class="imgapp-3">
                                <img src="/assets/img/iphone-atual/app-4.svg" alt="Print app 3"/>
                                <img src="/assets/img/iphone-atual/app-5.svg" alt="Print app 4"/>
                                </div>
                                </div>

                                <div class="app-3">
                                <h1>5 - <span>Atenção: esse procedimento deve ser feito somente no dia da viagem</span><br/>Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative seu plano</h1>
                                <div class="imgapp-3">
                                    <img src="/assets/img/iphone-atual/app-6.svg" alt="Print app 6"/>
                                    <img src="/assets/img/iphone-atual/app-7.svg" alt="Print app 7"/>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="recarga-setting" id="samsungteste">
                        <h1 class="configrecargas">Recarga</h1>
                        <div class="settings-recarga1">

                            <div class="recarga-1">
                                <h1>1 - Na tela inicial, vá em <span>Comprar créditos</span> no menu inferior</h1>
                                <div class="imgrecarga-1">
                                    <img src="/assets/img/iphone-atual/recarga-1.svg" alt="Print app 1"/>
                                </div>
                            </div>

                        <div class="recarga-2">
                            <h1>2 - Selecione o nº do chip que gostaria de recarregar</h1>
                            <div class="imgapp-2">
                               <img src="/assets/img/iphone-atual/recarga-2.svg" alt="Print app 2"/>
                            </div>
                        </div>

                        <div class="recarga-3">
                            <h1>3 - Insira o valor de acordo com o <span>plano que deseja recarregar</span> e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no máximo 5 minutos</h1>
                            <div class="imgapp-3">
                                <img src="/assets/img/iphone-atual/recarga-3.svg" alt="Print app 3"/>
                            </div>

                            <h1>Pronto, agora basta renovar o seu plano novamente, indo na aba <span>Ativação pelo app mysimtravel FOR YOU</span></h1>
                        </div>  

                        </div>
                    </div>
    </div>
    


    <div class="settings-virtual" id="containersettingsvirtual">
      <div class="settings-text-virtual">
        <div class="config-instalacao" id="install-esim">
          <h1 class="h1-instalacao" id="h1-install">Instalação eSIM</h1>
        </div>

        <div class="config-inicial" id="settingsinicial">
          <h1 class="h1-esimconfig" id="h2-esiminstall">Configuração inicial</h1>
        </div>

        <div class="config-inicial" id="dicaseconomia-esim">
          <h1 class="h1-ativarapp" id="h2-esimdicas">Dicas de economia de dados</h1>
        </div>

        <div class="ativarapp" id="esim-app">
          <h1 class="h1-ativarapp" id="h2-app-esim">Ativação pelo app mysimtravel FOR YOU</h1>
        </div>

        <div class="recargaesim" id="rechargeesim">
          <h1 class="h1-rechargeapp" id="h2-recharge-esim">Recarga</h1>
        </div>
      </div>

      <div class="settings-virtual-install" id="virtual-install">
        <h1 class="install-qrcode">Instalação eSIM</h1>
        <div class="settings-virtual-instrucoes">
          <div class="install-1">
          <h1>1 - Para efetuar a leitura do QRCode vá em <span>Ajustes</span> e depois <span>Celular</span></h1>
          <div class="imginstall-1">
            <img src="/assets/img/iphone-atual/esim/install1.svg" alt="Print install 1"/>
            </div>
          </div>
          <div class="install-2">
            <h1>2 - Selecione a opção chamada <span>Adicionar Plano Celular ou Adicionar eSIM</span></h1>
            <div class="imginstall-2">
              <img src="/assets/img/iphone-atual/esim/install2.svg" alt="Print install 2" />
              
              </div>
            </div>

            <div class="install-3">
              <h1>3 - Faça a leitura do QRCode e logo após isso ative o seu eSIM clicando <span>OK</span> e depois em <span>Continuar</span></h1>
              <div class="imginstall-3">
                <img src="/assets/img/iphone-atual/esim/install3.svg" alt="Print install 4"/>
                <img src="/assets/img/iphone-atual/esim/install4.svg" alt="Print install 5"/>
                <img src="/assets/img/iphone-atual/esim/install5.svg" alt="Print install 6"/>
                </div>
              </div>

              <div class="install-4">
                <h1>4 - Nessa parte você define qual será a etiqueta, ou seja, o nome que você vai dar ao eSIM, recomendamos que seja <span>Turismo</span></h1>
                <div class="imginstall-4">
                  <img src="/assets/img/iphone-atual/esim/install6.svg" alt="Print install 6"/>
                  <img src="/assets/img/iphone-atual/esim/install7.svg" alt="Print install 7"/>
                  </div>
                </div>

                <div class="install-4">
                  <h1>5 - Nas opções de <span>Dados Celulares, Linha Padrão e IMessage e Facetime</span> direcione para a linha de Turismo e pronto, seu eSIM já instalado</h1>
                  <div class="imginstall-4">
                    <img src="/assets/img/iphone-atual/esim/install8.svg" alt="Print install 8"/>
                    <img src="/assets/img/iphone-atual/esim/install9.svg" alt="Print install 9"/>
                    <img src="/assets/img/iphone-atual/esim/install10.svg" alt="Print install 10"/>
                    </div>
                  </div>
        </div>
      </div>

      <div class="settings-virtual-config" id="virtual-config">
        <h1 class="config-qrcode">Instalação eSIM</h1>
        <div class="settings-virtual-instrucoes-config">
          <div class="configinstall-1">
          <h1>1 - Após inserir o mysimtravel no seu celular vá na tela de ajustes e selecione a opção <span>Celular</span></h1>
          <div class="imgconfiginstall-1">
            <img src="/assets/img/iphone-atual/esim/config1.svg" alt="Print config 1"/>
            </div>
          </div>
          <div class="configinstall-2">
            <h1>2 - Selecione <span>Turismo</span> e ative o <span>Dados em roaming</span></h1>
            <div class="imgconfiginstall-2">
              <img src="/assets/img/iphone-atual/esim/config2.svg" alt="Print configinstall 2"/>
              <img src="/assets/img/iphone-atual/esim/config3.svg" alt="Print configinstall 3"/>
            </div>
            </div>

            <div class="configinstall-3">
              <h1>3 - Depois vá em <span>Rede de Dados Celulares</span> e no primeiro <span>APN</span> insira: <span>internet</span> Caso já esteja, não é necessário alterar</h1>
              <div class="imgconfiginstall-3">
                <img src="/assets/img/iphone-atual/esim/config4.svg" alt="Print configinstall 4"/>
                <img src="/assets/img/iphone-atual/esim/config5.svg" alt="Print configinstall 5"/>
              </div>

                <h1>4 - Após isso é <span>IMPORTANTE</span> que você siga as dicas de economia de dados do IPHONE <a href="#">clicando aqui</a></h1>
              </div>
        </div>
      </div>


      <div class="settings-virtual-app" id="dicas-virtual">
        <h1 class="app-qrcode">Dicas de economia de dados</h1>
        <div class="settings-virtual-instrucoes-app">
          <div class="appinstall-1">
          <h1>1 - Vá em <span>Ajustes</span> e depois <span>Celular</span></h1>
          <div class="imgappinstall-1">
            <img src="/assets/img/iphone-atual/esim/dicasesim1.svg" alt="Print dicas eSIM 1"/>
            </div>
          </div>
          <div class="appinstall-2">
            <h1>2 - Role a tela até o final para encontrar as opções de <span>Assist. Wifi e ICloud Drive</span></h1>
            <div class="imgappinstall-2">
              <img src="/assets/img/iphone-atual/esim/dicasesim2.svg" alt="Print appinstall 2"/>
              </div>
            </div>

            <div class="appinstall-3">
              <h1>3 - Depois volte novamente em <span>Ajustes</span> e procure pela opção <span>Geral</span> e em seguida <span>Atualização em 2º Plano</span></h1>
              <div class="imgappinstall-3">
                <img src="/assets/img/iphone-atual/esim/dicasesim3.svg" alt="Print appinstall 3"/>
                <img src="/assets/img/iphone-atual/esim/dicasesim4.svg" alt="Print appinstall 4"/>
                </div>
              </div>

              <div class="appinstall-4">
                <h1>4 - Selecione <span>Atualização em 2ª Plano</span>e depois selecione Wi-fi</h1>
                <div class="imgappinstall-4">
                  <img src="/assets/img/iphone-atual/esim/dicasesim5.svg" alt="Print appinstall 5"/>
                  <img src="/assets/img/iphone-atual/esim/dicasesim6.svg" alt="Print appinstall 6"/>
                  </div>
                </div>

                <div class="appinstall-5">
                  <h1>5 - Por Último, volte em <span>Ajustes</span> selecione a opção <span>App Store</span> e em <span>Dados Celulares</span> desative <span>Downloads Automáticos</span></h1>
                  <div class="imgappinstall-4">
                    <img src="/assets/img/iphone-atual/esim/dicasesim7.svg" alt="Print appinstall 7" />
                    <img src="/assets/img/iphone-atual/esim/dicasesim8.svg" alt="Print appinstall 8" />
                </div>
                  </div>
        </div>
      </div>

      <div class="settings-virtual-app" id="virtual-app">
        <h1 class="app-qrcode">Ativação Roaming</h1>
        <div class="settings-appins">
          <div class="app-1">
          <h1>1 - Faça o login com <span>email (ou  nº do chip) e a senha cadastrada no ato da compra</span></h1>
          <div class="imgapp-1">
            <img src="/assets/img/iphone-atual/app-1.svg" alt="Print app 1" />
            </div>
          </div>
          <div class="app-2">
            <h1>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado) E para ativar o plano selecione <span>Tarifas</span></h1>
            <div class="imgapp-2">
              <img src="/assets/img/iphone-atual/app-2.svg" alt="Print app 2" />
              </div>
            </div>

            <div class="app-3">
              <h1>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span>Barra de pesquisa</span> ou <span>procurar na lista de países</span></h1>
              <div class="imgapp-3">
                <img src="/assets/img/iphone-atual/app-3.svg" alt="Print app 3" />
                
                </div>
              </div>

              <div class="app-3">
                <h1>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na <span>etapa 2 ou 3)</span></h1>
                <div class="imgapp-3">
                  <img src="/assets/img/iphone-atual/app-4.svg" alt="Print app 3"/>
                  <img src="/assets/img/iphone-atual/app-5.svg" alt="Print app 4"/>
                  </div>
                </div>

                <div class="app-3">
                  <h1>5 - <span>Atenção: esse procedimento deve ser feito somente no dia da viagem</span><br/>Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative seu plano</h1>
                  <div class="imgapp-3">
                    <img src="/assets/img/iphone-atual/app-6.svg" alt="Print app 6"/>
                    <img src="/assets/img/iphone-atual/app-7.svg" alt="Print app 7"/>
                    </div>
                  </div>
        </div>
      </div>

      <div class="settings-virtual-recharge" id="virtual-recharge">
        <h1 class="recharge-qrcode">Ativação Roaming</h1>
        <div class="settings-virtual-instrucoes-recharge">
          <div class="recarga-1">
            <h1>1 - Na tela inicial, vá em <span>Comprar créditos</span> no menu inferior</h1>
            <div class="imgrecarga-1">
              <img src="/assets/img/iphone-atual/recarga-1.svg" alt="Print app 1"/>
              </div>
            </div>
            <div class="recarga-2">
              <h1>2 - Selecione o nº do chip que gostaria de recarregar</h1>
              <div class="imgapp-2">
                <img src="/assets/img/iphone-atual/recarga-2.svg" alt="Print app 2"/>
                </div>
              </div>

              <div class="recarga-3">
                <h1>3 - Insira o valor de acordo com o <span>plano que deseja recarregar</span> e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no máximo 5 minutos</h1>
                <div class="imgapp-3">
                  <img src="/assets/img/iphone-atual/esim/recarga-3.svg" alt="Print app 3"/>
                  </div>
  
                  <h1>Pronto, agora basta renovar o seu plano novamente, qualquer dúvida te ajudamos por <a href="#">aqui</a></h1>
                </div>  

        </div>
      </div>

      

      
    </div>

  </div> 




  <div className="view_mobile">


    
            <h1 className="mobile_text_paragrafo">Configure seu Iphone para utilizar o <span>mysimtravel</span> </h1>

          <div className="mobile_iphone11_select_chip">
            <div className="mobile_fisico_iphone11" id="option_mobile_fisico_iphone11" onClick={physycal_accordion_iphone11}>
              <h1 id="option_mobile_fisico_iphone11_h1">Chip Físico</h1>
            </div>

            <div className="mobile_virtual_iphone11" id="option_mobile_virtual_iphone11" onClick={virtual_accordion_iphone11}>
              <h1 id="option_mobile_virtual_iphone11_h1">Chip Virtual</h1>
            </div>
          </div>

        <div id="section_mobile_iphone11_accordion_fisico">
          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="configinicialiphone11" onClick={ () => {accordionIphone11("configinicialiphone11")}}>
                <h1>Configuração inicial</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação em Roaming</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Após inserir o mysimtravel no seu celular vá a tela de ajustes e selecione a opção <span className="mobile_iphone7_span">Celular</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/config-1.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione Opções de dados celulares e ative o Dados em Roaming </p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/config-2.svg" alt="Print config 1"/>
                        <img src="/assets/img/iphone-atual/config-3.svg" alt="Print config 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Depois vá em Rede de Dados Celulares e no primeiro APN insira: internet Caso já esteja, não é necessário alterar </p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/config-4.svg" alt="Print config 5"/>
                        <img src="/assets/img/iphone-atual/config-5.svg" alt="Print config 6"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="dicasdedados7" onClick={ () => {accordionIphone11("dicasdedados7")}}>
                <h1>Dicas de economia de dados</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Dicas de economia de dados</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Vá em Ajustes e depois Celular</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/dicas-1.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Role a tela até o final para encontrar as opções de Assist. Wifi e iCloud Drive</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/dicas-2.svg" alt="Print config 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Depois volte novamente em Ajustes e procure pela opção Geral e em seguida
 Atualização em 2° plano</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/dicas-3.svg" alt="Print config 1"/>

                        <img src="/assets/img/iphone-atual/dicas-4.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4. Selecione Atualização em 2° plano e depois selecione Wi-Fi</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/dicas-5.svg" alt="Print config 1"/>
                        <img src="/assets/img/iphone-atual/dicas-6.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Por Último, volte em <span className="mobile_iphone7_span">Ajustes</span> selecione a opção <span className="mobile_iphone7_span">Apple Store</span> e em <span className="mobile_iphone7_span">Dados Celulares</span> desative <span className="mobile_iphone7_span">Downloads Automáticos</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/dicas-7.svg" alt="Print config 1"/>
                        <img src="/assets/img/iphone-atual/dicas-8.svg" alt="Print config 1"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="ativacaopeloapp7" onClick={ () => {accordionIphone11("ativacaopeloapp7")}}>
                <h1>Ativação pelo app mysimtravel FORYOU</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação pelo aplicativo</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Faça o login com o email (ou n° do chip) e a senha cadastrada no ato da compra</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado)
E para ativar o plano selecione Tarifas</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span className="mobile_iphone7_span">Barra de pesquisa</span> ou <span className="mobile_iphone7_span">procurar na lista de países</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-3.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na etapa 2 ou 3)</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-4.svg" alt="Print app 1"/>

                        <img src="/assets/img/iphone-atual/app-5.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Atenção: esse procedimento deve ser feito somente no dia da viagem
Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative o seu plano</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-6.svg" alt="Print app 1"/>
                        <img src="/assets/img/iphone-atual/app-7.svg" alt="Print app 7"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="recargaapp7" onClick={ () => {accordionIphone11("recargaapp7")}}>
                <h1>Recarga</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Recarga</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Na tela inicial, vá em <span className="mobile_iphone7_span">Comprar Créditos</span> no menu anterior</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/recarga-1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione o nº do chip que gostaria de recarregar</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/recarga-2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o valor de acordo com o plano que deseja recarregar e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no maximo 5 minutos</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/recarga-3.svg" alt="Print app 1"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





        <div id="section_mobile_iphone11_accordion_virtual">
          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="configinstallesim" onClick={ () => {accordionIphone11("configinstallesim")}}>
                <h1>Instalação eSIM</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação em Roaming</h1>

                     <div className="div_mobile_iphone7">
                     <p>1 - Para efetuar a leitura do QRCode vá em <span className="mobile_iphone7_span">Ajustes</span> e depois <span className="mobile_iphone7_span">Celular</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/install1.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione a opção chamada <span className="mobile_iphone7_span">Adicionar Plano Celular ou Adicionar eSIM</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/install2.svg" alt="Print config 1"/>
                        
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Faça a leitura do QRCode e logo após isso ative o seu eSIM clicando <span className="mobile_iphone7_span">OK</span> e depois em <span className="mobile_iphone7_span">Continuar</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/install3.svg" alt="Print install 4"/>
                        <img src="/assets/img/iphone-atual/esim/install4.svg" alt="Print install 5"/>
                        <img src="/assets/img/iphone-atual/esim/install5.svg" alt="Print install 6"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Nessa parte você define qual será a etiqueta, ou seja, o nome que você vai dar ao eSIM, recomendamos que seja <span className="mobile_iphone7_span">Turismo</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/install6.svg" alt="Print install 6"/>
                        <img src="/assets/img/iphone-atual/esim/install7.svg" alt="Print install 7"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Nas opções de <span className="mobile_iphone7_span">Dados Celulares, Linha Padrão e IMessage e Facetime</span> direcione para a linha de Turismo e pronto, seu eSIM já instalado</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/install8.svg" alt="Print install 8"/>
                        <img src="/assets/img/iphone-atual/esim/install9.svg" alt="Print install 9"/>
                        <img src="/assets/img/iphone-atual/esim/install10.svg" alt="Print install 10"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="dicasdedados7esim" onClick={ () => {accordionIphone11("dicasdedados7esim")}}>
                <h1>Configuração Inicial</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Configuração Inicial</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Após inserir o mysimtravel no seu celular vá na tela de ajustes e selecione a opção <span className="mobile_iphone7_span">Celular</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/config1.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione <span className="mobile_iphone7_span">Turismo</span> e ative o <span className="mobile_iphone7_span">Dados em roaming</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/config2.svg" alt="Print configinstall 2"/>
                        <img src="/assets/img/iphone-atual/esim/config3.svg" alt="Print configinstall 3"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Depois vá em <span className="mobile_iphone7_span">Rede de Dados Celulares</span> e no primeiro <span className="mobile_iphone7_span">APN</span> insira: <span className="mobile_iphone7_span">internet</span> Caso já esteja, não é necessário alterar</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/config4.svg" alt="Print configinstall 4"/>
                        <img src="/assets/img/iphone-atual/esim/config5.svg" alt="Print configinstall 5"/>
                        </div>
                    </div>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="dicasdedados7esim" onClick={ () => {accordionIphone11("dicasdedados7esim")}}>
                <h1>Dicas de economia de dados</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Dicas de economia de dados</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Vá em <span className="mobile_iphone7_span">Ajustes</span> e depois <span className="mobile_iphone7_span">Celular</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/dicasesim1.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Role a tela até o final para encontrar as opções de <span className="mobile_iphone7_span">Assist. Wifi e ICloud Drive</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/dicasesim2.svg" alt="Print appinstall 2"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Depois volte novamente em <span className="mobile_iphone7_span">Ajustes</span> e procure pela opção <span className="mobile_iphone7_span">Geral</span> e em seguida <span className="mobile_iphone7_span">Atualização em 2º Plano</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/dicasesim3.svg" alt="Print appinstall 3"/>
                        <img src="/assets/img/iphone-atual/esim/dicasesim4.svg" alt="Print appinstall 4"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Selecione <span className="mobile_iphone7_span">Atualização em 2ª Plano</span>e depois selecione Wi-fi</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/dicasesim5.svg" alt="Print appinstall 5"/>
                        <img src="/assets/img/iphone-atual/esim/dicasesim6.svg" alt="Print appinstall 6"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Por Último, volte em <span className="mobile_iphone7_span">Ajustes</span> selecione a opção <span className="mobile_iphone7_span">App Store</span> e em <span className="mobile_iphone7_span">Dados Celulares</span> desative <span className="mobile_iphone7_span">Downloads Automáticos</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/esim/dicasesim7.svg" alt="Print appinstall 7" />
                        <img src="/assets/img/iphone-atual/esim/dicasesim8.svg" alt="Print appinstall 8" />
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="ativacaopeloapp7esim" onClick={ () => {accordionIphone11("ativacaopeloapp7esim")}}>
                <h1>Ativação pelo app mysimtravel FORYOU</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação pelo aplicativo</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Faça o login com o email (ou n° do chip) e a senha cadastrada no ato da compra</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado)
E para ativar o plano selecione Tarifas</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span className="mobile_iphone7_span">Barra de pesquisa</span> ou <span className="mobile_iphone7_span">procurar na lista de países</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-3.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na etapa 2 ou 3)</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-4.svg" alt="Print app 3"/>
                        <img src="/assets/img/iphone-atual/app-5.svg" alt="Print app 4"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Atenção: esse procedimento deve ser feito somente no dia da viagem
Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative o seu plano</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/app-6.svg" alt="Print app 6"/>
                        <img src="/assets/img/iphone-atual/app-7.svg" alt="Print app 7"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="recargaapp7esim" onClick={ () => {accordionIphone11("recargaapp7esim")}}>
                <h1>Recarga</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Recarga</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Na tela inicial, vá em <span className="mobile_iphone7_span">Comprar Créditos</span> no menu anterior</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/recarga-1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione o nº do chip que gostaria de recarregar</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/recarga-2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o valor de acordo com o plano que deseja recarregar e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no maximo 5 minutos</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/iphone-atual/recarga-3.svg" alt="Print app 3"/>
                        </div>
                    </div>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


          </div>
           <Footer/>
        </div>
    )
}