import "./index.css";
import "./responsive-razr.css";
import Header from "../../header/index";
import Footer from '../../footer/index';
import { Link,useNavigate } from 'react-router-dom';

export default function Moto_razr(){
  const navigate = useNavigate();
  


  function link_to_models(){
    navigate("/como-funciona");
  
     setTimeout(() => {
      let divPrincipal = document.querySelector(".marcas-container-config");
      let divMostrar1 = document.querySelector("#motorola");
  
      divPrincipal.style.display = "none";
      divMostrar1.style.display = "block";
  },50)
  
  }

    function animation(){
        let samsungModels = document.querySelector('#samsung-models'); //CONFIGURAÇÃO INICIAL
        let btnModels = document.querySelector('#btnmodels'); // Botão config inicial
        let samsungApp = document.querySelector("#samsungapp") // APP físico
        
        
        let textPrimary = document.querySelector('#textprimary2')
        
        let samsungRecharge = document.querySelector('#samsungteste')
        let btnRecharge = document.querySelector('#rechargeteste')
        
        // retornar
        let btnReturn = document.querySelector('#btnreturn') 
        let containerSettings = document.querySelector('#containersettings') // TELA DO CHIP FÍSICO
        let btnFisico = document.querySelector('#divfisico')
        containerSettings.style.display = "flex";//aqui eu estou falando que eu quero exibir para o cliente primeiro a opçõ do físico, Se eu não quero mostrar nada, eu apenas devo remover o display:flex

        // CHIP VIRTUAL
        
        let containerVirtual = document.querySelector('#containersettingsvirtual')
        let btnVirtual = document.querySelector('#btnvirtual')
        
        
        // Chip virtual instalação eSIM
        let btnInstall = document.querySelector('#install-esim')
        let installModels = document.querySelector('#virtual-install')
        
        // Chip virtual Configuração inicial eSIM
        let btnconfigEsim = document.querySelector('#settingsinicial')
        let configesimModels = document.querySelector('#virtual-config')
        
        // Chip virtual app
        let btnAppesim = document.querySelector('#esim-app')
        let appesimModels = document.querySelector('#virtual-app')
        
        // Chip virtual recharge
        let btnRechargeesim = document.querySelector('#rechargeesim')
        let rechargeesimModels = document.querySelector('#virtual-recharge')
        
        
        
        
        
        // Função de esconder e aparecer o CHIP VIRTUAL OU CHIP FÍSICO
        btnFisico.addEventListener("click", function(){
        
            textPrimary.style.display = 'none'
        
            containerSettings.style.display = 'flex'
            containerVirtual.style.display = 'none'
            
            window.scrollTo({
                top: 600,
                behavior: 'smooth'
            });
        
        
            document.getElementById("divfisico").style.borderColor = "#ff5000";
            document.getElementById("chipfisico").style.color = "#ff5000";
            document.getElementById("btnvirtual").style.borderColor = "#c2c2c2";
            document.getElementById("chipvirtual").style.color = "#c2c2c2";
        });
        
        btnVirtual.addEventListener("click", function(){
            textPrimary.style.display = 'none'
            containerSettings.style.display = 'none';
            containerVirtual.style.display = 'flex'
        
            window.scrollTo({
                top: 600,
                behavior: 'smooth'
            });
        
            document.getElementById("btnvirtual").style.borderColor = "#ff5000";
            document.getElementById("chipvirtual").style.color = "#ff5000";
            document.getElementById("divfisico").style.borderColor = "#c2c2c2";
            document.getElementById("chipfisico").style.color = "#c2c2c2";
        
        });
        
        
        // ------------------------------------------------------------------------
        
       
        
    
        // CHIP FÍSICO FUNÇÕES
        
        btnModels.addEventListener("click", function(){
            samsungModels.style.display = "none"
            samsungApp.style.display = "block"
            samsungRecharge.style.display = "none"
           
            document.getElementById("h1text1").style.color = "#2d2d2d"
            document.getElementById("h1text2").style.color = "#ff5000"
            document.getElementById("h1text3").style.color = "#2d2d2d"
            
        });
        
        
        btnRecharge.addEventListener("click", function(){
            samsungModels.style.display = "none"  
            samsungApp.style.display = "none"
            samsungRecharge.style.display = "block"
           
            document.getElementById("h1text1").style.color = "#2d2d2d";
            document.getElementById("h1text2").style.color = "#2d2d2d";
            document.getElementById("h1text3").style.color = "#ff5000";
            
        });
        
        btnReturn.addEventListener("click", function(){
            samsungModels.style.display = "block"  
            samsungApp.style.display = "none"
            samsungRecharge.style.display = "none"
            
            document.getElementById("h1text1").style.color = "#ff5000";
            document.getElementById("h1text2").style.color = "#2d2d2d";
            document.getElementById("h1text3").style.color = "#2d2d2d";
            
        });
        
        // VIRTUAL FUNÇÕES
        
        
       
        
        btnInstall.addEventListener("click", function(){
            installModels.style.display = "block"
           
            configesimModels.style.display = "none"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "none"
            
            document.getElementById("h1-install").style.color = "#ff5000"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        
        });
        
        btnconfigEsim.addEventListener("click", function(){
            installModels.style.display = "none"
            
            configesimModels.style.display = "block"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "none"
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#ff5000"
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        });
        
        btnAppesim.addEventListener("click", function(){
            installModels.style.display = "none"
           
            configesimModels.style.display = "none"
            appesimModels.style.display = "block"
            rechargeesimModels.style.display = "none"
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-app-esim").style.color = "#ff5000"
           
            document.getElementById("h2-recharge-esim").style.color = "#2d2d2d"
        
        });
        
        btnRechargeesim.addEventListener("click", function(){
            installModels.style.display = "none"
            
            configesimModels.style.display = "none"
            appesimModels.style.display = "none"
            rechargeesimModels.style.display = "block"
            document.getElementById("h1-install").style.color = "#2d2d2d"
            document.getElementById("h2-esiminstall").style.color = "#2d2d2d";
            document.getElementById("h2-app-esim").style.color = "#2d2d2d"
            
            document.getElementById("h2-recharge-esim").style.color = "#ff5000"
        
        });
                
     } 

     function accordionIphone11(id){

      const accordionItemHeaders = document.getElementById(`${id}`);console.log(accordionItemHeaders)

          
          accordionItemHeaders.classList.toggle("active");
          const accordionItemBody = accordionItemHeaders.nextElementSibling;
          if(accordionItemHeaders.classList.contains("active")) {
            accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
          }
          else {
            accordionItemBody.style.maxHeight = 0;
          }

  }


  function physycal_accordion_iphone11(){
    let accordionFisicosiphone11 = document.querySelector('#section_mobile_iphone11_accordion_fisico');
    let accordionVirtuaisiphone11 = document.querySelector('#section_mobile_iphone11_accordion_virtual');
  
  
    accordionFisicosiphone11.style.display = 'block';
    accordionVirtuaisiphone11.style.display = 'none';
    
    
    document.getElementById("option_mobile_virtual_iphone11").style.borderColor = "#c2c2c2";
    document.getElementById("option_mobile_virtual_iphone11_h1").style.color = "#c2c2c2";
    
    document.getElementById("option_mobile_fisico_iphone11").style.borderColor = "#ff5000";
    document.getElementById("option_mobile_fisico_iphone11_h1").style.color = "#ff5000";
  }
  
  
  
  
  
  
  function virtual_accordion_iphone11(){
    let accordionFisicosiphone11 = document.querySelector('#section_mobile_iphone11_accordion_fisico');
    let accordionVirtuaisiphone11 = document.querySelector('#section_mobile_iphone11_accordion_virtual');
  
    accordionFisicosiphone11.style.display = 'none';
    accordionVirtuaisiphone11.style.display = 'block';
  
  
    document.getElementById("option_mobile_fisico_iphone11").style.borderColor = "#c2c2c2";
    document.getElementById("option_mobile_fisico_iphone11_h1").style.color = "#c2c2c2";
  
    document.getElementById("option_mobile_virtual_iphone11").style.borderColor = "#ff5000";
    document.getElementById("option_mobile_virtual_iphone11_h1").style.color = "#ff5000";
  
  }
   
     setTimeout(() => {
       animation()
    },10)
   
    return(
        <div>
            <Header backgroundcolor="#2d2d2f"/>
             <div class="content-config">

             <div className="text_mobile_iphone7_top">
                <h1>Motorola</h1>
                <h2>Tutorial para configuração Motorola Razr</h2>
                <p>Suporte e dicas para configurar seu celular antes da viagem</p>
            </div>
                    <div class="config-div">
                    <div class="config-text">
                    <h1>Motorola</h1>
                    <h2>Tuturial para configuração Motorola Razr</h2>
                    <p>Suporte e dicas para configurar seu celular antes da sua viagem</p>
                    </div>

                    <div class="config-samsung2">
                    <img src="/assets/img/motorola/mockup-razr-novo.svg" alt="Mockup config Iphone"/>
                    </div>
                </div>
           </div>

           <div class="container-config">
                <div class="links-config"><Link to="/como-funciona">Configuração</Link><span> {">"} </span>   <div onClick={link_to_models}>Modelos </div>   <span> {">"} </span><Link to="" class="spanlink">Motorola Razr</Link></div>

             <div class="config-content">
                <h1 class="textprimary">Configure seu Motorola Razr para utilizar o <span class="span-orange">mysimtravel</span></h1>
                <h3 class="textprimary1" id="textprimary2">Qual o modelo do seu CHIP ?</h3>
                <div class="flex-grid">
                    
                    <div class="fisico" id="divfisico">
                    <h1 id="chipfisico">Chip Físico</h1>
                    </div>

                    <div class="virtual" id="btnvirtual">
                    <h1 id="chipvirtual">Chip Virtual</h1>
                    </div>
                </div>
              </div>




              <div class="settings js-scroll" id="containersettings">
            <div class="settings-text">
                <div class="inicial ativo" id="btnreturn">
                <h1 class="text-h1" id="h1text1">Configuração inicial</h1>
                </div>

                <div class="app" id="btnmodels">
                <h1 id="h1text2">Ativação pelo app mysimtravel FOR YOU</h1>
                </div>

                <div class="recarga" id="rechargeteste">
                <h1 id="h1text3">Recarga</h1>
                </div>
            </div>

            <div class="settings-models" id="samsung-models">
                <h1 class="roaming">Ativação Roaming</h1>
                <div class="settings-instru">
                <div class="config-1">
                <h1>1 - Vá em <span>Configurações</span> e depois <span>Rede e internet</span> em seguida <span>Cartões SIM</span> e deixe o mysimtravel <span>sempre no SIM1</span></h1>
                <div class="imgconfig-1">
                    <img src="/assets/img/motorola/config-fisico/config1.svg" alt="Print config 1"/>
                    <img src="/assets/img/motorola/config-fisico/config2.svg" alt="Print config 1"/>
                    <img src="/assets/img/motorola/config-fisico/config3.svg" alt="Print config 1"/>
                    </div>
                </div>
                <div class="config-2">
                    <h1>2 - Depois volte em <span>Rede e internet</span> e selecione <span>Rede Móvel</span> e ative o <span>Roaming</span></h1>
                    <div class="imgconfig-2">
                    <img src="/assets/img/motorola/config-fisico/config4.svg" alt="Print config 2"/>
                    <img src="/assets/img/motorola/config-fisico/config5.svg" alt="Print config 3"/>
                    </div>
                    </div>

                    <div class="config-3">
                    <h1>3 - Na mesma tela vá em <span>"Nomes dos pontos de acesso"</span> e verifique se o <span>APN Elisa internet</span> está selecionado</h1>
                    <div class="imgconfig-3">
                        <img src="/assets/img/motorola/config-fisico/config6.svg" alt="Print config 5"/>
                        <img src="/assets/img/motorola/config-fisico/config7.svg" alt="Print config 6"/>
                        </div>
                    </div>

                    
                </div>
            </div>


            <div class="settings-app" id="samsungapp">
                <h1 class="configapp">Configuração Aplicativo</h1>
                <div class="settings-appins">
                <div class="app-1">
                <h1>1 - Faça o login com <span>email (ou  nº do chip) e a senha cadastrada no ato da compra</span></h1>
                <div class="imgapp-1">
                    <img src="/assets/img/motorola/config-fisico/app1.svg" alt="Print app 1"/>
                    </div>
                </div>
                <div class="app-2">
                    <h1>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado) E para ativar o plano selecione <span>Tarifas</span></h1>
                    <div class="imgapp-2">
                    <img src="/assets/img/motorola/config-fisico/app2.svg" alt="Print app 2"/>
                    </div>
                    </div>

                    <div class="app-3">
                    <h1>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span>Barra de pesquisa</span> ou <span>procurar na lista de países</span></h1>
                    <div class="imgapp-3">
                        <img src="/assets/img/motorola/config-fisico/app3.svg" alt="Print app 3"/>
                        
                        </div>
                    </div>

                    <div class="app-3">
                        <h1>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na <span>etapa 2 ou 3)</span></h1>
                        <div class="imgapp-3">
                        <img src="/assets/img/motorola/config-fisico/app4.svg" alt="Print app 3"/>
                        <img src="/assets/img/motorola/config-fisico/app5.svg" alt="Print app 4"/>
                        </div>
                        </div>

                        <div class="app-3">
                        <h1>5 - <span>Atenção: esse procedimento deve ser feito somente no dia da viagem</span><br/>Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative seu plano</h1>
                        <div class="imgapp-3">
                            <img src="/assets/img/motorola/config-fisico/app6.svg" alt="Print app 6"/>
                            <img src="/assets/img/motorola/config-fisico/app7.svg" alt="Print app 7"/>
                            </div>
                        </div>
                </div>
            </div>

            <div class="recarga-setting" id="samsungteste">
                <h1 class="configrecargas">Recarga</h1>
                <div class="settings-recarga1">
                <div class="recarga-1">
                <h1>1 - Na tela inicial, vá em <span>Comprar créditos</span> no menu inferior</h1>
                <div class="imgrecarga-1">
                    <img src="/assets/img/motorola/config-fisico/recarga1.svg" alt="Print app 1"/>
                    </div>
                </div>
                <div class="recarga-2">
                    <h1>2 - Selecione a opção <span>comprar créditos</span></h1>
                    <div class="imgapp-2">
                    <img src="/assets/img/motorola/config-fisico/recarga2.svg" alt="Print app 2"/>
                    </div>
                    </div>

                    <div class="recarga-3">
                    <h1>3 - Insira o valor de acordo com o <span>plano que deseja recarregar</span> e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no máximo 5 minutos</h1>
                    <div class="imgapp-3">
                        <img src="/assets/img/motorola/config-fisico/recarga3.svg" alt="Print app 3"/>
                        </div>

                        
                    </div>  
                </div>
            </div>
            </div>
    


    <div class="settings-virtual" id="containersettingsvirtual">
      <div class="settings-text-virtual">
        <div class="config-instalacao" id="install-esim">
          <h1 class="h1-instalacao" id="h1-install">Instalação eSIM</h1>
        </div>

        <div class="config-inicial" id="settingsinicial">
          <h1 class="h1-esimconfig" id="h2-esiminstall">Configuração inicial</h1>
        </div>

        <div class="ativarapp" id="esim-app">
          <h1 class="h1-ativarapp" id="h2-app-esim">Ativação pelo app mysimtravel FOR YOU</h1>
        </div>

        <div class="recargaesim" id="rechargeesim">
          <h1 class="h1-rechargeapp" id="h2-recharge-esim">Recarga</h1>
        </div>
      </div>

      <div class="settings-virtual-install" id="virtual-install">
        <h1 class="install-qrcode">Instalação eSIM</h1>
        <div class="settings-virtual-instrucoes">
          <div class="install-1">
          <h1>1 - Vá em <span>Configurações</span> e depois <span>Rede e internet,</span> em seguida <span>Cartões SIM</span> e deixe o mysimtravel <span>sempre no SIM 1</span></h1>
          <div class="imginstall-1">
            <img src="/assets/img/motorola/config-fisico/e-sim/config1.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config2.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config3.svg" alt="Print install 1"/>
            </div>
          </div>
          <div class="install-2">
            <h1>2 - Depois volte em <span> Rede e internet </span> e selecione <span>Rede Móvel</span> e ative o <span>Roaming</span></h1>
            <div class="imginstall-2">
            <img src="/assets/img/motorola/config-fisico/e-sim/config4.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config5.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config6.svg" alt="Print install 1"/>
              
              </div>
            </div>

            <div class="install-3">
              <h1>3 - Na mesma tela vá em <span>"Nomes dos pontos de acesso"</span> e verifique se o <span>APN Elisa internet</span> está selecionado</h1>
              <div class="imginstall-3">
              <img src="/assets/img/motorola/config-fisico/e-sim/config7.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config8.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config9.svg" alt="Print install 1"/>
                </div>
              </div>
        </div>
      </div>

      <div class="settings-virtual-config" id="virtual-config">
        <h1 class="config-qrcode">Configuração Inicial</h1>
        <div class="settings-virtual-instrucoes-config">
          <div class="configinstall-1">
          <h1>1 - Vá em <span>Configurações</span> e depois <span>Rede e internet</span> em seguida <span>Cartões SIM</span> e deixe somente o eSIM mysimtravel ativo</h1>
          <div class="imgconfiginstall-1">
          <img src="/assets/img/motorola/config-fisico/config1.svg" alt="Print config 1"/>
          <img src="/assets/img/motorola/config-fisico/config2.svg" alt="Print config 1"/>
          {/* <img src="/assets/img/motorola/config-fisico/config3.svg" alt="Print config 1"/> */}
            </div>
          </div>
          <div class="configinstall-2">
            <h1>2 - Depois volte em <span>Rede e internet</span> e selecione <span>Rede Móvel</span> e ative o <span>Roaming</span></h1>
            <div class="imgconfiginstall-2">
            <img src="/assets/img/motorola/config-fisico/config4.svg" alt="Print config 2"/>
            <img src="/assets/img/motorola/config-fisico/config5.svg" alt="Print config 3"/>
            </div>
            </div>

            <div class="configinstall-3">
              <h1>3 - Na mesma tela vá em <span>"Nomes dos pontos de acesso"</span> e verifique se o <span>APN Elisa internet</span> está selecionado</h1>
              <div class="imgconfiginstall-3">
              <img src="/assets/img/motorola/config-fisico/config6.svg" alt="Print config 5"/>
              <img src="/assets/img/motorola/config-fisico/config7.svg" alt="Print config 6"/>
              </div>

               
              </div>
        </div>
      </div>

      <div class="settings-virtual-app" id="virtual-app">
        <h1 class="app-qrcode">Ativação Roaming</h1>
        <div class="settings-appins">
          <div class="app-1">
          <h1>1 - Faça o login com <span>email (ou  nº do chip) e a senha cadastrada no ato da compra</span></h1>
          <div class="imgapp-1">
            <img src="/assets/img/motorola/config-fisico/app1.svg" alt="Print app 1" />
            </div>
          </div>
          <div class="app-2">
            <h1>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado) E para ativar o plano selecione <span>Tarifas</span></h1>
            <div class="imgapp-2">
              <img src="/assets/img/motorola/config-fisico/app2.svg" alt="Print app 2" />
              </div>
            </div>

            <div class="app-3">
              <h1>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span>Barra de pesquisa</span> ou <span>procurar na lista de países</span></h1>
              <div class="imgapp-3">
                <img src="/assets/img/motorola/config-fisico/app3.svg" alt="Print app 3" />
                
                </div>
              </div>

              <div class="app-3">
                <h1>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na <span>etapa 2 ou 3)</span></h1>
                <div class="imgapp-3">
                  <img src="/assets/img/motorola/config-fisico/app4.svg" alt="Print app 3"/>
                  <img src="/assets/img/motorola/config-fisico/app5.svg" alt="Print app 4"/>
                  </div>
                </div>

                <div class="app-3">
                  <h1>5 - <span>Atenção: esse procedimento deve ser feito somente no dia da viagem</span><br/>Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative seu plano</h1>
                  <div class="imgapp-3">
                    <img src="/assets/img/motorola/config-fisico/app6.svg" alt="Print app 6"/>
                    <img src="/assets/img/motorola/config-fisico/app7.svg" alt="Print app 7"/>
                    </div>
                  </div>
        </div>
      </div>

      <div class="settings-virtual-recharge" id="virtual-recharge">
        <h1 class="recharge-qrcode">Ativação Roaming</h1>
        <div class="settings-virtual-instrucoes-recharge">
          <div class="recarga-1">
            <h1>1 - Na tela inicial, vá em <span>Comprar créditos</span> no menu inferior</h1>
            <div class="imgrecarga-1">
              <img src="/assets/img/motorola/config-fisico/recarga1.svg" alt="Print app 1"/>
              </div>
            </div>
            <div class="recarga-2">
              <h1>2 - Selecione o nº do chip que gostaria de recarregar</h1>
              <div class="imgapp-2">
                <img src="/assets/img/motorola/config-fisico/recarga2.svg" alt="Print app 2"/>
                </div>
              </div>

              <div class="recarga-3">
                <h1>3 - Insira o valor de acordo com o <span>plano que deseja recarregar</span> e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no máximo 5 minutos</h1>
                <div class="imgapp-3">
                  <img src="/assets/img/motorola/config-fisico/recarga3.svg" alt="Print app 3"/>
                  </div>
  
                  <h1>Pronto, agora basta renovar o seu plano novamente, qualquer dúvida te ajudamos por <a href="#">aqui</a></h1>
                </div>  

        </div>
      </div>

      

      
    </div>

  </div> 




  <div className="view_mobile">


    
            <h1 className="mobile_text_paragrafo">Configure seu Motorola Razr para utilizar o <span>mysimtravel</span> </h1>

          <div className="mobile_iphone11_select_chip">
            <div className="mobile_fisico_iphone11" id="option_mobile_fisico_iphone11" onClick={physycal_accordion_iphone11}>
              <h1 id="option_mobile_fisico_iphone11_h1">Chip Físico</h1>
            </div>

            <div className="mobile_virtual_iphone11" id="option_mobile_virtual_iphone11" onClick={virtual_accordion_iphone11}>
              <h1 id="option_mobile_virtual_iphone11_h1">Chip Virtual</h1>
            </div>
          </div>

        <div id="section_mobile_iphone11_accordion_fisico">
          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="configinicialiphone11" onClick={ () => {accordionIphone11("configinicialiphone11")}}>
                <h1>Configuração inicial</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação em Roaming</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Vá em <span className="mobile_iphone7_span">Configurações</span> e depois <span className="mobile_iphone7_span">Rede e internet</span> em seguida <span className="mobile_iphone7_span">Cartões SIM</span> e deixe o mysimtravel <span className="mobile_iphone7_span">sempre no SIM1</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/config1.svg" alt="Print config 1"/>
                        <img src="/assets/img/motorola/config-fisico/config2.svg" alt="Print config 1"/>
                        <img src="/assets/img/motorola/config-fisico/config3.svg" alt="Print config 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Depois volte em <span className="mobile_iphone7_span">Rede e internet</span> e selecione <span className="mobile_iphone7_span">Rede Móvel</span> e ative o <span className="mobile_iphone7_span">Roaming</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/config4.svg" alt="Print config 2"/>
                        <img src="/assets/img/motorola/config-fisico/config5.svg" alt="Print config 3"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Na mesma tela vá em <span className="mobile_iphone7_span">"Nomes dos pontos de acesso"</span> e verifique se o <span className="mobile_iphone7_span">APN Elisa internet</span> está selecionado</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/config6.svg" alt="Print config 5"/>
                        <img src="/assets/img/motorola/config-fisico/config7.svg" alt="Print config 6"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="ativacaopeloapp7" onClick={ () => {accordionIphone11("ativacaopeloapp7")}}>
                <h1>Ativação pelo app mysimtravel FORYOU</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação pelo aplicativo</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Faça o login com o email (ou n° do chip) e a senha cadastrada no ato da compra</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado)
E para ativar o plano selecione Tarifas</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span className="mobile_iphone7_span">Barra de pesquisa</span> ou <span className="mobile_iphone7_span">procurar na lista de países</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app3.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na etapa 2 ou 3)</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app4.svg" alt="Print app 1"/>

                        <img src="/assets/img/motorola/config-fisico/app5.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Atenção: esse procedimento deve ser feito somente no dia da viagem
Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative o seu plano</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app6.svg" alt="Print app 1"/>
                        <img src="/assets/img/motorola/config-fisico/app7.svg" alt="Print app 7"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="recargaapp7" onClick={ () => {accordionIphone11("recargaapp7")}}>
                <h1>Recarga</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Recarga</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Na tela inicial, vá em <span className="mobile_iphone7_span">Comprar Créditos</span> no menu anterior</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/recarga2.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione a opção <span>comprar créditos</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/recarga2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o valor de acordo com o plano que deseja recarregar e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no maximo 5 minutos</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/recarga3.svg" alt="Print app 1"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





        <div id="section_mobile_iphone11_accordion_virtual">
          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="configinstallesim" onClick={ () => {accordionIphone11("configinstallesim")}}>
                <h1>Instalação eSIM</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação em Roaming</h1>

                     <div className="div_mobile_iphone7">
                     <p>1 - Vá em <span className="mobile_iphone7_span">Configurações</span> e depois <span className="mobile_iphone7_span">Rede e internet,</span> em seguida <span className="mobile_iphone7_span">Cartões SIM</span> e deixe o mysimtravel <span className="mobile_iphone7_span">sempre no SIM 1</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/e-sim/config1.svg" alt="Print install 1"/>
                        <img src="/assets/img/motorola/config-fisico/e-sim/config2.svg" alt="Print install 1"/>
                        <img src="/assets/img/motorola/config-fisico/e-sim/config3.svg" alt="Print install 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Depois volte em <span className="mobile_iphone7_span"> Rede e internet </span> e selecione <span className="mobile_iphone7_span">Rede Móvel</span> e ative o <span className="mobile_iphone7_span">Roaming</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/e-sim/config4.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config5.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config6.svg" alt="Print install 1"/>
                        
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Na mesma tela vá em <span className="mobile_iphone7_span">"Nomes dos pontos de acesso"</span> e verifique se o <span className="mobile_iphone7_span">APN Elisa internet</span> está selecionado</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/e-sim/config7.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config8.svg" alt="Print install 1"/>
            <img src="/assets/img/motorola/config-fisico/e-sim/config9.svg" alt="Print install 1"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="dicasdedados7esim" onClick={ () => {accordionIphone11("dicasdedados7esim")}}>
                <h1>Configuração Inicial</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Configuração Inicial</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Vá em <span className="mobile_iphone7_span">Configurações</span> e depois <span className="mobile_iphone7_span">Rede e internet</span> em seguida <span className="mobile_iphone7_span">Cartões SIM</span> e deixe somento o eSIM mysimtravel ativo</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/config1.svg" alt="Print config 1"/>
          <img src="/assets/img/motorola/config-fisico/config2.svg" alt="Print config 1"/>
          {/* <img src="/assets/img/motorola/config-fisico/config3.svg" alt="Print config 1"/> */}
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Depois volte em <span className="mobile_iphone7_span">Rede e internet</span> e selecione <span className="mobile_iphone7_span">Rede Móvel</span> e ative o <span className="mobile_iphone7_span">Roaming</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/config4.svg" alt="Print config 2"/>
            <img src="/assets/img/motorola/config-fisico/config5.svg" alt="Print config 3"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Na mesma tela vá em <span className="mobile_iphone7_span">"Nomes dos pontos de acesso"</span> e verifique se o <span className="mobile_iphone7_span">APN Elisa internet</span> está selecionado</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/config6.svg" alt="Print config 5"/>
              <img src="/assets/img/motorola/config-fisico/config7.svg" alt="Print config 6"/>
                        </div>
                    </div>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="ativacaopeloapp7esim" onClick={ () => {accordionIphone11("ativacaopeloapp7esim")}}>
                <h1>Ativação pelo app mysimtravel FORYOU</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Ativação pelo aplicativo</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Faça o login com o email (ou n° do chip) e a senha cadastrada no ato da compra</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Nessa primeira tela você confere o seu saldo e o seu consumo (quando o plano estiver ativado)
E para ativar o plano selecione Tarifas</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o seu país de destino (caso vá para mais de um país, insira o primeiro destino e selecione o plano que foi adquirido na compra) Você pode pesquisar na <span className="mobile_iphone7_span">Barra de pesquisa</span> ou <span className="mobile_iphone7_span">procurar na lista de países</span></p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app3.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>4 - Selecione o seu plano que foi adquirido (caso não se lembre, olhe o email na etapa 2 ou 3)</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app4.svg" alt="Print app 3"/>
                  <img src="/assets/img/motorola/config-fisico/app5.svg" alt="Print app 4"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>5 - Atenção: esse procedimento deve ser feito somente no dia da viagem
Após selecionar o plano, arraste o botão ao lado, confirme os países de cobertura e ative o seu plano</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/app6.svg" alt="Print app 6"/>
                    <img src="/assets/img/motorola/config-fisico/app7.svg" alt="Print app 7"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordion-iphone7">
            <div className="accordion-item-iphone7">
              <div className="accordion-item-header-iphone7" id="recargaapp7esim" onClick={ () => {accordionIphone11("recargaapp7esim")}}>
                <h1>Recarga</h1>
              </div>
              <div className="accordion-item-body-iphone7">
                <div className="accordion-item-body-content-iphone7">
                  <div className="accordion-item1">
                    
                    <div className="mobile_config_iphone7">
                        <h1>Recarga</h1>

                     <div className="div_mobile_iphone7">
                        <p>1 - Na tela inicial, vá em <span className="mobile_iphone7_span">Comprar Créditos</span> no menu anterior</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/recarga1.svg" alt="Print app 1"/>
                        </div>
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>2 - Selecione o nº do chip que gostaria de recarregar</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/recarga2.svg" alt="Print app 1"/>
                        </div>    
                    </div>

                    <div className="div_mobile_iphone7">
                        <p>3 - Insira o valor de acordo com o plano que deseja recarregar e os dados do cartão de crédito, após isso basta confirmar e seu crédito vai estar disponivel em no maximo 5 minutos</p>

                        <div className="img_mobile_iphone7">
                        <img src="/assets/img/motorola/config-fisico/recarga3.svg" alt="Print app 1"/>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


          </div>
           <Footer/>
        </div>
    )
}