import './index.css'
import './responsive-listas.css'
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import React from 'react';





 function lista_global_operadoras(){

    setTimeout(() =>{
        document.querySelector('.input_listas_globais').addEventListener('input', function(){

            let input = this.value.toLowerCase();
            let result = document.querySelectorAll('#result tr');
            
            result.forEach(function(row){
            if(row.textContent.toLowerCase().indexOf(input) > -1){
            row.style.display = 'table-row';
            
            
            }else{
            row.style.display = 'none';
            
            }
            });

        
          });
         
        
        
        
         document.querySelector('.input_listas_globais_esim').addEventListener('input', function(){
        
        let input = this.value.toLowerCase();
        let result = document.querySelectorAll('#result-esim tr');
            
        result.forEach(function(row){
        if(row.textContent.toLowerCase().indexOf(input) > -1){
        row.style.display = 'table-row';
        row.classList.add("row-styled");
        }else{
        row.style.display = 'none';
        row.classList.remove("row-styled");
        }
        });
        
        }); 
        
    },100)

 
  return(

    <div>
      <Header />
        <div class="content_header_listas_operadoras">
        <div class="text_listas_operadoras_header">
            <h1 class="h1_header_listas">
                Lista global de operadoras
            </h1>

            <p class="p_listas_operadoras_header">
                o mysimtravel possui cobertura no mundo todo.
            </p>

            <p class="p_listas_operadoras_header">
                São mais de 300 operadoras e cobertura em +180 países.
            </p>
        </div>
    </div>

    <div class="section_main_listas_globais">

        <div class="text_section_main_listas_globais">
            <h1>Lista global de operadoras mysimtravel</h1>
            <p><img src="/assets/img/listas-globais/chip-listas.svg" alt="Chip mockup p listas"/> Cobertura <span>chip físico</span> mysimtravel</p>
        </div>


        <div class="content_table_listas_globais">

            <div class="search_table_listas_globais">
                <label for="search-pais-listas" class="label_listas">
                    Digite o país de destino:
                    <input type="text" class="input_listas_globais" placeholder="Digite um país"/>
                </label>
            </div>

            <div class="table_listas_globais">
                <table id="result">
                    <tr>
                    <th>#</th>
                    <th>País</th>
                    <th>Operadora</th>
                    <th>MCC</th>
                    <th>MNC</th>
                    <th>Tipo de rede</th>
                    <th>2G</th>
                    <th>3G</th>
                    <th>LTE</th>
                    <th>Data</th>
                    <th>Direct call</th>


                    </tr>
                    <tr>
    <td>1</td>
    <td>Afghanistan</td>
    <td>Telecom Development Company Afghanistan Ltd.</td>
    <td>412</td>
    <td>20</td>
    <td>GSM 900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>2</td>
    <td>Albania</td>
    <td>Telekom Albania</td>
    <td>276</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>3</td>
    <td>Albania</td>
    <td>ONE TELECOMMUNICATIONS</td>
    <td>276</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>4</td>
    <td>Algeria</td>
    <td>Optimum Telecom Algérie Spa</td>
    <td>603</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>5</td>
    <td>Algeria</td>
    <td>ATM MOBILIS</td>
    <td>603</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>6</td>
    <td>Andorra</td>
    <td>Servei De Tele. DAndorra</td>
    <td>213</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>7</td>
    <td>Andorra</td>
    <td>ANDORRA TELECOM SAU</td>
    <td>213</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>8</td>
    <td>Angola</td>
    <td>UNITEL S.a.r.l.</td>
    <td>631</td>
    <td>2</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>9</td>
    <td>Angola</td>
    <td>MOVICEL Telecommunications S.A.</td>
    <td>631</td>
    <td>4</td>
    <td>GSM 900, GSM 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>10</td>
    <td>Angola</td>
    <td>UNITEL</td>
    <td>631</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UTMS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>11</td>
    <td>Anguilla</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>12</td>
    <td>Anguilla</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>13</td>
    <td>Anguilla</td>
    <td>CABLE & WIRELESS, ANGUILLA</td>
    <td>365</td>
    <td>8403</td>
    <td>GSM 1800, GSM 1900, GSM 850, UMTS, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>14</td>
    <td>Anguilla</td>
    <td>DIGICEL ANGUILLA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 850, UMTS 850, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>15</td>
    <td>Antigua and Barbuda</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>16</td>
    <td>Antigua and Barbuda</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>17</td>
    <td>Antigua and Barbuda</td>
    <td>CABLE & WIRELESS, ANTIGUA</td>
    <td>344</td>
    <td>9203</td>
    <td>GSM 1800, GSM 1900, GSM 850, UMTS, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>18</td>
    <td>Antigua and Barbuda</td>
    <td>DIGICEL ANTIGUA & BARBUDA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1900, GSM 900, UMTS 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>19</td>
    <td>Argentina</td>
    <td>AMX Argentina S.A. - CLARO</td>
    <td>722</td>
    <td>31</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>20</td>
    <td>Argentina</td>
    <td>AMX Argentina S.A.</td>
    <td>722</td>
    <td>310</td>
    <td>GSM 1900, GSM 850, UMTS 850, UMTS 1900, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>21</td>
    <td>Argentina</td>
    <td>CLARO ARGENTINA</td>
    <td>722</td>
    <td>3103</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>22</td>
    <td>Armenia</td>
    <td>Ucom LLC</td>
    <td>283</td>
    <td>10</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>23</td>
    <td>Armenia</td>
    <td>TELECOM ARMENIA</td>
    <td>283</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 450</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>24</td>
    <td>Aruba</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>25</td>
    <td>Aruba</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>26</td>
    <td>Aruba</td>
    <td>Digicel Aruba</td>
    <td>363</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>27</td>
    <td>Aruba</td>
    <td>DIGICEL ARUBA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>28</td>
    <td>Aruba</td>
    <td>DIGICEL ARUBA</td>
    <td>363</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>29</td>
    <td>Australia</td>
    <td>Telstra Corporation Limited</td>
    <td>505</td>
    <td>1</td>
    <td>UMTS 2100, LTE 1800, UMTS 850, LTE 700, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>30</td>
    <td>Australia</td>
    <td>Singtel Optus Proprietary Limited</td>
    <td>505</td>
    <td>2</td>
    <td>UMTS 2100, LTE 1800, LTE 700, UMTS 900, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>31</td>
    <td>Australia</td>
    <td>VODAFONE AUSTRALIA</td>
    <td>505</td>
    <td>33</td>
    <td>UMTS 2100, UMTS 850, UMTS 900, LTE 1800, LTE 2100, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>32</td>
    <td>Austria</td>
    <td>A1 Telekom Austria</td>
    <td>232</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>33</td>
    <td>Austria</td>
    <td>HUTCHISON DREI AUSTRIA</td>
    <td>232</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2100, LTE 2600, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>34</td>
    <td>Austria</td>
    <td>T-MOBILE AUSTRIA</td>
    <td>232</td>
    <td>33</td>
    <td>GSM 900, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>35</td>
    <td>Austria</td>
    <td>A1 TELEKOM AUSTRIA AG</td>
    <td>232</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>36</td>
    <td>Azerbaijan</td>
    <td>Azercell</td>
    <td>400</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>37</td>
    <td>Azerbaijan</td>
    <td>AZERCELL TELECOM B.M.</td>
    <td>400</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>38</td>
    <td>Bahamas</td>
    <td>The Bahamas Telecommunications Company Ltd (BaTelCo)</td>
    <td>364</td>
    <td>39</td>
    <td>GSM 850, GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>39</td>
    <td>Bahamas</td>
    <td>BTC BAHAMAS</td>
    <td>364</td>
    <td>393</td>
    <td>GSM 1900, UMTS 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>40</td>
    <td>Bahrain</td>
    <td>Viva Bahrain</td>
    <td>426</td>
    <td>4</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>41</td>
    <td>Bahrain</td>
    <td>BATELCO</td>
    <td>426</td>
    <td>13</td>
    <td>UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>42</td>
    <td>Bangladesh</td>
    <td>Grameenphone Ltd.</td>
    <td>470</td>
    <td>1</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>43</td>
    <td>Bangladesh</td>
    <td>ROBI AXIATA LIMITED</td>
    <td>470</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>44</td>
    <td>Bangladesh</td>
    <td>GRAMEENPHONE</td>
    <td>470</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>45</td>
    <td>Bangladesh</td>
    <td>ROBI AXIATA LIMITED</td>
    <td>470</td>
    <td>73</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>46</td>
    <td>Barbados</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>47</td>
    <td>Barbados</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>48</td>
    <td>Barbados</td>
    <td>CABLE & WIRELESS, BARBADOS</td>
    <td>342</td>
    <td>6003</td>
    <td>GSM 1900, GSM 850, LTE 1900, LTE 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>49</td>
    <td>Barbados</td>
    <td>DIGICEL BARBADOS</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>50</td>
    <td>Belarus</td>
    <td>Mobile TeleSystems</td>
    <td>257</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>51</td>
    <td>Belarus</td>
    <td>velcom</td>
    <td>257</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>52</td>
    <td>Belarus</td>
    <td>LIFE</td>
    <td>257</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>53</td>
    <td>Belarus</td>
    <td>MTS BELARUS</td>
    <td>257</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>54</td>
    <td>Belgium</td>
    <td>Belgacom Mobile</td>
    <td>206</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>55</td>
    <td>Belgium</td>
    <td>Orange S.A.</td>
    <td>206</td>
    <td>10</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>56</td>
    <td>Belgium</td>
    <td>Telenet</td>
    <td>206</td>
    <td>20</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>57</td>
    <td>Belgium</td>
    <td>PROXIMUS PLC</td>
    <td>206</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>58</td>
    <td>Belgium</td>
    <td>TELENET GROUP BVBA/SPRL</td>
    <td>206</td>
    <td>203</td>
    <td>GSM 1800, GSM 900, UMTS 900, UTMS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>59</td>
    <td>Belgium</td>
    <td>ORANGE BELGIUM</td>
    <td>206</td>
    <td>103</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>60</td>
    <td>Bermuda</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>61</td>
    <td>Bermuda</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>62</td>
    <td>Bermuda</td>
    <td>DIGICEL BERMUDA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1900, UMTS 1900, UMTS 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>63</td>
    <td>Bosnia and Herzegovina</td>
    <td>BH Telecom</td>
    <td>218</td>
    <td>90</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>64</td>
    <td>Bosnia and Herzegovina</td>
    <td>M-TEL</td>
    <td>218</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>65</td>
    <td>Brazil</td>
    <td>Claro</td>
    <td>724</td>
    <td>5</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 2600, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>66</td>
    <td>Brazil</td>
    <td>TIM</td>
    <td>724</td>
    <td>2</td>
    <td>GSM 1800, UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>67</td>
    <td>Brazil</td>
    <td>TIM</td>
    <td>724</td>
    <td>3</td>
    <td>GSM 1800, UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>68</td>
    <td>Brazil</td>
    <td>TIM</td>
    <td>724</td>
    <td>4</td>
    <td>GSM 1800, UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>69</td>
    <td>Brazil</td>
    <td>TIM BRAZIL</td>
    <td>724</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 850, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>70</td>
    <td>Brazil</td>
    <td>TIM BRAZIL</td>
    <td>724</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 850, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>71</td>
    <td>Brazil</td>
    <td>TIM BRAZIL</td>
    <td>724</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 850, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>72</td>
    <td>Brazil</td>
    <td>CLARO BRAZIL</td>
    <td>724</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 850, LTE 1800, LTE 2600, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>73</td>
    <td>British Indian Ocean Territory</td>
    <td>DIGICEL BRITISH VIRGIN ISLANDS</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 1900, UMTS 1900, LTE 1700, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>74</td>
    <td>British Indian Ocean Territory</td>
    <td>DIGICEL BRITISH VIRGIN ISLANDS</td>
    <td>348</td>
    <td>773</td>
    <td>GSM 1800, GSM 1900, UMTS 1900, LTE 1700, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>75</td>
    <td>British Indian Ocean Territory</td>
    <td>CABLE & WIRELESS, BRITISH VIRGIN ISLANDS</td>
    <td>348</td>
    <td>1703</td>
    <td>GSM 1900, GSM 850, UMTS 850, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>76</td>
    <td>Brunei Darussalam</td>
    <td>Data Stream Technology</td>
    <td>528</td>
    <td>11</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>77</td>
    <td>Brunei Darussalam</td>
    <td>B-Mobile Communications Sdn Bhd</td>
    <td>528</td>
    <td>2</td>
    <td>UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>78</td>
    <td>Brunei Darussalam</td>
    <td>DST COMMUNICATIONS</td>
    <td>528</td>
    <td>113</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>79</td>
    <td>Bulgaria</td>
    <td>A1 Bulgaria</td>
    <td>284</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>80</td>
    <td>Bulgaria</td>
    <td>BTC</td>
    <td>284</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>81</td>
    <td>Bulgaria</td>
    <td>TELENOR BG</td>
    <td>284</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>82</td>
    <td>Bulgaria</td>
    <td>A1 BULGARIA EAD</td>
    <td>284</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>83</td>
    <td>Bulgaria</td>
    <td>VIVACOM (BTC)</td>
    <td>284</td>
    <td>33</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>84</td>
    <td>Cambodia</td>
    <td>Smart Axiata Co. Ltd</td>
    <td>456</td>
    <td>2</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>85</td>
    <td>Cambodia</td>
    <td>Viettel</td>
    <td>456</td>
    <td>8</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>86</td>
    <td>Cambodia</td>
    <td>Smart Axiata Co. Ltd</td>
    <td>456</td>
    <td>61</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>87</td>
    <td>Cambodia</td>
    <td>Smart Axiata Co. Ltd</td>
    <td>456</td>
    <td>21</td>
    <td>GSM 900, GSM 1800, LTE 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>88</td>
    <td>Cambodia</td>
    <td>SMART AXIATA CO., LTD.</td>
    <td>456</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>89</td>
    <td>Cambodia</td>
    <td>SMART AXIATA CO., LTD.</td>
    <td>456</td>
    <td>63</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>90</td>
    <td>Canada</td>
    <td>TELUS Communications Inc.</td>
    <td>302</td>
    <td>22</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>91</td>
    <td>Canada</td>
    <td>TELUS Communications Inc.</td>
    <td>302</td>
    <td>220</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>92</td>
    <td>Canada</td>
    <td>Rogers Communications Canada Inc.</td>
    <td>302</td>
    <td>720</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE 1700, LTE 2100, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>93</td>
    <td>Canada</td>
    <td>Rogers Communications Canada Inc.</td>
    <td>302</td>
    <td>72</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE 1700, LTE 2100, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>94</td>
    <td>Canada</td>
    <td>ROGERS COMMUNICATIONS CANADA INC.</td>
    <td>302</td>
    <td>7203</td>
    <td>GSM 850, UMTS 850, LTE 1700, LTE 1900, LTE 2600, LTE 700, LTE 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>95</td>
    <td>Canada</td>
    <td>ROGERS COMMUNICATIONS CANADA INC.</td>
    <td>302</td>
    <td>3703</td>
    <td>MVNO</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>96</td>
    <td>Cape Verde</td>
    <td>CVMóvel, S.A.</td>
    <td>625</td>
    <td>1</td>
    <td>GSM 900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>97</td>
    <td>Cape Verde</td>
    <td>CV MÓVEL, SA</td>
    <td>625</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>98</td>
    <td>Cayman Islands</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>UMTS</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>99</td>
    <td>Cayman Islands</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>UMTS</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>100</td>
    <td>Cayman Islands</td>
    <td>CABLE & WIRELESS, CAYMAN ISLANDS</td>
    <td>346</td>
    <td>1403</td>
    <td>GSM 1900, GSM 850, UMTS 850, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>101</td>
    <td>Cayman Islands</td>
    <td>DIGICEL CAYMAN</td>
    <td>338</td>
    <td>503</td>
    <td>UMTS 2100, LTE 1800, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>102</td>
    <td>Chile</td>
    <td>Entel Telefonía Móvil S.A.</td>
    <td>730</td>
    <td>1</td>
    <td>LTE 2600, GSM 1900, LTE 700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>103</td>
    <td>Chile</td>
    <td>Claro Chile S.A.</td>
    <td>730</td>
    <td>3</td>
    <td>GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>104</td>
    <td>Chile</td>
    <td>Claro Chile S.A.</td>
    <td>730</td>
    <td>31</td>
    <td>GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>105</td>
    <td>Chile</td>
    <td>Entel Telefonía Móvil S.A.</td>
    <td>730</td>
    <td>11</td>
    <td>LTE 2600, GSM 1900, LTE 700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>106</td>
    <td>Chile</td>
    <td>Telefónica Móvil de Chile</td>
    <td>730</td>
    <td>2</td>
    <td>LTE 2600, GSM 850, GSM 1900, UMTS 850, LTE 700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>107</td>
    <td>Chile</td>
    <td>CLARO CHILE S.A.</td>
    <td>730</td>
    <td>33</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>108</td>
    <td>China</td>
    <td>China Unicom</td>
    <td>460</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, TD-LTE 2300, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>109</td>
    <td>China</td>
    <td>China Unicom</td>
    <td>460</td>
    <td>11</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, TD-LTE 2300, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>110</td>
    <td>China</td>
    <td>CHINA MOBILE</td>
    <td>460</td>
    <td>3</td>
    <td>GSM 1800, GSM 900, TD-SCDMA 1900, TD-SCDMA 2000</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>111</td>
    <td>China</td>
    <td>CHINA MOBILE</td>
    <td>460</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, TD-SCDMA 1900, TD-SCDMA 2000</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>112</td>
    <td>Colombia</td>
    <td>COMCEL S.A.</td>
    <td>732</td>
    <td>101</td>
    <td>LTE 2600, GSM 850, GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>113</td>
    <td>Colombia</td>
    <td>COMCEL S.A (CLARO)</td>
    <td>732</td>
    <td>1013</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE 1700, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>114</td>
    <td>Colombia</td>
    <td>TELEFONICA MOVILES COLOMBIA</td>
    <td>732</td>
    <td>1233</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>115</td>
    <td>Congo, the Democratic Republic of the</td>
    <td>Africell RDC sprl</td>
    <td>630</td>
    <td>90</td>
    <td>GSM 900, GSM 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>116</td>
    <td>Congo, the Democratic Republic of the</td>
    <td>VODACOM CONGO</td>
    <td>630</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>117</td>
    <td>Costa Rica</td>
    <td>Claro CR Telecomunicaciones (Aló)</td>
    <td>712</td>
    <td>3</td>
    <td>GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>118</td>
    <td>Croatia</td>
    <td>A1 Hrvatska</td>
    <td>219</td>
    <td>10</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>119</td>
    <td>Croatia</td>
    <td>CROATIAN TELECOM INC. (T-HT)</td>
    <td>219</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>120</td>
    <td>Croatia</td>
    <td>TELEMACH CROATIA</td>
    <td>219</td>
    <td>23</td>
    <td>GSM 1800, UMTS 2100, UMTS 900, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>121</td>
    <td>Croatia</td>
    <td>A1 HRVATSKA</td>
    <td>219</td>
    <td>103</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>122</td>
    <td>Cyprus</td>
    <td>Cyprus Telecommunications Authority</td>
    <td>280</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>123</td>
    <td>Cyprus</td>
    <td>MTN Group</td>
    <td>280</td>
    <td>10</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>124</td>
    <td>Cyprus</td>
    <td>CYTA MOBILE-VODAFONE</td>
    <td>280</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>125</td>
    <td>Cyprus</td>
    <td>PRIMETEL PLC</td>
    <td>280</td>
    <td>203</td>
    <td>UMTS 2100, LTE 1800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>126</td>
    <td>Czech Republic</td>
    <td>O2 Czech Republic</td>
    <td>230</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, CDMA 450</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>127</td>
    <td>Czech Republic</td>
    <td>Vodafone Czech Republic</td>
    <td>230</td>
    <td>3</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>128</td>
    <td>Czech Republic</td>
    <td>VODAFONE CZECH REPUBLIC</td>
    <td>230</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, LTE</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>129</td>
    <td>Czech Republic</td>
    <td>T-MOBILE CZECH</td>
    <td>230</td>
    <td>13</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>130</td>
    <td>Czech Republic</td>
    <td>O2 CZECH REPUBLIC</td>
    <td>230</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>131</td>
    <td>Denmark</td>
    <td>Telia</td>
    <td>238</td>
    <td>20</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>132</td>
    <td>Denmark</td>
    <td>TT-Netværket P/S</td>
    <td>238</td>
    <td>66</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>133</td>
    <td>Denmark</td>
    <td>HI3G DENMARK APS</td>
    <td>238</td>
    <td>63</td>
    <td>UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 900, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>134</td>
    <td>Denmark</td>
    <td>TELIA DANMARK</td>
    <td>238</td>
    <td>203</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>135</td>
    <td>Denmark</td>
    <td>NUUDAY</td>
    <td>238</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>136</td>
    <td>Dominica</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>137</td>
    <td>Dominica</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>138</td>
    <td>Dominica</td>
    <td>CABLE & WIRELESS, DOMINICA</td>
    <td>366</td>
    <td>1103</td>
    <td>GSM 850, UMTS 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>139</td>
    <td>Dominica</td>
    <td>DIGICEL DOMINICA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1900, GSM 900, UMTS 1900, UMTS 900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>140</td>
    <td>Dominican Republic</td>
    <td>Compañía Dominicana de Teléfonos</td>
    <td>370</td>
    <td>2</td>
    <td>GSM 850, GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>141</td>
    <td>Dominican Republic</td>
    <td>CLARO DOMINICANA (CODETEL)</td>
    <td>370</td>
    <td>23</td>
    <td>GSM 1900, GSM 850, UMTS 850, LTE 1700, LTE 1900, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>142</td>
    <td>Ecuador</td>
    <td>Otecel S.A.</td>
    <td>740</td>
    <td>0</td>
    <td>GSM 850, GSM 1900, UMTS 850, LTE 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>143</td>
    <td>Ecuador</td>
    <td>CONECEL (CLARO)</td>
    <td>740</td>
    <td>13</td>
    <td>GSM 850, UMTS 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>144</td>
    <td>Egypt</td>
    <td>Orange Egypt</td>
    <td>602</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>145</td>
    <td>Egypt</td>
    <td>Orange Egypt</td>
    <td>602</td>
    <td>11</td>
    <td>GSM 1900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>146</td>
    <td>Egypt</td>
    <td>Vodafone Egypt</td>
    <td>602</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>147</td>
    <td>Egypt</td>
    <td>VODAFONE EGYPT</td>
    <td>602</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>148</td>
    <td>El Salvador</td>
    <td>CTE Telecom Personal, S.A. de C.V.</td>
    <td>706</td>
    <td>1</td>
    <td>GSM 1900, UMTS 1900, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>149</td>
    <td>El Salvador</td>
    <td>CTE TELECOM PERSONAL (CLARO)</td>
    <td>706</td>
    <td>13</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>150</td>
    <td>Estonia</td>
    <td>Estonian Mobile Telecom</td>
    <td>248</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>151</td>
    <td>Estonia</td>
    <td>ELISA</td>
    <td>248</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>152</td>
    <td>Faroe Islands</td>
    <td>Faroese Telecom</td>
    <td>288</td>
    <td>1</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>153</td>
    <td>Finland</td>
    <td>TeliaSonera Finland Oyj</td>
    <td>244</td>
    <td>91</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 700, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>154</td>
    <td>Finland</td>
    <td>TeliaSonera Finland Oyj / Suomen Yhteisverkko Oy</td>
    <td>244</td>
    <td>36</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>155</td>
    <td>Finland</td>
    <td>ELISA FINLAND</td>
    <td>244</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>156</td>
    <td>Finland</td>
    <td>DNA NETWORKS LTD</td>
    <td>244</td>
    <td>123</td>
    <td>GSM 1800, GSM 900, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>157</td>
    <td>Finland</td>
    <td>DNA NETWORKS LTD</td>
    <td>244</td>
    <td>33</td>
    <td>GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>158</td>
    <td>Finland</td>
    <td>DNA NETWORKS LTD</td>
    <td>244</td>
    <td>363</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>159</td>
    <td>Finland</td>
    <td>ALANDS TELEKOMMUNIKATION AB</td>
    <td>244</td>
    <td>143</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>160</td>
    <td>France</td>
    <td>Bouygues Telecom</td>
    <td>208</td>
    <td>20</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 700, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>161</td>
    <td>France</td>
    <td>Bouygues Telecom</td>
    <td>208</td>
    <td>88</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 700, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>162</td>
    <td>France</td>
    <td>Orange S.A.</td>
    <td>208</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>163</td>
    <td>France</td>
    <td>Orange S.A.</td>
    <td>208</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>164</td>
    <td>France</td>
    <td>SFR</td>
    <td>208</td>
    <td>103</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>165</td>
    <td>France</td>
    <td>BOUYGUES TELECOM</td>
    <td>208</td>
    <td>203</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>166</td>
    <td>France</td>
    <td>FREE MOBILE</td>
    <td>208</td>
    <td>153</td>
    <td>UMTS 2100, UMTS 900, LTE 1800, LTE 2600, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>167</td>
    <td>France</td>
    <td>ORANGE</td>
    <td>208</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>168</td>
    <td>France</td>
    <td>ORANGE</td>
    <td>901</td>
    <td>313</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>169</td>
    <td>Gambia</td>
    <td>Africell</td>
    <td>607</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, LTE, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>170</td>
    <td>Georgia</td>
    <td>Silknet</td>
    <td>282</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>171</td>
    <td>Georgia</td>
    <td>MagtiCom</td>
    <td>282</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>172</td>
    <td>Georgia</td>
    <td>VEON GEORGIA</td>
    <td>282</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>173</td>
    <td>Germany</td>
    <td>VODAFONE D2</td>
    <td>262</td>
    <td>23</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>174</td>
    <td>Germany</td>
    <td>TELEKOM DEUTSCHLAND</td>
    <td>262</td>
    <td>13</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>175</td>
    <td>Germany</td>
    <td>TELEFONICA O2 GERMANY</td>
    <td>262</td>
    <td>73</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>176</td>
    <td>Germany</td>
    <td>TELEFONICA O2 GERMANY</td>
    <td>262</td>
    <td>33</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>177</td>
    <td>Ghana</td>
    <td>Vodafone Group</td>
    <td>620</td>
    <td>2</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>178</td>
    <td>Ghana</td>
    <td>VODAFONE GHANA</td>
    <td>620</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>179</td>
    <td>Gibraltar</td>
    <td>Gibtelecom</td>
    <td>266</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>180</td>
    <td>Gibraltar</td>
    <td>GIBTELECOM</td>
    <td>266</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>181</td>
    <td>Greece</td>
    <td>Wind Hellas Telecommunications S.A.</td>
    <td>202</td>
    <td>10</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>182</td>
    <td>Greece</td>
    <td>Vodafone Greece</td>
    <td>202</td>
    <td>5</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>183</td>
    <td>Greece</td>
    <td>VODAFONE GREECE</td>
    <td>202</td>
    <td>53</td>
    <td>GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>184</td>
    <td>Greece</td>
    <td>COSMOTE</td>
    <td>202</td>
    <td>13</td>
    <td>GSM 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>185</td>
    <td>Greece</td>
    <td>WIND HELLAS (TIM)</td>
    <td>202</td>
    <td>103</td>
    <td>GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>186</td>
    <td>Greece</td>
    <td>WIND HELLAS (TIM)</td>
    <td>202</td>
    <td>93</td>
    <td>GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>187</td>
    <td>Greenland</td>
    <td>TELE Greenland A/S</td>
    <td>290</td>
    <td>1</td>
    <td>GSM 900, LTE 800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>188</td>
    <td>Greenland</td>
    <td>TELE GREENLAND A/S</td>
    <td>290</td>
    <td>13</td>
    <td>GSM 900, UMTS 900, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>189</td>
    <td>Grenada</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>190</td>
    <td>Grenada</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>191</td>
    <td>Grenada</td>
    <td>CABLE & WIRELESS, GRENADA</td>
    <td>352</td>
    <td>1103</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>192</td>
    <td>Grenada</td>
    <td>DIGICEL GRENADA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>193</td>
    <td>Guatemala</td>
    <td>Telecomunicaciones de Guatemala, S.A.</td>
    <td>704</td>
    <td>1</td>
    <td>GSM 900, CDMA 1900, GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>194</td>
    <td>Guatemala</td>
    <td>SERCOM (CLARO)</td>
    <td>704</td>
    <td>13</td>
    <td>GSM 900, UMTS 1900, LTE 1900, CDMA 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>195</td>
    <td>Haiti</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>196</td>
    <td>Haiti</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>197</td>
    <td>Haiti</td>
    <td>NATCOM</td>
    <td>372</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>198</td>
    <td>Haiti</td>
    <td>DIGICEL HAITI</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>199</td>
    <td>Holy See (Vatican City State)</td>
    <td>Telecom Italia S.p.A</td>
    <td>222</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 1500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>200</td>
    <td>Holy See (Vatican City State)</td>
    <td>TIM ITALIA</td>
    <td>222</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1500, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>201</td>
    <td>Honduras</td>
    <td>SERCOM HONDURAS S.A de C.V.</td>
    <td>708</td>
    <td>0</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>202</td>
    <td>Honduras</td>
    <td>Servicios de Comunicaciones de Honduras S.A. de C.V.</td>
    <td>708</td>
    <td>1</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>203</td>
    <td>Honduras</td>
    <td>CLARO HONDURAS</td>
    <td>708</td>
    <td>13</td>
    <td>GSM 1900, UMTS 1900, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>204</td>
    <td>Hong Kong</td>
    <td>SmarTone Mobile Communications Limited</td>
    <td>454</td>
    <td>6</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 850, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>205</td>
    <td>Hong Kong</td>
    <td>Hutchison Telecom</td>
    <td>454</td>
    <td>3</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>206</td>
    <td>Hong Kong</td>
    <td>HUTCHISON TELECOM (HK) LTD</td>
    <td>454</td>
    <td>43</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>207</td>
    <td>Hong Kong</td>
    <td>HUTCHISON TELECOM (HK) LTD</td>
    <td>454</td>
    <td>33</td>
    <td>UMTS 2100, UMTS 900</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>208</td>
    <td>Hong Kong</td>
    <td>CHINA MOBILE HONG KONG</td>
    <td>454</td>
    <td>123</td>
    <td>GSM 1800, LTE 1800, LTE 2100, LTE 2600, LTE 900, TD-LTE 2300, TD-SCDMA 2000</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>209</td>
    <td>Hungary</td>
    <td>Vodafone Magyarország Zrt.</td>
    <td>216</td>
    <td>70</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>210</td>
    <td>Hungary</td>
    <td>VODAFONE HUNGARY</td>
    <td>216</td>
    <td>703</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>211</td>
    <td>Hungary</td>
    <td>TELENOR MAGYARORSZAG ZRT.</td>
    <td>216</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>212</td>
    <td>Hungary</td>
    <td>T-MOBILE HUNGARY</td>
    <td>216</td>
    <td>303</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>213</td>
    <td>Iceland</td>
    <td>Nova ehf</td>
    <td>274</td>
    <td>11</td>
    <td>UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>214</td>
    <td>Iceland</td>
    <td>NOVA</td>
    <td>274</td>
    <td>113</td>
    <td>UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>215</td>
    <td>Iceland</td>
    <td>SIMINN</td>
    <td>274</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>216</td>
    <td>Iceland</td>
    <td>VODAFONE ICELAND</td>
    <td>274</td>
    <td>23</td>
    <td>GSM 900, UMTS 2100, LTE 1800, LTE 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>217</td>
    <td>India</td>
    <td>Chennai</td>
    <td>404</td>
    <td>40</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>218</td>
    <td>India</td>
    <td>Karnataka</td>
    <td>404</td>
    <td>45</td>
    <td>TD-LTE 2300, GSM 900, GSM 1800, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>219</td>
    <td>India</td>
    <td>Andhra Pradesh and Telangana</td>
    <td>404</td>
    <td>49</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>220</td>
    <td>India</td>
    <td>Kolkata</td>
    <td>404</td>
    <td>31</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>221</td>
    <td>India</td>
    <td>Madhya Pradesh</td>
    <td>404</td>
    <td>93</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>222</td>
    <td>India</td>
    <td>Haryana</td>
    <td>404</td>
    <td>96</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>223</td>
    <td>India</td>
    <td>Mumbai</td>
    <td>404</td>
    <td>92</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>224</td>
    <td>India</td>
    <td>Uttar Pradesh (West)</td>
    <td>404</td>
    <td>97</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>225</td>
    <td>India</td>
    <td>Gujarat</td>
    <td>404</td>
    <td>98</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>226</td>
    <td>India</td>
    <td>Himachal Pradesh</td>
    <td>404</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>227</td>
    <td>India</td>
    <td>Maharashtra</td>
    <td>404</td>
    <td>90</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>228</td>
    <td>India</td>
    <td>Kerala</td>
    <td>404</td>
    <td>95</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>229</td>
    <td>India</td>
    <td>Punjab</td>
    <td>404</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>230</td>
    <td>India</td>
    <td>Tamil Nadu</td>
    <td>404</td>
    <td>94</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>231</td>
    <td>India</td>
    <td>West Bengal</td>
    <td>405</td>
    <td>51</td>
    <td>GSM 900, LTE 1800, GSM 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>232</td>
    <td>India</td>
    <td>Orissa</td>
    <td>405</td>
    <td>53</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>233</td>
    <td>India</td>
    <td>Uttar Pradesh (East)</td>
    <td>405</td>
    <td>54</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>234</td>
    <td>India</td>
    <td>North East</td>
    <td>404</td>
    <td>16</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>235</td>
    <td>India</td>
    <td>Rajasthan</td>
    <td>404</td>
    <td>70</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>236</td>
    <td>India</td>
    <td>Assam</td>
    <td>405</td>
    <td>56</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>237</td>
    <td>India</td>
    <td>Chennai</td>
    <td>404</td>
    <td>401</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>238</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>404</td>
    <td>451</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>239</td>
    <td>India</td>
    <td>Andhra Pradesh and Telangana</td>
    <td>404</td>
    <td>491</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>240</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>404</td>
    <td>311</td>
    <td>GSM 1800, GSM 900, LTE 1700, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>241</td>
    <td>India</td>
    <td>Madhya Pradesh</td>
    <td>404</td>
    <td>931</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>242</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>404</td>
    <td>961</td>
    <td>GSM 1800, GSM 900, LTE 1700, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>243</td>
    <td>India</td>
    <td>Mumbai</td>
    <td>404</td>
    <td>921</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>244</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>404</td>
    <td>971</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>245</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>404</td>
    <td>981</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>246</td>
    <td>India</td>
    <td>Himachal Pradesh</td>
    <td>404</td>
    <td>31</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>247</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>404</td>
    <td>901</td>
    <td>GSM 1800, GSM 900, LTE 1700, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>248</td>
    <td>India</td>
    <td>Kerala</td>
    <td>404</td>
    <td>951</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>249</td>
    <td>India</td>
    <td>Punjab</td>
    <td>404</td>
    <td>21</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>250</td>
    <td>India</td>
    <td>Tamil Nadu</td>
    <td>404</td>
    <td>941</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>251</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>405</td>
    <td>511</td>
    <td>GSM 1800, GSM 900, LTE 1700, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>252</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>405</td>
    <td>521</td>
    <td>GSM 1800, GSM 900, LTE 1700, LTE 1900, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>253</td>
    <td>India</td>
    <td>Bharti Airtel</td>
    <td>405</td>
    <td>531</td>
    <td>GSM 1800, GSM 900, LTE 1700, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>254</td>
    <td>India</td>
    <td>Rajasthan</td>
    <td>404</td>
    <td>701</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>255</td>
    <td>India</td>
    <td>VODAFONE IDEA LIMITED</td>
    <td>404</td>
    <td>73</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>256</td>
    <td>India</td>
    <td>VODAFONE IDEA LIMITED</td>
    <td>404</td>
    <td>823</td>
    <td>GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>257</td>
    <td>India</td>
    <td>VODAFONE IDEA LIMITED</td>
    <td>404</td>
    <td>783</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>258</td>
    <td>India</td>
    <td>VODAFONE IDEA LIMITED</td>
    <td>404</td>
    <td>223</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>259</td>
    <td>India</td>
    <td>VODAFONE IDEA LIMITED</td>
    <td>404</td>
    <td>563</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>260</td>
    <td>India</td>
    <td>VODAFONE IDEA LIMITED</td>
    <td>405</td>
    <td>703</td>
    <td>GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>261</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>103</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 850, LTE 900, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>262</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>903</td>
    <td>GSM 1800, LTE 1800, LTE 2100, LTE 850, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>263</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>983</td>
    <td>GSM 1800, LTE 1800, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>264</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>943</td>
    <td>GSM 1800, LTE 1800, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>265</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>963</td>
    <td>GSM 1800, LTE 1800, LTE 2100, LTE 850, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>266</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>973</td>
    <td>GSM 1800, LTE 1800, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>267</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>953</td>
    <td>GSM 1800, LTE 1800, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>268</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>933</td>
    <td>GSM 1800, UMTS 1800, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>269</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>270</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>493</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 850, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>271</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>703</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>272</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>453</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>273</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>274</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>313</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>275</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>403</td>
    <td>GSM 1800, LTE 1800, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>276</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>405</td>
    <td>543</td>
    <td>GSM 900, UTMS 2100, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>277</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>405</td>
    <td>533</td>
    <td>GSM 900, UMTS 2100, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>278</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>405</td>
    <td>523</td>
    <td>LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>279</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>405</td>
    <td>513</td>
    <td>GSM 900, UMTS 2100, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>280</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>405</td>
    <td>553</td>
    <td>GSM 900, UMTS 2100, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>281</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>405</td>
    <td>563</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 900, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>282</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>163</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>283</td>
    <td>India</td>
    <td>BHARTI AIRTEL LTD</td>
    <td>404</td>
    <td>923</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 850, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>284</td>
    <td>Indonesia</td>
    <td>PT Telekomunikasi Selular</td>
    <td>510</td>
    <td>101</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>285</td>
    <td>Indonesia</td>
    <td>Telkomsel Indonesia</td>
    <td>510</td>
    <td>10</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>286</td>
    <td>Indonesia</td>
    <td>PT. HUTCHISON 3 INDONESIA</td>
    <td>510</td>
    <td>893</td>
    <td>GSM 1800, UTMS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>287</td>
    <td>Indonesia</td>
    <td>XL AXIATA</td>
    <td>510</td>
    <td>113</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>288</td>
    <td>Indonesia</td>
    <td>INDOSAT OOREDOO</td>
    <td>510</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>289</td>
    <td>Indonesia</td>
    <td>INDOSAT OOREDOO</td>
    <td>510</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>290</td>
    <td>Indonesia</td>
    <td>TELKOMSEL</td>
    <td>510</td>
    <td>103</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>291</td>
    <td>Iraq</td>
    <td>Telecom Ltd</td>
    <td>418</td>
    <td>40</td>
    <td>GSM 900, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>292</td>
    <td>Ireland</td>
    <td>Eir Group plc</td>
    <td>272</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>293</td>
    <td>Ireland</td>
    <td>VODAFONE IRELAND</td>
    <td>272</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>294</td>
    <td>Israel</td>
    <td>Partner Communications Company Ltd</td>
    <td>425</td>
    <td>1</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>295</td>
    <td>Israel</td>
    <td>PARTNER COMMUNICATIONS (ORANGE)</td>
    <td>425</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>296</td>
    <td>Italy</td>
    <td>Telecom Italia S.p.A</td>
    <td>222</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 1500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>297</td>
    <td>Italy</td>
    <td>VODAFONE OMNITEL</td>
    <td>222</td>
    <td>103</td>
    <td>GSM 900, GSM 1800, LTE</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>298</td>
    <td>Italy</td>
    <td>WIND TRE S.P.A.</td>
    <td>222</td>
    <td>883</td>
    <td>GSM 900, UMTS 900, UTMS 2100, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>299</td>
    <td>Italy</td>
    <td>ILIAD ITALIA S.P.A.</td>
    <td>222</td>
    <td>503</td>
    <td>UMTS 900, LTE 1800, LTE 2100, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>300</td>
    <td>Italy</td>
    <td>TIM ITALIA</td>
    <td>222</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1500, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>301</td>
    <td>Jamaica</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>302</td>
    <td>Jamaica</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>303</td>
    <td>Jamaica</td>
    <td>CABLE & WIRELESS, JAMAICA</td>
    <td>338</td>
    <td>1803</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>304</td>
    <td>Jamaica</td>
    <td>DIGICEL</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1900, GSM 900, UMTS 850, LTE 1700, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>305</td>
    <td>Japan</td>
    <td>SoftBank Corp.</td>
    <td>440</td>
    <td>201</td>
    <td>UMTS 2100, LTE 1800, LTE 700, LTE 900, LTE 2100, TD-LTE 3500, LTE 1500, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>306</td>
    <td>Japan</td>
    <td>NTT DOCOMO</td>
    <td>440</td>
    <td>103</td>
    <td>UMTS 2100, UMTS 850, LTE 1500, LTE 1800, LTE 2100, LTE 700, LTE 850, TD-LTE 3500, TD-LTE 2500</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>307</td>
    <td>Jordan</td>
    <td>Jordan Mobile Telephone Services</td>
    <td>416</td>
    <td>1</td>
    <td>GSM 900, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>308</td>
    <td>Jordan</td>
    <td>ORANGE (MOBILECOM)</td>
    <td>416</td>
    <td>773</td>
    <td>GSM 900, UMTS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>309</td>
    <td>Kazakhstan</td>
    <td>Kcell JSC</td>
    <td>401</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>310</td>
    <td>Kazakhstan</td>
    <td>KaR-Tel LLP</td>
    <td>401</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, LTE 2100, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>311</td>
    <td>Kazakhstan</td>
    <td>KAR-TEL, BEELINE</td>
    <td>401</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>312</td>
    <td>Kenya</td>
    <td>Telkom Kenya</td>
    <td>639</td>
    <td>7</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>313</td>
    <td>Kenya</td>
    <td>SAFARICOM</td>
    <td>639</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>314</td>
    <td>Korea, Republic of</td>
    <td>SK Telecom</td>
    <td>450</td>
    <td>5</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, LTE 2100, LTE 850, CDMA2000 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>315</td>
    <td>Korea, Republic of</td>
    <td>SK Telecom</td>
    <td>450</td>
    <td>51</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, LTE 2100, LTE 850, CDMA2000 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>316</td>
    <td>Korea, Republic of</td>
    <td>SK TELECOM</td>
    <td>450</td>
    <td>53</td>
    <td>UTMS 2100, LTE 1800, LTE 2100, LTE 2600, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>317</td>
    <td>Kosovo</td>
    <td>Monaco Telecom</td>
    <td>212</td>
    <td>1</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>318</td>
    <td>Kosovo</td>
    <td>Telekom Srbija</td>
    <td>220</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, TETRA</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>319</td>
    <td>Kosovo</td>
    <td>IPKO NET</td>
    <td>221</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>320</td>
    <td>Kuwait</td>
    <td>National Mobile Telecommunications</td>
    <td>419</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>321</td>
    <td>Kuwait</td>
    <td>ZAIN KUWAIT</td>
    <td>419</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>322</td>
    <td>Kyrgyzstan</td>
    <td>Alfa Telecom CJSC</td>
    <td>437</td>
    <td>5</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>323</td>
    <td>Kyrgyzstan</td>
    <td>SKY MOBILE (BEELINE)</td>
    <td>437</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>324</td>
    <td>Latvia</td>
    <td>Latvian Mobile Telephone</td>
    <td>247</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>325</td>
    <td>Latvia</td>
    <td>BITE LATVIJA</td>
    <td>247</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>326</td>
    <td>Latvia</td>
    <td>TELE2 LATVIJA</td>
    <td>247</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>327</td>
    <td>Latvia</td>
    <td>LMT</td>
    <td>247</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>328</td>
    <td>Lebanon</td>
    <td>MIC 1</td>
    <td>415</td>
    <td>1</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>329</td>
    <td>Lebanon</td>
    <td>MIC 2 (TOUCH)</td>
    <td>415</td>
    <td>33</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>330</td>
    <td>Liechtenstein</td>
    <td>A1 Telekom Austria</td>
    <td>232</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>331</td>
    <td>Liechtenstein</td>
    <td>Telecom Liechtenstein AG</td>
    <td>295</td>
    <td>5</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>332</td>
    <td>Liechtenstein</td>
    <td>TELECOM LIECHTENSTEIN AG</td>
    <td>295</td>
    <td>53</td>
    <td>GSM 900, UTMS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>333</td>
    <td>Lithuania</td>
    <td>Telia Lietuva</td>
    <td>246</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, LTE 2600, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>334</td>
    <td>Lithuania</td>
    <td>BITE LIETUVA</td>
    <td>246</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>335</td>
    <td>Lithuania</td>
    <td>TELIA LIETUVA</td>
    <td>246</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>336</td>
    <td>Luxembourg</td>
    <td>Tango SA</td>
    <td>270</td>
    <td>77</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>337</td>
    <td>Luxembourg</td>
    <td>Orange S.A.</td>
    <td>270</td>
    <td>99</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>338</td>
    <td>Luxembourg</td>
    <td>POST LUXEMBOURG</td>
    <td>270</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>339</td>
    <td>Luxembourg</td>
    <td>TANGO MOBILE SA</td>
    <td>270</td>
    <td>773</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>340</td>
    <td>Macao</td>
    <td>Companhia de Telecomunicações de Macau, S.A.R.L.</td>
    <td>455</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>341</td>
    <td>Macao</td>
    <td>Companhia de Telecomunicações de Macau, S.A.R.L.</td>
    <td>455</td>
    <td>11</td>
    <td>GSM 900, GSM 1800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>342</td>
    <td>Macao</td>
    <td>C.T.M. MACAU</td>
    <td>455</td>
    <td>13</td>
    <td>LTE 1800</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>343</td>
    <td>Macedonia, the Former Yugoslav Republic of</td>
    <td>ONE.VIP DOO</td>
    <td>294</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>344</td>
    <td>Malaysia</td>
    <td>Maxis Communications Berhad</td>
    <td>502</td>
    <td>12</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>345</td>
    <td>Malaysia</td>
    <td>DiGi Telecommunications</td>
    <td>502</td>
    <td>16</td>
    <td>GSM 1800, UMTS 2100, LTE 1800, LTE 2600, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>346</td>
    <td>Malaysia</td>
    <td>Maxis Communications Berhad</td>
    <td>502</td>
    <td>121</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>347</td>
    <td>Malaysia</td>
    <td>MAXIS</td>
    <td>502</td>
    <td>123</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>348</td>
    <td>Malta</td>
    <td>Mobile Communications Limited</td>
    <td>278</td>
    <td>21</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>349</td>
    <td>Malta</td>
    <td>MELITA MOBILE</td>
    <td>278</td>
    <td>773</td>
    <td>UMTS 2100, UMTS 900, LTE 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>350</td>
    <td>Malta</td>
    <td>VODAFONE MALTA LTD</td>
    <td>278</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>351</td>
    <td>Malta</td>
    <td>GO MOBILE</td>
    <td>278</td>
    <td>213</td>
    <td>GSM 900, UTMS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>352</td>
    <td>Malta</td>
    <td>EPIC</td>
    <td>901</td>
    <td>193</td>
    <td>GSM</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>353</td>
    <td>Mauritius</td>
    <td>Cellplus Mobile Communications Ltd.</td>
    <td>617</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>354</td>
    <td>Mauritius</td>
    <td>MY.T</td>
    <td>617</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>355</td>
    <td>Mexico</td>
    <td>Radiomovil Dipsa S.A. de C.V. - Telcel</td>
    <td>334</td>
    <td>2</td>
    <td>GSM 1900, GSM 850, UMTS 850, LTE 1700, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>356</td>
    <td>Mexico</td>
    <td>América Móvil</td>
    <td>334</td>
    <td>20</td>
    <td>LTE 2600, GSM 1900, UMTS 850, LTE 1700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>357</td>
    <td>Mexico</td>
    <td>TELCEL</td>
    <td>334</td>
    <td>203</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 2600, LTE 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>358</td>
    <td>Moldova, Republic of</td>
    <td>Moldcell</td>
    <td>259</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>359</td>
    <td>Moldova, Republic of</td>
    <td>Orange Moldova</td>
    <td>259</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>360</td>
    <td>Moldova, Republic of</td>
    <td>Moldtelecom</td>
    <td>259</td>
    <td>5</td>
    <td>UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>361</td>
    <td>Moldova, Republic of</td>
    <td>ORANGE MOLDOVA</td>
    <td>259</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>362</td>
    <td>Monaco</td>
    <td>Monaco Telecom</td>
    <td>212</td>
    <td>10</td>
    <td>GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>363</td>
    <td>Monaco</td>
    <td>MONACO TELECOM S.A.M.</td>
    <td>212</td>
    <td>103</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>364</td>
    <td>Mongolia</td>
    <td>Mobicom Corporation</td>
    <td>428</td>
    <td>99</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>365</td>
    <td>Mongolia</td>
    <td>MOBICOM MONGOLIA</td>
    <td>428</td>
    <td>993</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>366</td>
    <td>Mongolia</td>
    <td>UNITEL MONGOLIA</td>
    <td>428</td>
    <td>883</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>367</td>
    <td>Montenegro</td>
    <td>MTEL CG</td>
    <td>297</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>368</td>
    <td>Montenegro</td>
    <td>MTEL D.O.O. PODGORICA</td>
    <td>297</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UTMS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>369</td>
    <td>Montenegro</td>
    <td>TELENOR MONTENEGRO</td>
    <td>297</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>370</td>
    <td>Montserrat</td>
    <td>DIGICEL ARUBA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>371</td>
    <td>Montserrat</td>
    <td>CABLE & WIRELESS, MONTSERRAT</td>
    <td>354</td>
    <td>8603</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>372</td>
    <td>Morocco</td>
    <td>Ittissalat Al-Maghrib (Maroc Telecom)</td>
    <td>604</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>373</td>
    <td>Mozambique</td>
    <td>Vodacom Mozambique, S.A.</td>
    <td>643</td>
    <td>4</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>374</td>
    <td>Mozambique</td>
    <td>VODACOM SA</td>
    <td>643</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>375</td>
    <td>Myanmar</td>
    <td>Telenor Myanmar</td>
    <td>414</td>
    <td>6</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>376</td>
    <td>Myanmar</td>
    <td>TELENOR MYANMAR LIMITED</td>
    <td>414</td>
    <td>63</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>377</td>
    <td>Myanmar</td>
    <td>MYTEL</td>
    <td>414</td>
    <td>93</td>
    <td>GSM 900, UMTS 900, UTMS 2100, LTE 2100, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>378</td>
    <td>Nepal</td>
    <td>Ncell Pvt. Ltd.</td>
    <td>429</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>379</td>
    <td>Netherlands</td>
    <td>KPN Mobile The Netherlands B.V.</td>
    <td>204</td>
    <td>8</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>380</td>
    <td>Netherlands</td>
    <td>VODAFONE LIBERTEL</td>
    <td>204</td>
    <td>43</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>381</td>
    <td>Netherlands Antilles</td>
    <td>Curaçao Telecom N.V.</td>
    <td>362</td>
    <td>69</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>382</td>
    <td>Netherlands Antilles</td>
    <td>DIGICEL</td>
    <td>362</td>
    <td>693</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>383</td>
    <td>Netherlands Antilles</td>
    <td>TELCELL ST. MAARTEN</td>
    <td>362</td>
    <td>513</td>
    <td>GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>384</td>
    <td>New Zealand</td>
    <td>Spark New Zealand</td>
    <td>530</td>
    <td>5</td>
    <td>UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>385</td>
    <td>New Zealand</td>
    <td>Spark New Zealand</td>
    <td>530</td>
    <td>51</td>
    <td>UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>386</td>
    <td>New Zealand</td>
    <td>VODAFONE NEW ZEALAND</td>
    <td>530</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>387</td>
    <td>New Zealand</td>
    <td>TWO DEGREES MOBILE LTD.</td>
    <td>530</td>
    <td>243</td>
    <td>UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 700, LTE 900</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>388</td>
    <td>Nicaragua</td>
    <td>Empresa Nicaragüense de Telecomunicaciones, S.A. (ENITEL) (América Móvil)</td>
    <td>710</td>
    <td>21</td>
    <td>GSM 1900, UMTS 850, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>389</td>
    <td>Nicaragua</td>
    <td>ENITEL (CLARO)</td>
    <td>710</td>
    <td>213</td>
    <td>GSM 1900, GSM 850, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>390</td>
    <td>Nicaragua</td>
    <td>ENITEL (CLARO)</td>
    <td>710</td>
    <td>733</td>
    <td>GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>391</td>
    <td>Nigeria</td>
    <td>9mobile</td>
    <td>621</td>
    <td>60</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>392</td>
    <td>Norway</td>
    <td>TeliaSonera Norge AS</td>
    <td>242</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>393</td>
    <td>Norway</td>
    <td>TELENOR NORGE AS</td>
    <td>242</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>394</td>
    <td>Oman</td>
    <td>Oman Telecommunications Company</td>
    <td>422</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, LTE 1800, UMTS 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>395</td>
    <td>Oman</td>
    <td>OMANTEL</td>
    <td>422</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 900, LTE 1800, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>396</td>
    <td>Pakistan</td>
    <td>Pakistan Telecommunication Mobile Ltd</td>
    <td>410</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>397</td>
    <td>Pakistan</td>
    <td>TELENOR PAKISTAN PVT. LTD</td>
    <td>410</td>
    <td>63</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>398</td>
    <td>Pakistan</td>
    <td>CMPAK LIMITED</td>
    <td>410</td>
    <td>43</td>
    <td>Unknown</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>399</td>
    <td>Palestinian, State of</td>
    <td>Palestine Cellular Communications Ltd.</td>
    <td>425</td>
    <td>5</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>400</td>
    <td>Panama</td>
    <td>América Móvil</td>
    <td>714</td>
    <td>3</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>401</td>
    <td>Panama</td>
    <td>Digicel Group</td>
    <td>714</td>
    <td>4</td>
    <td>UMTS 1900</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>402</td>
    <td>Panama</td>
    <td>CLARO PANAMA</td>
    <td>714</td>
    <td>33</td>
    <td>GSM 1900, UMTS 1900, LTE 1900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>403</td>
    <td>Paraguay</td>
    <td>AMX Paraguay S.A.</td>
    <td>744</td>
    <td>2</td>
    <td>GSM 1900, LTE 1700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>404</td>
    <td>Paraguay</td>
    <td>CLARO PARAGUAY</td>
    <td>744</td>
    <td>23</td>
    <td>GSM 1900, UMTS 1900, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>405</td>
    <td>Peru</td>
    <td>América Móvil Perú</td>
    <td>716</td>
    <td>10</td>
    <td>GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>406</td>
    <td>Peru</td>
    <td>AMERICA MOVIL PERU (CLARO)</td>
    <td>716</td>
    <td>103</td>
    <td>GSM 1900, GSM 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>407</td>
    <td>Philippines</td>
    <td>Globe Telecom</td>
    <td>515</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 850, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>408</td>
    <td>Philippines</td>
    <td>PLDT via Smart Communications</td>
    <td>515</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 850, LTE 700, LTE 2100, TD-LTE 2300, LTE 850, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>409</td>
    <td>Philippines</td>
    <td>GLOBE TELECOM</td>
    <td>515</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 700, TD-LTE 2300, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>410</td>
    <td>Poland</td>
    <td>Polska Telefonia Komórkowa Centertel Sp. z o.o.</td>
    <td>260</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>411</td>
    <td>Poland</td>
    <td>Polkomtel Sp. z o.o.</td>
    <td>260</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>412</td>
    <td>Poland</td>
    <td>POLKOMTEL</td>
    <td>260</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 900, UTMS 2100, LTE 1800, LTE 2100, LTE 2600, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>413</td>
    <td>Poland</td>
    <td>T-MOBILE POLSKA S.A.</td>
    <td>260</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>414</td>
    <td>Poland</td>
    <td>ORANGE POLSKA S.A</td>
    <td>260</td>
    <td>33</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>415</td>
    <td>Poland</td>
    <td>PROJECT 4</td>
    <td>260</td>
    <td>63</td>
    <td>GSM 1800, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>416</td>
    <td>Portugal</td>
    <td>Vodafone Portugal</td>
    <td>268</td>
    <td>1</td>
    <td>GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>417</td>
    <td>Portugal</td>
    <td>Telecomunicações Móveis Nacionais</td>
    <td>268</td>
    <td>6</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>418</td>
    <td>Portugal</td>
    <td>VODAFONE PORTUGAL</td>
    <td>268</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>419</td>
    <td>Puerto Rico</td>
    <td>AT&T</td>
    <td>310</td>
    <td>41</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>420</td>
    <td>Puerto Rico</td>
    <td>AT&T</td>
    <td>310</td>
    <td>410</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>421</td>
    <td>Puerto Rico</td>
    <td>América Móvil</td>
    <td>330</td>
    <td>110</td>
    <td>GSM 850, GSM 1900, UMTS 850, LTE 700, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>422</td>
    <td>Puerto Rico</td>
    <td>AT&T MOBILITY</td>
    <td>310</td>
    <td>3803</td>
    <td>GSM 1900, GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>423</td>
    <td>Qatar</td>
    <td>Vodafone</td>
    <td>427</td>
    <td>21</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 450</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>424</td>
    <td>Qatar</td>
    <td>VODAFONE QATAR</td>
    <td>427</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>425</td>
    <td>Romania</td>
    <td>Orange România</td>
    <td>226</td>
    <td>10</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>426</td>
    <td>Romania</td>
    <td>VODAFONE ROMANIA</td>
    <td>226</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>427</td>
    <td>Romania</td>
    <td>ORANGE ROMANIA</td>
    <td>226</td>
    <td>103</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>428</td>
    <td>Russian Federation</td>
    <td>Mobile TeleSystems</td>
    <td>250</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 900, LTE 2100, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>429</td>
    <td>Russian Federation</td>
    <td>MegaFon PJSC</td>
    <td>250</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>430</td>
    <td>Russian Federation</td>
    <td>BEELINE (VIMPELCOM)</td>
    <td>250</td>
    <td>993</td>
    <td>GSM 1900, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>431</td>
    <td>Russian Federation</td>
    <td>MEGAFON</td>
    <td>250</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>432</td>
    <td>Saint Kitts and Nevis</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>433</td>
    <td>Saint Kitts and Nevis</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>434</td>
    <td>Saint Kitts and Nevis</td>
    <td>DIGICEL ST KITTS & NEVIS</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>435</td>
    <td>Saint Kitts and Nevis</td>
    <td>CABLE & WIRELESS, ST KITTS & NEVIS</td>
    <td>356</td>
    <td>1103</td>
    <td>GSM 1900, GSM 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>436</td>
    <td>Saint Lucia</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>437</td>
    <td>Saint Lucia</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>438</td>
    <td>Saint Lucia</td>
    <td>DIGICEL ST. LUCIA</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 1900, GSM 900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>439</td>
    <td>Saint Lucia</td>
    <td>CABLE & WIRELESS, ST. LUCIA</td>
    <td>358</td>
    <td>1103</td>
    <td>GSM 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>440</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>441</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>442</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>DIGICEL ST. VINCENT & GRENADINES</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1800, GSM 1900, GSM 900, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>443</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>CABLE & WIRELESS, ST. VINCENT & GRENADINES</td>
    <td>360</td>
    <td>1103</td>
    <td>GSM 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>444</td>
    <td>San Marino</td>
    <td>Telecom Italia S.p.A</td>
    <td>222</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 1500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>445</td>
    <td>San Marino</td>
    <td>TIM ITALIA</td>
    <td>222</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1500, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>446</td>
    <td>Saudi Arabia</td>
    <td>Etihad Etisalat Company</td>
    <td>420</td>
    <td>31</td>
    <td>GSM 900, UMTS 2100, LTE 1800, UMTS 900, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>447</td>
    <td>Saudi Arabia</td>
    <td>SAUDI TELECOM COMPANY (ALJAWAL)</td>
    <td>420</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2100, LTE 850, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>448</td>
    <td>Serbia</td>
    <td>Telekom Srbija</td>
    <td>220</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, TETRA</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>449</td>
    <td>Serbia</td>
    <td>TELENOR SERBIA</td>
    <td>220</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>450</td>
    <td>Sierra Leone</td>
    <td>Lintel Sierra Leone Limited</td>
    <td>619</td>
    <td>5</td>
    <td>GSM 900, UMTS 2100, GSM 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>451</td>
    <td>Sierra Leone</td>
    <td>AFRICELL-LINTEL (SL) LTD.</td>
    <td>619</td>
    <td>53</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>452</td>
    <td>Singapore</td>
    <td>Singapore Telecom</td>
    <td>525</td>
    <td>1</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>453</td>
    <td>Singapore</td>
    <td>Singapore Telecom</td>
    <td>525</td>
    <td>11</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>454</td>
    <td>Singapore</td>
    <td>SINGTEL MOBILE SINGAPORE</td>
    <td>525</td>
    <td>13</td>
    <td>UMTS 2100, UMTS 900, LTE 1800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>455</td>
    <td>Slovakia</td>
    <td>O2 Slovakia</td>
    <td>231</td>
    <td>6</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, TD-LTE 3500, TD-LTE 3700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>456</td>
    <td>Slovakia</td>
    <td>SLOVAK TELEKOM</td>
    <td>231</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>457</td>
    <td>Slovenia</td>
    <td>A1 Slovenija</td>
    <td>293</td>
    <td>40</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>458</td>
    <td>Slovenia</td>
    <td>TELEKOM SLOVENIJE D.D.</td>
    <td>293</td>
    <td>413</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>459</td>
    <td>Slovenia</td>
    <td>TELEMACH MOBIL</td>
    <td>293</td>
    <td>703</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 700, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>460</td>
    <td>South Africa</td>
    <td>Vodacom</td>
    <td>655</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>461</td>
    <td>South Africa</td>
    <td>VODACOM SOUTH AFRICA</td>
    <td>655</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>462</td>
    <td>Spain</td>
    <td>Orange Espagne S.A.U</td>
    <td>214</td>
    <td>3</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>463</td>
    <td>Spain</td>
    <td>VODAFONE ESPANA</td>
    <td>214</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>464</td>
    <td>Spain</td>
    <td>XFERA MÓVILES S.A. (YOIGO)</td>
    <td>214</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>465</td>
    <td>Spain</td>
    <td>TELEFONICA ESPANA</td>
    <td>214</td>
    <td>73</td>
    <td>GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>466</td>
    <td>Spain</td>
    <td>ORANGE ESPAGNE</td>
    <td>214</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>467</td>
    <td>Sri Lanka</td>
    <td>Mobitel (Pvt) Ltd</td>
    <td>413</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>468</td>
    <td>Sri Lanka</td>
    <td>DIALOG AXIATA PLC</td>
    <td>413</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>469</td>
    <td>Suriname</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>470</td>
    <td>Suriname</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>471</td>
    <td>Suriname</td>
    <td>Digicel Suriname</td>
    <td>746</td>
    <td>3</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>472</td>
    <td>Suriname</td>
    <td>DIGICEL SURINAME N.V.</td>
    <td>746</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>473</td>
    <td>Sweden</td>
    <td>TeliaSonera Sverige AB</td>
    <td>240</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>474</td>
    <td>Sweden</td>
    <td>Svenska UMTS-Nät AB</td>
    <td>240</td>
    <td>5</td>
    <td>UMTS 2100, GSM 900, GSM 1800, LTE 1800, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>475</td>
    <td>Sweden</td>
    <td>TELENOR SVERIGE AB</td>
    <td>240</td>
    <td>83</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>476</td>
    <td>Sweden</td>
    <td>TELENOR SVERIGE AB</td>
    <td>240</td>
    <td>243</td>
    <td>GSM 900, LTE 1800, LTE 2600, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>477</td>
    <td>Sweden</td>
    <td>TELENOR SVERIGE AB</td>
    <td>240</td>
    <td>423</td>
    <td>Unknown</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>478</td>
    <td>Sweden</td>
    <td>TELENOR SVERIGE AB</td>
    <td>901</td>
    <td>293</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>479</td>
    <td>Sweden</td>
    <td>HI3G ACCESS AB</td>
    <td>240</td>
    <td>23</td>
    <td>GSM 900, UMTS 2100, LTE 2600, LTE 800, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>480</td>
    <td>Sweden</td>
    <td>TELE2 COMVIQ AB</td>
    <td>240</td>
    <td>73</td>
    <td>LTE 1800, LTE 2600, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>481</td>
    <td>Sweden</td>
    <td>TELE2 COMVIQ AB</td>
    <td>240</td>
    <td>103</td>
    <td>Unknown</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>482</td>
    <td>Sweden</td>
    <td>TELIA SVERIGE AB</td>
    <td>240</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>483</td>
    <td>Sweden</td>
    <td>HI3G ACCESS AB</td>
    <td>240</td>
    <td>43</td>
    <td>UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>484</td>
    <td>Switzerland</td>
    <td>Sunrise Communications AG</td>
    <td>228</td>
    <td>2</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>485</td>
    <td>Switzerland</td>
    <td>Salt Mobile SA</td>
    <td>228</td>
    <td>3</td>
    <td>GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>486</td>
    <td>Switzerland</td>
    <td>SWISSCOM</td>
    <td>228</td>
    <td>13</td>
    <td>UMTS 900, LTE 1800, LTE 2100, LTE 2600, LTE 700, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>487</td>
    <td>Syrian Arab Republic</td>
    <td>MTN Syria</td>
    <td>417</td>
    <td>2</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>488</td>
    <td>Taiwan, Province of China</td>
    <td>Taiwan Mobile Co. Ltd</td>
    <td>466</td>
    <td>97</td>
    <td>UMTS 2100, LTE 1800, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>489</td>
    <td>Taiwan, Province of China</td>
    <td>Chunghwa Telecom</td>
    <td>466</td>
    <td>921</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>490</td>
    <td>Taiwan, Province of China</td>
    <td>CHUNGHWA TELECOM</td>
    <td>466</td>
    <td>923</td>
    <td>LTE 1800, LTE 2100, LTE 2600, LTE 900</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>491</td>
    <td>Taiwan, Province of China</td>
    <td>T STAR TAIWAN</td>
    <td>466</td>
    <td>893</td>
    <td>LTE 2600, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>492</td>
    <td>Tajikistan</td>
    <td>JV Somoncom</td>
    <td>436</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>493</td>
    <td>Tajikistan</td>
    <td>Indigo Tajikistan</td>
    <td>436</td>
    <td>2</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>494</td>
    <td>Tajikistan</td>
    <td>TACOM - BEELINE</td>
    <td>436</td>
    <td>53</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>495</td>
    <td>Tanzania, United Republic of</td>
    <td>Vodacom Tanzania Limited</td>
    <td>640</td>
    <td>4</td>
    <td>GSM 900, GSM 1800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>496</td>
    <td>Tanzania, United Republic of</td>
    <td>VODACOM TANSANIA</td>
    <td>640</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>497</td>
    <td>Thailand</td>
    <td>Advanced Wireless Network Company Ltd.</td>
    <td>520</td>
    <td>3</td>
    <td>UMTS 2100, LTE 1800, LTE 900, LTE 2100, GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>498</td>
    <td>Thailand</td>
    <td>TRUE MOVE H UNIVERSAL COMMUNICATION</td>
    <td>520</td>
    <td>1</td>
    <td>GSM, UMTS 2100, UMTS 850, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>499</td>
    <td>Thailand</td>
    <td>True Move H Universal Communication Company Ltd.</td>
    <td>520</td>
    <td>41</td>
    <td>UMTS 2100, LTE 1800, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>500</td>
    <td>Thailand</td>
    <td>AIS</td>
    <td>520</td>
    <td>33</td>
    <td>UTMS 2100, LTE 1800, LTE 2100, LTE 700, LTE 900</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>501</td>
    <td>Trinidad and Tobago</td>
    <td>TSTT</td>
    <td>374</td>
    <td>12</td>
    <td>GSM 1800, GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>502</td>
    <td>Trinidad and Tobago</td>
    <td>Digicel Trinidad and Tobago</td>
    <td>374</td>
    <td>130</td>
    <td>GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>503</td>
    <td>Trinidad and Tobago</td>
    <td>DIGICEL TRINIDAD</td>
    <td>374</td>
    <td>1303</td>
    <td>GSM 850, UMTS 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>504</td>
    <td>Tunisia</td>
    <td>Tunisie Telecom</td>
    <td>605</td>
    <td>21</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>505</td>
    <td>Tunisia</td>
    <td>ORANGE TUNISIE</td>
    <td>605</td>
    <td>13</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>506</td>
    <td>Turkey</td>
    <td>Türk Telekom</td>
    <td>286</td>
    <td>3</td>
    <td>GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>507</td>
    <td>Turkey</td>
    <td>TT MOBILE TURKEY</td>
    <td>286</td>
    <td>33</td>
    <td>GSM 1800, UMTS 2100, LTE 1800, LTE 2600, LTE 800, LTE 900, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>508</td>
    <td>Turks and Caicos Islands</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>509</td>
    <td>Turks and Caicos Islands</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>510</td>
    <td>Turks and Caicos Islands</td>
    <td>DIGICEL TURKS & CAICOS</td>
    <td>338</td>
    <td>503</td>
    <td>GSM 1900, GSM 900, LTE 1700, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>511</td>
    <td>Turks and Caicos Islands</td>
    <td>DIGICEL TURKS & CAICOS</td>
    <td>376</td>
    <td>3603</td>
    <td>Unknown</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>512</td>
    <td>Turks and Caicos Islands</td>
    <td>CABLE & WIRELESS, TURKS & CAICOS</td>
    <td>376</td>
    <td>3503</td>
    <td>GSM 1900, GSM 850, UMTS 850, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>513</td>
    <td>Uganda</td>
    <td>MTN Uganda</td>
    <td>641</td>
    <td>10</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>514</td>
    <td>Uganda</td>
    <td>AFRICELL UGANDA LIMITED</td>
    <td>641</td>
    <td>143</td>
    <td>GSM 1800, GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>515</td>
    <td>Uganda</td>
    <td>AIRTEL UGANDA</td>
    <td>641</td>
    <td>13</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>516</td>
    <td>Uganda</td>
    <td>AIRTEL UGANDA</td>
    <td>641</td>
    <td>223</td>
    <td>GSM 1800, GSM 900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>517</td>
    <td>Uganda</td>
    <td>MTN UGANDA</td>
    <td>641</td>
    <td>103</td>
    <td>GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>518</td>
    <td>Ukraine</td>
    <td>PRJSC “VF Ukraine"</td>
    <td>255</td>
    <td>1</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>519</td>
    <td>Ukraine</td>
    <td>lifecell LLC</td>
    <td>255</td>
    <td>6</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>520</td>
    <td>Ukraine</td>
    <td>KYIVSTAR JSC</td>
    <td>255</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>521</td>
    <td>United Arab Emirates</td>
    <td>Emirates Telecom Corp-ETISALAT</td>
    <td>424</td>
    <td>21</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>522</td>
    <td>United Arab Emirates</td>
    <td>Emirates Telecom Corp</td>
    <td>424</td>
    <td>2</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>523</td>
    <td>United Arab Emirates</td>
    <td>DU</td>
    <td>424</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>524</td>
    <td>United Kingdom</td>
    <td>Telefónica Europe</td>
    <td>234</td>
    <td>10</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, UMTS 900, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>525</td>
    <td>United Kingdom</td>
    <td>EE</td>
    <td>234</td>
    <td>30</td>
    <td>GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>526</td>
    <td>United Kingdom</td>
    <td>EE</td>
    <td>234</td>
    <td>33</td>
    <td>GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>527</td>
    <td>United Kingdom</td>
    <td>JT Group Limited</td>
    <td>234</td>
    <td>50</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>528</td>
    <td>United Kingdom</td>
    <td>HUTCHISON 3G UK LTD</td>
    <td>234</td>
    <td>203</td>
    <td>UMTS 2100, LTE 1500, LTE 1800, LTE 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>529</td>
    <td>United Kingdom</td>
    <td>VODAFONE UK</td>
    <td>234</td>
    <td>153</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>530</td>
    <td>United Kingdom</td>
    <td>EE UK</td>
    <td>234</td>
    <td>333</td>
    <td>GSM 1800, UMTS 2100, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>531</td>
    <td>United Kingdom</td>
    <td>EE UK</td>
    <td>234</td>
    <td>303</td>
    <td>GSM 1800, UMTS 2100, LTE 1800, LTE 2100, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>532</td>
    <td>United Kingdom</td>
    <td>AIRTEL</td>
    <td>234</td>
    <td>33</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800, LTE 2600, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>533</td>
    <td>United States</td>
    <td>AT&T</td>
    <td>310</td>
    <td>41</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>534</td>
    <td>United States</td>
    <td>AT&T</td>
    <td>310</td>
    <td>410</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>535</td>
    <td>United States</td>
    <td>AT&T MOBILITY</td>
    <td>310</td>
    <td>4103</td>
    <td>UMTS 1900, UMTS 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>536</td>
    <td>Uruguay</td>
    <td>AM Wireless Uruguay S.A.</td>
    <td>748</td>
    <td>10</td>
    <td>GSM 1900, LTE 1700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>537</td>
    <td>Uruguay</td>
    <td>Telefonica Uruguay</td>
    <td>748</td>
    <td>7</td>
    <td>GSM 1800, UMTS 1900, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>538</td>
    <td>Uruguay</td>
    <td>CLARO URUGUAY</td>
    <td>748</td>
    <td>103</td>
    <td>GSM 1900, UMTS 1900, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>539</td>
    <td>Uzbekistan</td>
    <td>Unitel LLC</td>
    <td>434</td>
    <td>41</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>540</td>
    <td>Uzbekistan</td>
    <td>UNITEL LLC (BEELINE)</td>
    <td>434</td>
    <td>43</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>541</td>
    <td>Vanuatu</td>
    <td>DIGICEL (VANUATU) LIMITED</td>
    <td>541</td>
    <td>53</td>
    <td>GSM 900, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>542</td>
    <td>Viet Nam</td>
    <td>Viettel</td>
    <td>452</td>
    <td>42</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>543</td>
    <td>Viet Nam</td>
    <td>VNPT INTERNATIONAL (VINAPHONE)</td>
    <td>452</td>
    <td>23</td>
    <td>GSM 1800, GSM 900, UMTS 2100, UMTS 900, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>544</td>
    <td>Virgin Islands, British</td>
    <td>Digicel</td>
    <td>338</td>
    <td>5</td>
    <td>UMTS</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>545</td>
    <td>Virgin Islands, British</td>
    <td>Digicel Bermuda</td>
    <td>338</td>
    <td>50</td>
    <td>LTE 700, UMTS</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>546</td>
    <td>Virgin Islands, British</td>
    <td>Digicel BVI</td>
    <td>348</td>
    <td>77</td>
    <td>UMTS 2100, LTE</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>

                    
                </table>
            </div>

        </div>




        <div class="text_section_main_listas_globais">
            <p><img src="/assets/img/listas-globais/chipesim-listas.svg" alt="Chip mockup p listas"/> Cobertura <span>chip virtual</span> mysimtravel</p>
        </div>


        <div class="content_table_listas_globais">

            <div class="search_table_listas_globais">
                <label for="search-pais-listas" class="label_listas">
                    Digite o país de destino:
                    <input type="text" class="input_listas_globais_esim" placeholder="Digite um país"/>
                </label>
            </div>

            <div class="table_listas_globais">
                <table id="result-esim">
                    <tr>
                    <th>#</th>
                    <th>País</th>
                    <th>Operadora</th>
                    <th>MCC</th>
                    <th>MNC</th>
                    <th>Tipo de rede</th>
                    <th>2G</th>
                    <th>3G</th>
                    <th>LTE</th>

                    </tr>

                    <tr>
    <td>1</td>
    <td>Afghanistan</td>
    <td>Telecom Development Company Afghanistan Ltd.</td>
    <td>GSM 900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>2</td>
    <td>Albania</td>
    <td>Telekom Albania</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>3</td>
    <td>Algeria</td>
    <td>Optimum Telecom Algérie Spa</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>4</td>
    <td>Andorra</td>
    <td>Servei De Tele. DAndorra</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>5</td>
    <td>Angola</td>
    <td>MOVICEL Telecommunications S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>6</td>
    <td>Angola</td>
    <td>UNITEL S.a.r.l.</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>7</td>
    <td>Anguilla</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>8</td>
    <td>Anguilla</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>9</td>
    <td>Anguilla</td>
    <td>Cable & Wireless</td>
    <td>GSM 850, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>10</td>
    <td>Antigua and Barbuda</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>11</td>
    <td>Antigua and Barbuda</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>12</td>
    <td>Antigua and Barbuda</td>
    <td>Cable & Wireless Caribbean Cellular (Antigua) Limited</td>
    <td>GSM 1800, GSM 850, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>13</td>
    <td>Argentina</td>
    <td>AMX Argentina S.A. - CLARO</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>14</td>
    <td>Argentina</td>
    <td>AMX Argentina S.A.</td>
    <td>GSM 1900, GSM 850, UMTS 850, UMTS 1900, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>15</td>
    <td>Armenia</td>
    <td>Ucom LLC</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>16</td>
    <td>Aruba</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>17</td>
    <td>Aruba</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>18</td>
    <td>Aruba</td>
    <td>Digicel Aruba</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>19</td>
    <td>Australia</td>
    <td>Telstra Corporation Limited</td>
    <td>UMTS 2100, LTE 1800, UMTS 850, LTE 700, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>20</td>
    <td>Australia</td>
    <td>Singtel Optus Proprietary Limited</td>
    <td>UMTS 2100, LTE 1800, LTE 700, UMTS 900, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>21</td>
    <td>Austria</td>
    <td>A1 Telekom Austria</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>22</td>
    <td>Azerbaijan</td>
    <td>Azercell</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>23</td>
    <td>Bahamas</td>
    <td>The Bahamas Telecommunications Company Ltd (BaTelCo)</td>
    <td>GSM 850, GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>24</td>
    <td>Bahrain</td>
    <td>Viva Bahrain</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>25</td>
    <td>Bangladesh</td>
    <td>Grameenphone Ltd.</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>26</td>
    <td>Barbados</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>27</td>
    <td>Barbados</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>28</td>
    <td>Barbados</td>
    <td>LIME (formerly known as Cable & Wireless)</td>
    <td>GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>29</td>
    <td>Belarus</td>
    <td>Mobile TeleSystems</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>30</td>
    <td>Belarus</td>
    <td>velcom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>31</td>
    <td>Belgium</td>
    <td>Belgacom Mobile</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>32</td>
    <td>Belgium</td>
    <td>Orange S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>33</td>
    <td>Belgium</td>
    <td>Telenet</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>34</td>
    <td>Belize</td>
    <td>Speednet Communications Limited</td>
    <td>LTE 700, CDMA2000 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>35</td>
    <td>Bermuda</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>36</td>
    <td>Bermuda</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>37</td>
    <td>Bosnia and Herzegovina</td>
    <td>BH Telecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>38</td>
    <td>Brazil</td>
    <td>Claro</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 2600, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>39</td>
    <td>Brazil</td>
    <td>TIM</td>
    <td>GSM 1800, UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>40</td>
    <td>Brazil</td>
    <td>TIM</td>
    <td>GSM 1800, UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>41</td>
    <td>Brazil</td>
    <td>TIM</td>
    <td>GSM 1800, UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>42</td>
    <td>Brunei Darussalam</td>
    <td>Data Stream Technology</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>43</td>
    <td>Brunei Darussalam</td>
    <td>B-Mobile Communications Sdn Bhd</td>
    <td>UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>44</td>
    <td>Bulgaria</td>
    <td>A1 Bulgaria</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>45</td>
    <td>Bulgaria</td>
    <td>BTC</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>46</td>
    <td>Cambodia</td>
    <td>Smart Axiata Co. Ltd</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>47</td>
    <td>Cambodia</td>
    <td>Viettel</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>48</td>
    <td>Canada</td>
    <td>TELUS Communications Inc.</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>49</td>
    <td>Canada</td>
    <td>TELUS Communications Inc.</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>50</td>
    <td>Canada</td>
    <td>Rogers Communications Canada Inc.</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE 1700, LTE 2100, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>51</td>
    <td>Canada</td>
    <td>Rogers Communications Canada Inc.</td>
    <td>GSM 1900, GSM 850, UMTS 1900, UMTS 850, LTE 1700, LTE 2100, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>52</td>
    <td>Cape Verde</td>
    <td>CVMóvel, S.A.</td>
    <td>GSM 900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>53</td>
    <td>Cayman Islands</td>
    <td>Digicel</td>
    <td>UMTS</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>54</td>
    <td>Cayman Islands</td>
    <td>Digicel Bermuda</td>
    <td>UMTS</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>55</td>
    <td>Cayman Islands</td>
    <td>Cable & Wireless (Cayman Islands) Limited</td>
    <td>GSM 850, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>56</td>
    <td>Chile</td>
    <td>Entel Telefonía Móvil S.A.</td>
    <td>LTE 2600, GSM 1900, LTE 700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>57</td>
    <td>Chile</td>
    <td>Claro Chile S.A.</td>
    <td>GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>58</td>
    <td>Chile</td>
    <td>Telefónica Móvil de Chile</td>
    <td>LTE 2600, GSM 850, GSM 1900, UMTS 850, LTE 700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>59</td>
    <td>China</td>
    <td>China Mobile</td>
    <td>GSM 900, GSM 1800, TD-LTE 2300, TD-LTE 2500, TD-SCDMA 1900, TD-SCDMA 2000, TD-LTE 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>60</td>
    <td>China</td>
    <td>China Unicom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 900, TD-LTE 2300, TD-LTE 2500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>61</td>
    <td>Colombia</td>
    <td>COMCEL S.A.</td>
    <td>LTE 2600, GSM 850, GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>62</td>
    <td>Congo, the Democratic Republic of the</td>
    <td>Africell RDC sprl</td>
    <td>GSM 900, GSM 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>63</td>
    <td>Costa Rica</td>
    <td>Claro CR Telecomunicaciones (Aló)</td>
    <td>GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>64</td>
    <td>Croatia</td>
    <td>A1 Hrvatska</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>65</td>
    <td>Cyprus</td>
    <td>Cyprus Telecommunications Authority</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>66</td>
    <td>Cyprus</td>
    <td>MTN Group</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>67</td>
    <td>Czech Republic</td>
    <td>O2 Czech Republic</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, CDMA 450</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>68</td>
    <td>Czech Republic</td>
    <td>Vodafone Czech Republic</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>69</td>
    <td>Denmark</td>
    <td>Telia</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>70</td>
    <td>Denmark</td>
    <td>TT-Netværket P/S</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>71</td>
    <td>Dominica</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>72</td>
    <td>Dominica</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>73</td>
    <td>Dominica</td>
    <td>Cable & Wireless</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>74</td>
    <td>Dominican Republic</td>
    <td>Compañía Dominicana de Teléfonos</td>
    <td>GSM 850, GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>75</td>
    <td>Ecuador</td>
    <td>Otecel S.A.</td>
    <td>GSM 850, GSM 1900, UMTS 850, LTE 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>76</td>
    <td>Egypt</td>
    <td>Orange Egypt</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>77</td>
    <td>Egypt</td>
    <td>Vodafone Egypt</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>78</td>
    <td>El Salvador</td>
    <td>CTE Telecom Personal, S.A. de C.V.</td>
    <td>GSM 1900, UMTS 1900, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>79</td>
    <td>Estonia</td>
    <td>Estonian Mobile Telecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>80</td>
    <td>Falkland Islands (Malvinas)</td>
    <td>Sure South Atlantic Ltd.</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>81</td>
    <td>Faroe Islands</td>
    <td>Faroese Telecom</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>82</td>
    <td>Finland</td>
    <td>TeliaSonera Finland Oyj</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 700, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>83</td>
    <td>Finland</td>
    <td>TeliaSonera Finland Oyj / Suomen Yhteisverkko Oy</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>84</td>
    <td>France</td>
    <td>Bouygues Telecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 700, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>85</td>
    <td>France</td>
    <td>Bouygues Telecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 700, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>86</td>
    <td>France</td>
    <td>Orange S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>87</td>
    <td>France</td>
    <td>Orange S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>88</td>
    <td>Gambia</td>
    <td>Africell</td>
    <td>GSM 900, GSM 1800, LTE, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>89</td>
    <td>Georgia</td>
    <td>Silknet</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>90</td>
    <td>Georgia</td>
    <td>MagtiCom</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>91</td>
    <td>Germany</td>
    <td>Telefónica Germany GmbH & Co. oHG</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>92</td>
    <td>Germany</td>
    <td>Telefónica Germany GmbH & Co. oHG</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>93</td>
    <td>Ghana</td>
    <td>Vodafone Group</td>
    <td>GSM 900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>94</td>
    <td>Gibraltar</td>
    <td>Gibtelecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>95</td>
    <td>Greece</td>
    <td>Wind Hellas Telecommunications S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>96</td>
    <td>Greece</td>
    <td>Vodafone Greece</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>97</td>
    <td>Greenland</td>
    <td>TELE Greenland A/S</td>
    <td>GSM 900, LTE 800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>98</td>
    <td>Grenada</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>99</td>
    <td>Grenada</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>100</td>
    <td>Grenada</td>
    <td>Cable & Wireless Grenada Ltd.</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>101</td>
    <td>Guatemala</td>
    <td>Telecomunicaciones de Guatemala, S.A.</td>
    <td>GSM 900, CDMA 1900, GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>102</td>
    <td>Haiti</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>103</td>
    <td>Haiti</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>104</td>
    <td>Holy See (Vatican City State)</td>
    <td>Telecom Italia S.p.A</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 1500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>105</td>
    <td>Honduras</td>
    <td>SERCOM HONDURAS S.A de C.V.</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>106</td>
    <td>Honduras</td>
    <td>Servicios de Comunicaciones de Honduras S.A. de C.V.</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>107</td>
    <td>Hong Kong</td>
    <td>SmarTone Mobile Communications Limited</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 850, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>108</td>
    <td>Hong Kong</td>
    <td>Hutchison Telecom</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>109</td>
    <td>Hungary</td>
    <td>Vodafone Magyarország Zrt.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>110</td>
    <td>Iceland</td>
    <td>Nova ehf</td>
    <td>UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>111</td>
    <td>India</td>
    <td>Chennai</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>112</td>
    <td>India</td>
    <td>Karnataka</td>
    <td>TD-LTE 2300, GSM 900, GSM 1800, LTE 1800, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>113</td>
    <td>India</td>
    <td>Andhra Pradesh and Telangana</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>114</td>
    <td>India</td>
    <td>Kolkata</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>115</td>
    <td>India</td>
    <td>Madhya Pradesh</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>116</td>
    <td>India</td>
    <td>Haryana</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>117</td>
    <td>India</td>
    <td>Mumbai</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>118</td>
    <td>India</td>
    <td>Uttar Pradesh (West)</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>119</td>
    <td>India</td>
    <td>Gujarat</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>120</td>
    <td>India</td>
    <td>Himachal Pradesh</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>121</td>
    <td>India</td>
    <td>Maharashtra</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>122</td>
    <td>India</td>
    <td>Kerala</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>123</td>
    <td>India</td>
    <td>Punjab</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>124</td>
    <td>India</td>
    <td>Delhi & NCR</td>
    <td>GSM 900, GSM 1800, LTE 2100, LTE 1800, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>125</td>
    <td>India</td>
    <td>Tamil Nadu</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>126</td>
    <td>India</td>
    <td>West Bengal</td>
    <td>GSM 900, LTE 1800, GSM 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>127</td>
    <td>India</td>
    <td>Bihar & Jharkhand</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>128</td>
    <td>India</td>
    <td>Orissa</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>129</td>
    <td>India</td>
    <td>Uttar Pradesh (East)</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>130</td>
    <td>India</td>
    <td>Jammu & Kashmir</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>131</td>
    <td>India</td>
    <td>Rajasthan</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>132</td>
    <td>India</td>
    <td>Assam</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>133</td>
    <td>India</td>
    <td>North East</td>
    <td>GSM 900, LTE 1800, LTE 2100, LTE 2300</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>134</td>
    <td>Indonesia</td>
    <td>PT Indonesian Satellite Corporation Tbk (INDOSAT)</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>135</td>
    <td>Indonesia</td>
    <td>Telkomsel Indonesia</td>
    <td>GSM 1800, GSM 900, LTE 1800, LTE 2100, LTE 2300, LTE 800, LTE 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>136</td>
    <td>Iraq</td>
    <td>Telecom Ltd</td>
    <td>GSM 900, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>137</td>
    <td>Ireland</td>
    <td>Eir Group plc</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>138</td>
    <td>Israel</td>
    <td>Partner Communications Company Ltd</td>
    <td>GSM 1800, GSM 900, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>139</td>
    <td>Italy</td>
    <td>Telecom Italia S.p.A</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 1500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>140</td>
    <td>Jamaica</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>141</td>
    <td>Jamaica</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>142</td>
    <td>Jamaica</td>
    <td>Cable & Wireless Communications</td>
    <td>GSM 850, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>143</td>
    <td>Japan</td>
    <td>NTT DoCoMo, Inc.</td>
    <td>UMTS 2100, LTE 1800, LTE 700, LTE 2100, LTE 850, TD-LTE 3500, LTE 1500, UMTS 1800, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>144</td>
    <td>Jordan</td>
    <td>Jordan Mobile Telephone Services</td>
    <td>GSM 900, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>145</td>
    <td>Kazakhstan</td>
    <td>Kcell JSC</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>146</td>
    <td>Kazakhstan</td>
    <td>KaR-Tel LLP</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, LTE 2100, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>147</td>
    <td>Kenya</td>
    <td>Telkom Kenya</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>148</td>
    <td>Korea, Republic of</td>
    <td>SK Telecom</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, LTE 2100, LTE 850, CDMA2000 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>149</td>
    <td>Kosovo</td>
    <td>Monaco Telecom</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>150</td>
    <td>Kosovo</td>
    <td>Telekom Srbija</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, TETRA</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>151</td>
    <td>Kuwait</td>
    <td>National Mobile Telecommunications</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>152</td>
    <td>Kyrgyzstan</td>
    <td>Alfa Telecom CJSC</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>153</td>
    <td>Latvia</td>
    <td>Latvian Mobile Telephone</td>
    <td>GSM 900, GSM 1800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>154</td>
    <td>Lebanon</td>
    <td>MIC 1</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>155</td>
    <td>Liechtenstein</td>
    <td>A1 Telekom Austria</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>156</td>
    <td>Lithuania</td>
    <td>Telia Lietuva</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, LTE 2600, LTE 2100</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>157</td>
    <td>Luxembourg</td>
    <td>Tango SA</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>158</td>
    <td>Luxembourg</td>
    <td>Orange S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>159</td>
    <td>Macao</td>
    <td>Companhia de Telecomunicações de Macau, S.A.R.L.</td>
    <td>GSM 900, GSM 1800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>160</td>
    <td>Macedonia, the Former Yugoslav Republic of</td>
    <td>ONE.VIP DOO</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>161</td>
    <td>Malaysia</td>
    <td>Maxis Communications Berhad</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>162</td>
    <td>Malaysia</td>
    <td>DiGi Telecommunications</td>
    <td>GSM 1800, UMTS 2100, LTE 1800, LTE 2600, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>163</td>
    <td>Malta</td>
    <td>Mobile Communications Limited</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>164</td>
    <td>Mauritius</td>
    <td>Cellplus Mobile Communications Ltd.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>165</td>
    <td>Mexico</td>
    <td>Radiomovil Dipsa S.A. de C.V. - Telcel</td>
    <td>GSM 1900, GSM 850, UMTS 850, LTE 1700, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>166</td>
    <td>Mexico</td>
    <td>América Móvil</td>
    <td>LTE 2600, GSM 1900, UMTS 850, LTE 1700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>167</td>
    <td>Moldova, Republic of</td>
    <td>Moldcell</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>168</td>
    <td>Moldova, Republic of</td>
    <td>Orange Moldova</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>169</td>
    <td>Moldova, Republic of</td>
    <td>Moldtelecom</td>
    <td>UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>170</td>
    <td>Monaco</td>
    <td>Monaco Telecom</td>
    <td>GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>171</td>
    <td>Mongolia</td>
    <td>Mobicom Corporation</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>172</td>
    <td>Montenegro</td>
    <td>MTEL CG</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>173</td>
    <td>Montserrat</td>
    <td>Cable & Wireless</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>174</td>
    <td>Morocco</td>
    <td>Ittissalat Al-Maghrib (Maroc Telecom)</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>175</td>
    <td>Mozambique</td>
    <td>Vodacom Mozambique, S.A.</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>176</td>
    <td>Myanmar</td>
    <td>Telenor Myanmar</td>
    <td>GSM 900, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>177</td>
    <td>Nepal</td>
    <td>Ncell Pvt. Ltd.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>178</td>
    <td>Netherlands</td>
    <td>KPN Mobile The Netherlands B.V.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>179</td>
    <td>Netherlands</td>
    <td>Vodafone Libertel B.V.</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>180</td>
    <td>Netherlands Antilles</td>
    <td>Curaçao Telecom N.V.</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>181</td>
    <td>New Zealand</td>
    <td>Spark New Zealand</td>
    <td>UMTS 2100, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>182</td>
    <td>Nicaragua</td>
    <td>Empresa Nicaragüense de Telecomunicaciones, S.A. (ENITEL) (América Móvil)</td>
    <td>GSM 1900, UMTS 850, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>183</td>
    <td>Nigeria</td>
    <td>9mobile</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>184</td>
    <td>Norway</td>
    <td>TeliaSonera Norge AS</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>185</td>
    <td>Oman</td>
    <td>Oman Telecommunications Company</td>
    <td>GSM 900, GSM 1800, LTE 1800, UMTS 900, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>186</td>
    <td>Pakistan</td>
    <td>Pakistan Telecommunication Mobile Ltd</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>187</td>
    <td>Palestinian, State of</td>
    <td>Palestine Cellular Communications Ltd.</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>188</td>
    <td>Panama</td>
    <td>América Móvil</td>
    <td>GSM 1900, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>189</td>
    <td>Panama</td>
    <td>Digicel Group</td>
    <td>UMTS 1900</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>190</td>
    <td>Paraguay</td>
    <td>AMX Paraguay S.A.</td>
    <td>GSM 1900, LTE 1700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>191</td>
    <td>Peru</td>
    <td>América Móvil Perú</td>
    <td>GSM 1900, UMTS 850</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>192</td>
    <td>Philippines</td>
    <td>Globe Telecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, UMTS 850, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>193</td>
    <td>Poland</td>
    <td>Polska Telefonia Komórkowa Centertel Sp. z o.o.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>194</td>
    <td>Poland</td>
    <td>Polkomtel Sp. z o.o.</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>195</td>
    <td>Portugal</td>
    <td>Telecomunicações Móveis Nacionais</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>196</td>
    <td>Portugal</td>
    <td>Vodafone Portugal</td>
    <td>GSM 900, UMTS 2100, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>197</td>
    <td>Puerto Rico</td>
    <td>AT&T</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>198</td>
    <td>Puerto Rico</td>
    <td>AT&T</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>199</td>
    <td>Puerto Rico</td>
    <td>América Móvil</td>
    <td>GSM 850, GSM 1900, UMTS 850, LTE 700, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>200</td>
    <td>Qatar</td>
    <td>ooredoo</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>201</td>
    <td>Romania</td>
    <td>Orange România</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>202</td>
    <td>Russian Federation</td>
    <td>Mobile TeleSystems</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 900, LTE 2100, TD-LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>203</td>
    <td>Russian Federation</td>
    <td>MegaFon PJSC</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>204</td>
    <td>Saint Kitts and Nevis</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>205</td>
    <td>Saint Kitts and Nevis</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>206</td>
    <td>Saint Kitts and Nevis</td>
    <td>Cable & Wireless St. Kitts & Nevis Ltd</td>
    <td>GSM 850, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>207</td>
    <td>Saint Lucia</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>208</td>
    <td>Saint Lucia</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>209</td>
    <td>Saint Lucia</td>
    <td>Cable & Wireless</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>210</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>211</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>212</td>
    <td>Saint Vincent and the Grenadines</td>
    <td>Cable & Wireless (St. Vincent & the Grenadines) Ltd</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>213</td>
    <td>San Marino</td>
    <td>Telecom Italia S.p.A</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 1500</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>214</td>
    <td>Satellite</td>
    <td>Iceland Telecom</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>215</td>
    <td>Saudi Arabia</td>
    <td>Saudi Telecom Company</td>
    <td>GSM 900, UMTS 2100, LTE 1800, LTE 700, UMTS 900, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>216</td>
    <td>Serbia</td>
    <td>Telekom Srbija</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, TETRA</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>217</td>
    <td>Seychelles</td>
    <td>Cable & Wireless Seychelles</td>
    <td>GSM 900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>218</td>
    <td>Sierra Leone</td>
    <td>Lintel Sierra Leone Limited</td>
    <td>GSM 900, UMTS 2100, GSM 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>219</td>
    <td>Singapore</td>
    <td>Singapore Telecom</td>
    <td>UMTS 2100, LTE 1800, LTE 2600, UMTS 900, LTE 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>220</td>
    <td>Slovakia</td>
    <td>O2 Slovakia</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, TD-LTE 3500, TD-LTE 3700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>221</td>
    <td>Slovenia</td>
    <td>A1 Slovenija</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>222</td>
    <td>South Africa</td>
    <td>Vodacom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>223</td>
    <td>Spain</td>
    <td>Orange Espagne S.A.U</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>224</td>
    <td>Sri Lanka</td>
    <td>Mobitel (Pvt) Ltd</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>225</td>
    <td>Suriname</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>226</td>
    <td>Suriname</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>227</td>
    <td>Suriname</td>
    <td>Digicel Suriname</td>
    <td>GSM 1800, GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>228</td>
    <td>Sweden</td>
    <td>TeliaSonera Sverige AB</td>
    <td>GSM 900, GSM 1800, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>229</td>
    <td>Sweden</td>
    <td>Svenska UMTS-Nät AB</td>
    <td>UMTS 2100, GSM 900, GSM 1800, LTE 1800, LTE 800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>230</td>
    <td>Switzerland</td>
    <td>Sunrise Communications AG</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>231</td>
    <td>Switzerland</td>
    <td>Salt Mobile SA</td>
    <td>GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>232</td>
    <td>Syrian Arab Republic</td>
    <td>MTN Syria</td>
    <td>GSM 900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>233</td>
    <td>Taiwan, Province of China</td>
    <td>Taiwan Mobile Co. Ltd</td>
    <td>UMTS 2100, LTE 1800, LTE 700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>234</td>
    <td>Tajikistan</td>
    <td>JV Somoncom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>235</td>
    <td>Tajikistan</td>
    <td>Indigo Tajikistan</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>236</td>
    <td>Tanzania, United Republic of</td>
    <td>Vodacom Tanzania Limited</td>
    <td>GSM 900, GSM 1800, LTE 1800, 3G 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>237</td>
    <td>Thailand</td>
    <td>Advanced Wireless Network Company Ltd.</td>
    <td>UMTS 2100, LTE 1800, LTE 900, LTE 2100, GSM 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>238</td>
    <td>Trinidad and Tobago</td>
    <td>TSTT</td>
    <td>GSM 1800, GSM 1900, UMTS 850, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>239</td>
    <td>Trinidad and Tobago</td>
    <td>Digicel Trinidad and Tobago</td>
    <td>GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>240</td>
    <td>Tunisia</td>
    <td>Orange Tunisie</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>241</td>
    <td>Turkey</td>
    <td>Türk Telekom</td>
    <td>GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>242</td>
    <td>Turks and Caicos Islands</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>243</td>
    <td>Turks and Caicos Islands</td>
    <td>Cable & Wireless West Indies Ltd (Turks & Caicos)</td>
    <td>GSM 850</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>244</td>
    <td>Turks and Caicos Islands</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900, UMTS</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>245</td>
    <td>Uganda</td>
    <td>MTN Uganda</td>
    <td>GSM 900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>246</td>
    <td>Ukraine</td>
    <td>PRJSC “VF Ukraine"</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>247</td>
    <td>Ukraine</td>
    <td>lifecell LLC</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>248</td>
    <td>United Arab Emirates</td>
    <td>Emirates Telecom Corp</td>
    <td>GSM 900, UMTS 2100, LTE 800, LTE 1800, LTE 2600</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
</tr>
<tr>
    <td>249</td>
    <td>United Kingdom</td>
    <td>Telefónica Europe</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, UMTS 900, LTE 2100, TD-LTE 2300</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>250</td>
    <td>United Kingdom</td>
    <td>EE</td>
    <td>GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, LTE 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>251</td>
    <td>United Kingdom</td>
    <td>EE</td>
    <td>GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>252</td>
    <td>United Kingdom</td>
    <td>JT Group Limited</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 800, LTE 1800, LTE 2600, UMTS 900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>253</td>
    <td>United States</td>
    <td>AT&T</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>254</td>
    <td>United States</td>
    <td>AT&T</td>
    <td>UMTS 1900, UMTS 850, LTE 1700, LTE 1900, LTE 2100, LTE 700, LTE 850</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>255</td>
    <td>Uruguay</td>
    <td>AM Wireless Uruguay S.A.</td>
    <td>GSM 1900, LTE 1700, UMTS 1900</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>256</td>
    <td>Uruguay</td>
    <td>Telefonica Uruguay</td>
    <td>GSM 1800, UMTS 1900, LTE 1700</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>257</td>
    <td>Uzbekistan</td>
    <td>Coscom</td>
    <td>GSM 900, GSM 1800, UMTS 2100</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
    <td>Yes</td>
    <td>Yes</td>
</tr>
<tr>
    <td>258</td>
    <td>Viet Nam</td>
    <td>Viettel Telecom</td>
    <td>GSM 900, GSM 1800, UMTS 2100, LTE 1800</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>259</td>
    <td>Virgin Islands, British</td>
    <td>Digicel</td>
    <td>GSM 900, GSM 1900, GSM 1800</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>260</td>
    <td>Virgin Islands, British</td>
    <td>Digicel Bermuda</td>
    <td>GSM 900, GSM 1800, GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
<tr>
    <td>261</td>
    <td>Virgin Islands, British</td>
    <td>Digicel BVI</td>
    <td>GSM 1900</td>
    <td>Yes</td>
    <td>No</td>
    <td>No</td>
    <td>Yes</td>
    <td>No</td>
</tr>
                    
                </table>
            </div>

        </div>


    {/* <div class="text_content_end_page_listas">
        <h1>Informações importantes</h1>
        <ul>
            <li>Alterações na lista de operadoras podem ser efetuadas sem aviso prévio.</li>
            <li>A velocidade de conexão LTE/4G/3G/2G pode variar dependendo das coberturas disponíveis na região de conexão</li>
            <li>Em países onde houver mais de uma operadora disponível, o Chip mysimtravel se conecta automaticamente sem a</br>necessidade de intervenção manual.</li>
            <li>Nas listas apresentadas estão listados os países que possuem disponibilidade dos serviços de VOZ, SMS e DADOS</li>
        </ul> */}
    
        
    </div>

    <Footer />
    </div>
  )
}

export default lista_global_operadoras;