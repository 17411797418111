import { Link } from "react-router-dom";
import "./index.css";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const navigate = useNavigate();

  function animation() {
    const openNavMenu = document.querySelector(".open-nav-menu"),
      closeNavMenu = document.querySelector(".close-nav-menu"),
      navMenu = document.querySelector(".nav-menu"),
      menuOverlay = document.querySelector(".menu-overlay"),
      mediaSize = 991;

    /*  openNavMenu.addEventListener("click", toggleNav);
  closeNavMenu.addEventListener("click", toggleNav);
  // close the navMenu by clicking outside */

    function toggleNav() {
      navMenu.classList.toggle("open");
      menuOverlay.classList.toggle("active");
      document.body.classList.toggle("hidden-scrolling");
    }

    navMenu.addEventListener("click", (event) => {
      if (
        event.target.hasAttribute("data-toggle") &&
        window.innerWidth <= mediaSize
      ) {
        // prevent default anchor click behavior
        event.preventDefault();
        const menuItemHasChildren = event.target.parentElement;
        // if menuItemHasChildren is already expanded, collapse it
        if (menuItemHasChildren.classList.contains("active")) {
          collapseSubMenu();
        } else {
          // collapse existing expanded menuItemHasChildren
          if (navMenu.querySelector(".menu-item-has-children.active")) {
            collapseSubMenu();
          }
          // expand new menuItemHasChildren
          menuItemHasChildren.classList.add("active");
          const subMenu = menuItemHasChildren.querySelector(".sub-menu");
          subMenu.style.maxHeight = subMenu.scrollHeight + "px";
        }
      }
    });
    function collapseSubMenu() {
      navMenu
        .querySelector(".menu-item-has-children.active .sub-menu")
        .removeAttribute("style");
      navMenu
        .querySelector(".menu-item-has-children.active")
        .classList.remove("active");
    }
    function resizeFix() {
      // if navMenu is open ,close it
      if (navMenu.classList.contains("open")) {
        toggleNav();
      }
      // if menuItemHasChildren is expanded , collapse it
      if (navMenu.querySelector(".menu-item-has-children.active")) {
        collapseSubMenu();
      }
    }

    window.addEventListener("resize", function () {
      if (this.innerWidth > mediaSize) {
        resizeFix();
      }
    });
    toggleNav();
  }

  function link_to_faq() {
    navigate("/");
    setTimeout(() => {
      let coordinates = document
        .getElementById("faq_homepage")
        .getBoundingClientRect();
      window.scrollTo(coordinates.x, coordinates.y);
    }, 100);
  }

  return (
    <header
      class="header black-pantone"
      id="container-header1"
      style={{ backgroundColor: props.backgroundcolor }}
    >
      {/* <div class="container">  */}

      <div class="header-main black-pantone" id="container-header2">
        <div
          class="open-nav-menu"
          onClick={() => {
            animation();
          }}
        >
          <span></span>
        </div>

        <Link to="/">
          <div class="logo-header">
            <img src="/assets/img/logolaranja.svg" alt="Logo-img" />
          </div>
        </Link>

        <nav class="nav-menu">
          <Link to="/">
            <div
              class="close-nav-menu"
              onClick={() => {
                animation();
              }}
            >
              <img src="/assets/img/close.svg" alt="close" />
            </div>
          </Link>
          <ul class="menu">
            <li class="menu-item menu-item-has-children">
              <Link to="/">Início</Link>
            </li>
            <li className="menu-item li">
              <Link to="/comprar" className="link-comprar">
                Comprar
              </Link>
            </li>

            <li class="menu-item menu-item-has-children">
              <a data-toggle="sub-menu" id="teste">
                Como Funciona <img src="/assets/img/vector-select.svg"></img>
              </a>
              <ul class="sub-menu">
                <li class="menu-item liclass">
                  {" "}
                  <Link to="/como-funciona" className="tag_a">
                    Configurações
                  </Link>{" "}
                </li>

                <li class="menu-item liclass" onClick={link_to_faq}>
                  {" "}
                  <Link to="" className="tag_a">
                    FAQ
                  </Link>{" "}
                </li>

                <li class="menu-item liclass">
                  <Link to="/recarga-foryou" className="tag_a">
                    Aplicativo
                  </Link>{" "}
                </li>

                <li class="menu-item liclass">
                  <Link to="/voz-sms" className="tag_a">
                    Tarifas
                  </Link>{" "}
                </li>
                <li class="menu-item liclass">
                  <Link to="/lista-global-de-operadoras" className="tag_a">
                    Listas Globais
                  </Link>{" "}
                </li>
              </ul>
            </li>
            {/* <li class="menu-item">
              <Link to="/m2m-iot">M2M & IoT</Link>
            </li> */}

            <li class="menu-item">
              <Link to="/solucoes-corporativas">Corporativo</Link>
            </li>
            <li class="menu-item menu-item-has-children">
              <a data-toggle="sub-menu">
                Onde Comprar <img src="/assets/img/vector-select.svg"></img>
              </a>
              <ul class="sub-menu">
                <li class="menu-item">
                  {" "}
                  <Link to="/distribuidores">Revendas Autorizadas</Link>{" "}
                </li>
                <li class="menu-item">
                  {" "}
                  <Link to="/chip-internet-aeroportos">
                    Retirada aeroporto
                  </Link>{" "}
                </li>
              </ul>
            </li>

            <li class="menu-item">
              <Link to="/SejaUmaRevenda">Seja uma Revenda</Link>
            </li>
          </ul>
        </nav>

        <a href="https://foryou.mysimtravel.com/">
          <button class="recarga-header">Recarga</button>
        </a>
      </div>
      {/*  </div>  */}
    </header>
  );
}
