import './index.css';
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import { Link } from "react-router-dom"

function webDocs(){
  return( 
    <>
        <Header backgroundcolor="#2D2D2F" color="black"/>

        <div className='container-webDocs'>


          

              <div className='body_web_docs'>
                  <h1>Políticas de uso e privacidade do site</h1>
              

                <div className='body_web_docs_links'>
                    <Link to="/privacidade"> <div className='link_web_docs'> Políticas de privacidade da informação SGMobile Brasil</div> </Link> 
                    <Link to="/termos-e-condicoes"> <div className='link_web_docs'>Políticas de Termos de uso do site</div> </Link> 
                </div>


              </div>

          
    </div>
    <Footer />
</>

  )
}

export default webDocs;