import './index.css';
import './responsive-smsvoz.css';
import Header from "../../components/header/index";
import Footer from "../../components/footer/index"

function vozSms(){
  setTimeout(() =>{
    document.querySelector('.input_listas_globais-tarifas').addEventListener('input', function(){

        let input = this.value.toLowerCase();
        let result = document.querySelectorAll('#result tr');
        
        result.forEach(function(row){
        if(row.textContent.toLowerCase().indexOf(input) > -1){
        row.style.display = 'table-row';
        
        }else{
        row.style.display = 'none';
        
        }
        });
    
      });
     
    
    
    
     document.querySelector('.input_listas_globais_esim-tarifas').addEventListener('input', function(){
    
    let input = this.value.toLowerCase();
    let result = document.querySelectorAll('#result-esim tr');
        
    result.forEach(function(row){
    if(row.textContent.toLowerCase().indexOf(input) > -1){
    row.style.display = 'table-row';
    row.classList.add("row-styled");
    }else{
    row.style.display = 'none';
    row.classList.remove("row-styled");
    }
    });
    
    }); 
    
},100)


  return(
    <>
    <Header/>
    <div class="content_header_listas_operadoras-tarifas">
            <div class="text_listas_operadoras_header-tarifas">
                <h1 class="h1_header_listas-tarifas">
                    Tarifas de Voz e SMS
                </h1>
    
                <p class="p_listas_operadoras_header-tarifas">
                    o mysimtravel possui cobertura no mundo todo.
                </p>
    
                <p class="p_listas_operadoras_header-tarifas">
                    São mais de 300 operadoras e cobertura em +180 países.
                </p>
            </div>
        </div>
    
        <div class="section_main_listas_globais-tarifas">
            <h2 className="info-h1-mapa">Para saber mais sobre as tarifas de <span className='orange_word'>VOZ e SMS</span>, entre em contato com a nossa equipe de atendimento.</h2>
        
            <ul className='tarifas-page-ul'>
              <li><b>Chip Internacional mysimtravel – Serviços de VOZ e SMS sob consulta.</b></li>  
              <li><b>Chip Internacional mysimtravel-simfinity – Somente dados. Serviços de VOZ e SMS não disponível.</b></li>
            </ul> 
                   
        </div>
    
        <Footer/>
        </>
  )
}

export default vozSms;