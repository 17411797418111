import "./index.css";
import "./responsive-config.css";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import { Link } from "react-router-dom";

function ComoFunciona() {
 /* MOSTRAR DIV DAS OPÇÕES DE CONFIGURAÇÕES DO IPHONE */
  function exhibit_settings_iphone() {
    let divPrincipal = document.querySelector(".marcas-container-config");
    let divMostrar1 = document.querySelector("#iphone");

    divPrincipal.style.display = "none";
    divMostrar1.style.display = "block";
  }
 
 /* -----------------------QQQ----------------------------------- */


 /* MOSTRAR DIV DAS OPÇÕES DE CONFIGURAÇÕES DA SAMSUNG */
 function exhibit_settings_samsung() {
  let divPrincipal = document.querySelector(".marcas-container-config");
  let divMostrar1 = document.querySelector("#samsung12");

  divPrincipal.style.display = "none";
  divMostrar1.style.display = "block";
}

/* -----------------------QQQ----------------------------------- */


/* MOSTRAR DIV DAS OPÇÕES DE CONFIGURAÇÕES DA XIAOMI */
function exhibit_settings_motorola() {
  let divPrincipal = document.querySelector(".marcas-container-config");
  let divMostrar1 = document.querySelector("#motorola");

  divPrincipal.style.display = "none";
  divMostrar1.style.display = "block";
}

/* -----------------------QQQ----------------------------------- */

/* VOLTAR PARA A SELEÇÃO DE MARCAS */
function back_to_brand_selection(div_to_hide) {console.log("oieee")
  let divPrincipal = document.querySelector(".marcas-container-config");
  let hide_div = document.querySelector(div_to_hide);

  divPrincipal.style.display = "block";
  hide_div.style.display = "none";
}

/* -----------------------QQQ----------------------------------- */




  return (
    <div className="container-pageconfig">
      <Header backgroundcolor="#2d2d2f"/>

      <div class="container-page">
        <div class="boxtext-container">
          <h1>Vamos te auxiliar na instalação do mysimtravel</h1>
          <p>
            Siga nossas orientações para usar o mysimtravel no dia de sua viagem
          </p>
        </div>

        <div class="boxlogo-container">
          <img
            src="/assets/img/mockup-celular.svg"
            alt="Mockup Celulares"
            class="logo-celulares"
            id="img-principal"
          />
          <img
            src="/assets/img/mockupsamsung.svg"
            alt="Mockup Samsung"
            class="mockup-samsung"
            id="img-samsung"
          />
        </div>
      </div>

      
        <div class="marcas-container-config">
          <div class="textmarcas">
            <h1>
              Configurando seu celular com o{" "}
              <span class="span1">mysimtravel</span>
            </h1>
            <p> Selecione abaixo o modelo do seu celular : </p>
          </div>

          <div class="marcas">
            <div
              class="logo  apple"
              id="iphonebtn"
              onClick={exhibit_settings_iphone}
              >
              <i class="bx bxl-apple"></i>
            </div>

            <div 
            class="logo samsung" 
            id="samsungbtn"
            onClick={exhibit_settings_samsung}
            >
              <img src="/assets/img/samsung.svg" alt="Samsung" />
            </div>

            
            <div className="logo xiaomi" id="xiaomi">
            <Link to="/xiaomi">
              <img src="/assets/img/xiaomi.svg" alt="Xiaomi" />
              </Link>
            </div>
            
            
            
         
       

            <div class="logo motorola" 
            id="motorolabtn"
            onClick={exhibit_settings_motorola}
            >
              <img src="/assets/img/motorola.svg" alt="Motorola" />
            </div>

            {/* <div class="logo huawei">
                <img src="./assets/img/huawei.svg" alt="Huawei">
            </div>

            <div class="logo google">
                <img src="./assets/img/google.svg" alt="Google">
            </div>  */}
          </div>
        
      

        {/* <div class="text-randow" >
        <a href="#">EM BREVE</a>
    </div>  */}

        <div class="ul-content">
          <h1>Saiba também sobre:</h1>

          <ul>
            <li>
              <a href="./recarga-foryou">
                Como funciona o app mysimtravel FORYOU
              </a>
            </li>
            <li>
              <a href="./lista-global-de-operadoras">Conheça nossa lista global de operadoras</a>
            </li>
            <li>
              <a href="./iphone11">Dicas de economia de dados no Iphone</a>
            </li>
            <li>
              <a href="./voz-sms">Tarifas de Voz e SMS</a>
            </li>
          </ul>
        </div>
      </div>
      

      <div class="celular-iphone" id="iphone">
        <div class="boxtext">
          <div className="settings-routes-hw">
            
            <div onClick={() => back_to_brand_selection("#iphone")}>Configuração</div> {">"} <span class="span2">Modelos</span>
         
          </div>
          <h1>
            Configurando seu celular com o {""}
            <span class="span1">mysimtravel</span>
          </h1>
          <h2>Selecione abaixo o modelo: </h2>
        </div>

        <div class="grid-iphone" id="grid-iphone">
       

           <Link to="/iphone7+">
            <div class="iphone7 cardphone" id="iphone7">
              <img src="/assets/img/iphone-7.svg" alt="Iphone 7" />
              <p>Iphone 7 | 8 | SE | X</p>
            </div>
          </Link>


          
          <Link to="/iphoneSE">
            <div class="iphoneX cardphone" id="iphoneX">
              <img src="/assets/img/iphone-x.svg" alt="Iphone X" />
              <p>Iphone SE (2020) | Xs | XR</p>
            </div>
          </Link>
         
          
          
          <Link to="/iphone11">
            <div class="iphone11 cardphone" id="iphone11">
              <img src="/assets/img/iphone-11.svg" alt="Iphone 11" />
              <p>Iphone 11 | 12 | 13+ </p>
            </div>
          </Link>
       
        </div>

        <div class="celular-textinfo">
          <p>
            Caso não saiba o modelo do seu celular, vá em{" "}
            <span class="span2">
              Ajustes {">"} Geral {">"} Sobre {">"} Nome do modelo
            </span>{" "}
            e você vai encontrar a informação do seu celular. Ou entre em
            contato com o nosso CHAT para tirar suas dúvidas
          </p>
        </div>

        {/* <div className="button_voltar">
          <button class="voltar" id="btn2" onClick={return_settings_iphone}>
            Retornar
          </button>
        </div> */}
     
      </div>


      <div class="celular-motorola" id="motorola">
      <div class="boxtext">
        
      <div className="settings-routes-hw">
         <div onClick={() => back_to_brand_selection("#motorola")}>Configuração</div> {">"} <span class="span2">Modelos</span>
      </div>

        <h1>
          Configurando seu celular com o <span class="span1">mysimtravel</span>
        </h1>
        <h2>Selecione abaixo o modelo:</h2>
      </div>

      <div class="grid-motorolag">

        <Link to="/motog">
        <div class="motorolag cardphone" id="motorolag">
          <img src="/assets/img/motorola/mockup-inicial-motorola.svg" alt="motorola-g" />
          <p>Motorola modelos G e Edge</p>
        </div>
        </Link>

        <Link to="/motorazr">
        <div class="motorolarazr cardphone" id="motorola-razr">
          <img src="/assets/img/motorola/razr-motorola.svg" alt="razr" />
          <p>Motorola Razr</p>
        </div>
        </Link>

      </div>

      <div class="celular-textinfo">
        <p>
          Caso não saiba o modelo do seu celular, vá em
          <span class="span2">Configurações {">"} Sistema {">"} Sobre o Telefone</span> e
          você vai encontrar a informação do seu celular. Ou entre em contato
          com o nosso CHAT para tirar suas dúvidas
        </p>
      </div>

      {/* <div className="button_voltar">
          <button class="voltar" onClick={return_settings_motorola}>
          Retornar
        </button>
        </div> */}
     

      {/* <!-- <a href="#" id="btnVoltar">voltar</a> --> */}
    </div>

      <div class="celular-samsung" id="samsung12">
        <div class="boxtext-samsung boxtext">


         <div className="settings-routes-hw">
          <div onClick={() => back_to_brand_selection("#samsung12")}>Configuração</div> {">"} <span class="span2">Modelos</span>
        </div>


          <h1>
            Configurando seu celular com o{" "}
            <span class="span1">mysimtravel</span>
          </h1>
          <h2>Selecione abaixo o modelo: </h2>
        </div>

       
        <div class="grid-samsung">
        <Link to="/galaxy">
          <div class="galaxy cardphone" id="linha-s">
            <img src="/assets/img/samsunggalaxy.svg" alt="Samsung Galaxy" />
            <p>Samsung modelos Galaxy</p>
          </div>
        </Link>

        <Link to="/ja">
          <div class="linhaj cardphone">
            <img src="/assets/img/samsungJ.svg" alt="Samsung J" />
            <p>Samsung modelos J | A</p>
          </div>
        </Link>

        </div>

        <div class="celular-textinfo">
          <p>
            Caso não saiba o modelo do seu celular, vá em{" "}
            <span class="span2">Configurações {">"} sobre o telefone</span> e
            você vai encontrar a informação do seu celular. Ou entre em contato
            com o nosso CHAT para tirar suas dúvidas
          </p>
        </div>


        {/* <div className="button_voltar">
            <button class="voltar" id="retornar-samsung" onClick={return_settings_samsung}>
            Retornar
          </button>
        </div> */}
       
      </div>

      <Footer />
    </div>
  );
}

export default ComoFunciona;
