import "./index.css";
import "./responsive-xiaomi.css";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import { Link, useNavigate } from "react-router-dom";

export default function Xiaomi() {
  const navigate = useNavigate();
  


  function link_to_models(){
    navigate("/como-funciona");  
  }
  
  function animation() {
    // CHIP FÍSICO
    let samsungModels = document.querySelector("#samsung-models"); //CONFIGURAÇÃO INICIAL
    let btnModels = document.querySelector("#btnmodels"); // Botão config inicial
    let samsungApp = document.querySelector("#samsungapp"); // APP físico

    let samsungRecharge = document.querySelector("#samsungteste");
    let btnRecharge = document.querySelector("#rechargeteste");

    // retornar

    let btnReturn = document.querySelector("#btnreturn");
    let containerSettings = document.querySelector("#containersettings"); // TELA DO CHIP FÍSICO

    // dicas btn

    containerSettings.style.display = "flex";

    // ------------------------------------------------------------------------

    // CHIP FÍSICO FUNÇÕES

    btnModels.addEventListener("click", function () {
      samsungModels.style.display = "none";
      samsungApp.style.display = "block";
      samsungRecharge.style.display = "none";

      document.getElementById("h1text1").style.color = "#2d2d2d";
      document.getElementById("h1text2").style.color = "#ff5000";
      document.getElementById("h1text3").style.color = "#2d2d2d";
    });

    btnRecharge.addEventListener("click", function () {
      samsungModels.style.display = "none";
      samsungApp.style.display = "none";
      samsungRecharge.style.display = "block";

      document.getElementById("h1text1").style.color = "#2d2d2d";
      document.getElementById("h1text2").style.color = "#2d2d2d";
      document.getElementById("h1text3").style.color = "#ff5000";
      document.getElementById("h1textdicas").style.color = "#2d2d2d";
    });

    btnReturn.addEventListener("click", function () {
      samsungModels.style.display = "block";
      samsungApp.style.display = "none";
      samsungRecharge.style.display = "none";

      document.getElementById("h1text1").style.color = "#ff5000";
      document.getElementById("h1text2").style.color = "#2d2d2d";
      document.getElementById("h1text3").style.color = "#2d2d2d";
      document.getElementById("h1textdicas").style.color = "#2d2d2d";
    });
  }

  function accordionIphone(id) {
    const accordionItemHeaders = document.getElementById(`${id}`);
    console.log(accordionItemHeaders);

    accordionItemHeaders.classList.toggle("active");
    const accordionItemBody = accordionItemHeaders.nextElementSibling;
    if (accordionItemHeaders.classList.contains("active")) {
      accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
    } else {
      accordionItemBody.style.maxHeight = 0;
    }
  }

  setTimeout(() => {
    animation();
  }, 10);

  return (
    <div>
      <Header backgroundcolor="#2d2d2f"/>
      <div class="content-config">
        <div className="text_mobile_iphone7_top">
          <h1>Xiaomi</h1>
          <h2>Tutorial para configuração dos aparelhos Xiaomi</h2>
          <p>Suporte e dicas para configurar seu celular antes da viagem</p>
        </div>
        <div class="config-div">
          <div class="config-text">
            <h1>Xiaomi</h1>
            <h2>Tutorial para configuração dos aparelhos Xiaomi</h2>
            <p>
              Suporte e dicas para configurar seu celular antes da sua viagem
            </p>
          </div>

          <div class="config-samsung2">
            <img
              src="/assets/img/config-samsung/samsung-mockupconfig.svg"
              alt="Mockup config Iphone"
            />
          </div>
        </div>
      </div>

      <div class="container-config">
        <div class="links-config">
          <Link to="/como-funciona">Configuração</Link> <span> {">"} </span> <div onClick={link_to_models}>Modelos </div>  <span> {">"} </span>  <a href="inicialconfiguracoes.html" class="spanlink"> Xiaomi </a>
        </div>

        <div class="config-content">
          <h1 class="textprimary">
            Configure seu Xiaomi para utilizar o{" "}
            <span class="span-orange">mysimtravel</span>
          </h1>

          <div class="flex-grid">
            <div class="fisico" id="divfisico">
              <h1 id="chipfisico">Chip Físico</h1>
            </div>
          </div>
        </div>

        <div class="settings js-scroll" id="containersettings">
          <div class="settings-text">
            <div class="inicial ativo" id="btnreturn">
              <h1 class="text-h1" id="h1text1">
                Configuração inicial
              </h1>
            </div>

            <div class="app" id="btnmodels">
              <h1 id="h1text2">Ativação pelo app mysimtravel FOR YOU</h1>
            </div>

            <div class="recarga" id="rechargeteste">
              <h1 id="h1text3">Recarga</h1>
            </div>
          </div>

          <div class="settings-models" id="samsung-models">
            <h1 class="roaming">Ativação Roaming</h1>
            <div class="settings-instru">
              <div class="config-1">
                <h1>
                  1 - Após Inserir o mysimtravel no seu celular vá na tela de{" "}
                  <span>Configurações</span> e depois{" "}
                  <span>Cartões SIM e redes móveis</span>
                </h1>
                <div class="imgconfig-1">
                  <img
                    src="/assets/img/img-xiaomi/inicial-1.svg"
                    alt="Print config 1"
                  />
                </div>
              </div>
              <div class="config-2">
                <h1>
                  2 - Ative o roaming de dados em <span>Roaming de dados</span>{" "}
                  e logo em seguida selecione <span>SEMPRE</span>
                </h1>
                <div class="imgconfig-2">
                  <img
                    src="/assets/img/img-xiaomi/inicial-2.svg"
                    alt="Print config 2"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-3.svg"
                    alt="Print config 3"
                  />
                </div>
              </div>

              <div class="config-3">
                <h1>
                  3 - Volte novamente em Cartões SIM e selecione o{" "}
                  <span>Elisa EE</span> depois{" "}
                  <span>Nomes de pontos de acesso</span> Verifique se o APN é o{" "}
                  <span>Elisa Internet</span>
                </h1>
                <div class="imgconfig-3">
                  <img
                    src="/assets/img/img-xiaomi/inicial-4.svg"
                    alt="Print config 5"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-5.svg"
                    alt="Print config 6"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-6.svg"
                    alt="Print config 6"
                  />
                </div>
              </div>

              <div class="config-3">
                <h1>
                  4 - Volte nas configurações de cartão sim e vá em{" "}
                  <span>Rede Preferencial</span> e selecione{" "}
                  <span>LTE Preferencial</span>
                </h1>
                <div class="imgconfig-3">
                  <img
                    src="/assets/img/img-xiaomi/inicial-7.svg"
                    alt="Print config 5"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-8.svg"
                    alt="Print config 6"
                  />
                </div>
              </div>

              <div class="config-3">
                <h1>
                  5 - Feito! Agora veja como você faz a ativação do seu plano no
                  dia de sua viagem aqui
                </h1>
              </div>
            </div>
          </div>

          <div class="settings-app" id="samsungapp">
            <h1 class="configapp">Configuração Aplicativo</h1>
            <div class="settings-appins">
              <div class="app-1">
                <h1>
                  1 - Faça o login com{" "}
                  <span>
                    email (ou nº do chip) e a senha cadastrada no ato da compra
                  </span>
                </h1>
                <div class="imgapp-1">
                  <img
                    src="/assets/img/config-samsung/linha-j/app-1.svg"
                    alt="Print app 1"
                  />
                </div>
              </div>
              <div class="app-2">
                <h1>
                  2 - Nessa primeira tela você confere o seu saldo e o seu
                  consumo (quando o plano estiver ativado) E para ativar o plano
                  selecione <span>Tarifas</span>
                </h1>
                <div class="imgapp-2">
                  <img
                    src="/assets/img/config-samsung/linha-j/app-2.svg"
                    alt="Print app 2"
                  />
                </div>
              </div>

              <div class="app-3">
                <h1>
                  3 - Insira o seu país de destino (caso vá para mais de um
                  país, insira o primeiro destino e selecione o plano que foi
                  adquirido na compra) Você pode pesquisar na{" "}
                  <span>Barra de pesquisa</span> ou{" "}
                  <span>procurar na lista de países</span>
                </h1>
                <div class="imgapp-3">
                  <img
                    src="/assets/img/config-samsung/linha-j/app-3.svg"
                    alt="Print app 3"
                  />
                </div>
              </div>

              <div class="app-3">
                <h1>
                  4 - Selecione o seu plano que foi adquirido (caso não se
                  lembre, olhe o email na <span>etapa 2 ou 3)</span>
                </h1>
                <div class="imgapp-3">
                  <img
                    src="/assets/img/config-samsung/linha-j/app-4.svg"
                    alt="Print app 3"
                  />
                  <img
                    src="/assets/img/config-samsung/linha-j/app-5.svg"
                    alt="Print app 4"
                  />
                </div>
              </div>

              <div class="app-3">
                <h1>
                  5 -{" "}
                  <span>
                    Atenção: esse procedimento deve ser feito somente no dia da
                    viagem
                  </span>
                  <br />
                  Após selecionar o plano, arraste o botão ao lado, confirme os
                  países de cobertura e ative seu plano
                </h1>
                <div class="imgapp-3">
                  <img
                    src="/assets/img/config-samsung/linha-j/app-6.svg"
                    alt="Print app 6"
                  />
                  <img
                    src="/assets/img/config-samsung/linha-j/app-7.svg"
                    alt="Print app 7"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="recarga-setting" id="samsungteste">
            <h1 class="configrecargas">Recarga</h1>
            <div class="settings-recarga1">
              <div class="recarga-1">
                <h1>
                  1 - Na tela inicial, vá em <span>Comprar créditos</span> no
                  menu inferior
                </h1>
                <div class="imgrecarga-1">
                  <img
                    src="/assets/img/config-samsung/linha-j/recarga-1.svg"
                    alt="Print app 1"
                  />
                </div>
              </div>
              <div class="recarga-2">
                <h1>2 - Selecione o nº do chip que gostaria de recarregar</h1>
                <div class="imgapp-2">
                  <img
                    src="/assets/img/config-samsung/linha-j/recarga-2.svg"
                    alt="Print app 2"
                  />
                </div>
              </div>

              <div class="recarga-3">
                <h1>
                  3 - Insira o valor de acordo com o{" "}
                  <span>plano que deseja recarregar</span> e os dados do cartão
                  de crédito, após isso basta confirmar e seu crédito vai estar
                  disponivel em no máximo 5 minutos
                </h1>
                <div class="imgapp-3">
                  <img
                    src="/assets/img/config-samsung/linha-j/recarga-3.svg"
                    alt="Print app 3"
                  />
                </div>

                <h1>
                  Pronto, agora basta renovar o seu plano novamente, qualquer
                  dúvida te ajudamos por <a href="#">aqui</a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view_mobile">
        <h1 className="mobile_text_paragrafo">
          Configure seu Xiaomi para utilizar o <span>mysimtravel</span>{" "}
        </h1>
        <div className="accordion-iphone7">
          <div className="accordion-item-iphone7">
            <div
              className="accordion-item-header-iphone7"
              id="configinicialiphone7"
              onClick={() => {
                accordionIphone("configinicialiphone7");
              }}
            >
              <h1>Configuração inicial</h1>
            </div>
            <div className="accordion-item-body-iphone7">
              <div className="accordion-item-body-content-iphone7">
                <div className="accordion-item1">
                  <div className="mobile_config_iphone7">
                    <h1>Ativação em Roaming</h1>

                    <div className="div_mobile_iphone7">
                      <p>
                      1 - Após Inserir o mysimtravel no seu celular vá na tela de{" "}
                  <span className="mobile_iphone7_span">Configurações</span> e depois{" "}
                  <span className="mobile_iphone7_span">Cartões SIM e redes móveis</span>
                      </p>

                      <div className="img_mobile_iphone7">
                      <img
                    src="/assets/img/img-xiaomi/inicial-1.svg"
                    alt="Print config 1"
                  />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                      2 - Ative o roaming de dados em <span className="mobile_iphone7_span">Roaming de dados</span>{" "}
                  e logo em seguida selecione <span className="mobile_iphone7_span">SEMPRE</span>
                      </p>

                      <div className="img_mobile_iphone7">
                      <img
                    src="/assets/img/img-xiaomi/inicial-2.svg"
                    alt="Print config 2"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-3.svg"
                    alt="Print config 3"
                  />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                      3 - Volte novamente em Cartões SIM e selecione o{" "}
                  <span className="mobile_iphone7_span">Elisa EE</span> depois{" "}
                  <span className="mobile_iphone7_span">Nomes de pontos de acesso</span> Verifique se o APN é o{" "}
                  <span className="mobile_iphone7_span">Elisa Internet</span>
                      </p>

                      <div className="img_mobile_iphone7">
                      <img
                    src="/assets/img/img-xiaomi/inicial-4.svg"
                    alt="Print config 5"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-5.svg"
                    alt="Print config 6"
                  />
                  <img
                    src="/assets/img/img-xiaomi/inicial-6.svg"
                    alt="Print config 6"
                  />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
              

        <div className="accordion-iphone7">
          <div className="accordion-item-iphone7">
            <div
              className="accordion-item-header-iphone7"
              id="ativacaopeloapp7"
              onClick={() => {
                accordionIphone("ativacaopeloapp7");
              }}
            >
              <h1>Ativação pelo app mysimtravel FORYOU</h1>
            </div>
            <div className="accordion-item-body-iphone7">
              <div className="accordion-item-body-content-iphone7">
                <div className="accordion-item1">
                  <div className="mobile_config_iphone7">
                    <h1>Ativação pelo aplicativo</h1>

                    <div className="div_mobile_iphone7">
                      <p>
                        1 - Faça o login com o email (ou n° do chip) e a senha
                        cadastrada no ato da compra
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/app-1.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                        2 - Nessa primeira tela você confere o seu saldo e o seu
                        consumo (quando o plano estiver ativado) E para ativar o
                        plano selecione Tarifas
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/app-2.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                        3 - Insira o seu país de destino (caso vá para mais de
                        um país, insira o primeiro destino e selecione o plano
                        que foi adquirido na compra) Você pode pesquisar na{" "}
                        <span className="mobile_iphone7_span">
                          Barra de pesquisa
                        </span>{" "}
                        ou{" "}
                        <span className="mobile_iphone7_span">
                          procurar na lista de países
                        </span>
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/app-3.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                        4 - Selecione o seu plano que foi adquirido (caso não se
                        lembre, olhe o email na etapa 2 ou 3)
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/app-4.svg"
                          alt="Print app 1"
                        />

                        <img
                          src="/assets/img/config-samsung/linha-j/app-5.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                        5 - Atenção: esse procedimento deve ser feito somente no
                        dia da viagem Após selecionar o plano, arraste o botão
                        ao lado, confirme os países de cobertura e ative o seu
                        plano
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/app-6.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-iphone7">
          <div className="accordion-item-iphone7">
            <div
              className="accordion-item-header-iphone7"
              id="recargaapp7"
              onClick={() => {
                accordionIphone("recargaapp7");
              }}
            >
              <h1>Recarga</h1>
            </div>
            <div className="accordion-item-body-iphone7">
              <div className="accordion-item-body-content-iphone7">
                <div className="accordion-item1">
                  <div className="mobile_config_iphone7">
                    <h1>Recarga</h1>

                    <div className="div_mobile_iphone7">
                      <p>
                        1 - Na tela inicial, vá em{" "}
                        <span className="mobile_iphone7_span">
                          Comprar Créditos
                        </span>{" "}
                        no menu anterior
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/recarga-1.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                        2 - Selecione o nº do chip que gostaria de recarregar
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/recarga-2.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>

                    <div className="div_mobile_iphone7">
                      <p>
                        3 - Insira o valor de acordo com o plano que deseja
                        recarregar e os dados do cartão de crédito, após isso
                        basta confirmar e seu crédito vai estar disponivel em no
                        maximo 5 minutos
                      </p>

                      <div className="img_mobile_iphone7">
                        <img
                          src="/assets/img/config-samsung/linha-j/recarga-3.svg"
                          alt="Print app 1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
