import "./index.css";
import "./responsive-sejarevenda.css";
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import axios from "axios";
import Reaptcha from 'reaptcha';
import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

export default function Seja_uma_revenda(){
  const captchaRef = useRef(null);

  const error = (message) => toast.error(message);


  async function send_mail(){
    let username = document.getElementById("username").value
    let agency_name = document.getElementById("agency_name").value;
    let cnpj = document.getElementById("cnpj").value;
    let email = document.getElementById("email").value;
    let message = document.getElementById("textarea").value;

    if(username === '' || agency_name === '' || cnpj === '' || email === '' || message === ''){
      error("Todos os campos são obrigatórios."); return
    }


     await axios.post("https://api.staticforms.xyz/submit",{//jhen's access key
      name:username,
      accessKey:"a242eef8-013e-43cb-9c43-871ebcdd2a1a",
      $Nome_da_Agência:agency_name,
      email:email,
      $CNPJ:cnpj,
      message:message,
      subject:"Formulário Seja uma Revenda do site MySimtravel."
    }) 

    await axios.post("https://api.staticforms.xyz/submit",{//jean's access key
    name:username,
    accessKey:"9c436fd3-b6dd-49bd-8f88-6f1bd4bb9a0a",
    $Nome_da_Agência:agency_name,
    email:email,
    $CNPJ:cnpj,
    message:message,
    subject:"Formulário Seja uma Revenda do site MySimtravel."
  }) 

  }


  const verify = () =>{
    captchaRef.current.getResponse().then(res => {
        let button = document.getElementById("button_sent");
        button.style.display = "block"
    })

}



return (
    <div>
      <ToastContainer />
      <Header  backgroundcolor="#2d2d2f"/>
    <div className="seja_uma_revenda">
      <div className="text_seja_uma_revenda">
        <h1>Seja uma Revenda Autorizada mysimtravel</h1>
        <p>Tenha disponível o melhor chip internacional para seu cliente.</p>
        <p>Agregue mais valor à sua agência sendo um parceiro mysimtravel</p>
      </div>

      <div className="img_seja_uma_revenda">
        <img src="/assets/img/mockup-chiprevenda.svg" alt="CHIP MOCKUP" />
      </div>
    </div>

    

    <div className="main-sejaumarevenda">
      <div className="container_info_revenda">
        <h1 className="primeiro_h1_topo">
          Por que fazer parte do maior rede de chip internacional no Brasil ?
        </h1>
        <div className="info_2015">
          <h1>2015</h1>
          <p>
            Foi o ano de nascimento do mysimtravel, contando com apenas <br />
            poucas revendas franqueadas.
          </p>
        </div>

        <div className="info_700">
          <p>
            Desde então foi se fortalecendo como marca e hoje conta com mais de
            <br />
            revendas no Brasil e no mundo.
          </p>
          <h1>+700</h1>
        </div>

        <div className="info_2022">
          <h1>2022</h1>
          <p>
            Hoje é a marca mais utilizada entre os viajantes com melhor índice
            <br />
            de atendimento e suporte a parceiros e clientes, contando com uma
            <br />
            base de +70mil clientes que usaram e usam o mysimtravel.
          </p>
        </div>

        <div className="info_ultimo_texto">
          <h1>O que nós oferecemos ?</h1>
          <p className="p_ultimo_texto">
            Treinamento qualificado para sua equipe, loja personalizada para
            vendas, estoque<br />local*, logística por nossa conta, oferecer o
            que há de mais novo no mercado o <span>eSIM</span>, <br />
            portal de gestão para acompanhar as vendas e disponibilidade do
            cliente retirar <br/>nos aeroportos conveniados.
          </p>
          <p className="sujeito_a_disponibilidade">*Sujeito a disponibilidade dos chips</p>
        </div>
      </div>


      <div className="form_seja_uma_revenda">
        <h1 className="h1_seja_uma_revenda">Faça o seu cadastro e seja uma <br/>Revenda Autorizada mysimtravel!</h1>

        <form action="https://api.staticforms.xyz/submit" method="post" className="form_seja_uma_revenda">
            
            <input type="hidden" name="accessKey" value="297ee6a7-18f9-47db-9713-6195585c79c9"></input>{/* fernando's access key */}
            <input type="hidden" name="redirectTo" value="https://mysimtravel.com.br/SejaUmaRevenda"/>

            <input type="text" id="username" name="name" placeholder="Seu nome" required/>
            
            
            <input type="text" id="agency_name" name="$Nome da Agência" placeholder="Nome da agência" required/>
            
            
            <input type="text" id="cnpj" name="$CNPJ" placeholder="CNPJ" required/>

            
            <input type="email" id="email" name="email" placeholder="E-mail" required/><br/>

            <input type="hidden" name="subject" value="Formulário Seja uma Revenda do site MySimtravel." required></input>
            
            
            <textarea id="textarea" name="message" rows="10" cols="30" placeholder="Digite o seu texto" required></textarea>
           
            <Reaptcha onVerify={verify} sitekey="6LeIe0AkAAAAABO9U7cLdUbZAVY36Pwb2etFIwx3" ref={captchaRef}/>
            <button id="button_sent" class="enviar_seja_uma_revenda" onClick={send_mail}>Enviar Mensagem</button>
          </form> 
          
          <p class="p_form_seja_uma_revenda">Ao confirmar, você autoriza a mysimtravel a armazenar e processar  os dados pessoais preenchidos <br/>para a finalidade informada.  Para
        saber mais, acesse nosso <Link to="/informacoes-mysimtravel">Termo de Uso</Link></p>

      </div>


    </div>

    <Footer/>
    </div>
)
}