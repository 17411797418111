import './index.css';
import './responsive-grupodicas.css';
import Header from "../../components/header";
import Footer from "../../components/footer"





function DicasToViagens(){

  return(
   <div className='main'>
    <Header backgroundcolor="#2d2d2f"/>
       
        <div className="container-section-text">
          <h1>mysimtravel & Grupo Dicas</h1>
          <div className="paragrafos-section-text">
          <p>Aproveite ao máximo sua viagem com dicas incríveis de Grupo Dicas</p>
          <p>Fique sempre online com o chip internacional mysimtravel</p>
          </div>
        </div>


        <div className='article-grupodicas'>
          <p>O Grupo Dicas é a maior rede de blogs de viagens do Brasil. Todos focados nos destinos mais visitados do mundo. São mais de 25 blogs de todos os destinos turísticos do mundo, somando mais de 2 milhões de acessos por mês. Em parceria com o mysimtravel,  você fica conectado em qualquer destino e ainda aproveita o melhor do país com ótimas dicas! </p>
        </div>


        <div className="grid-dicas">

          <div className="cancun" data-aos="fade-right">
            <div>
              <img src="/assets/img/cancun.svg" alt="Cancun Img" className="country-image" />
            </div>
            <h1>Cancun</h1>
            <a href="https://mystore.mysimtravel.com/dicas-de-cancun" className="link-webstore-cancun">Clique aqui para garantir seu chip internacional</a>
          </div>

          <div className="canada" data-aos="fade-right">
            <div>
              <img src="/assets/img/canada.svg" alt="Canada Img" className="country-image"/>
            </div>
            <h1>Canadá</h1>
            <a href="https://mystore.mysimtravel.com/dicas-do-canada" className="link-webstore-canada">Clique aqui para garantir seu chip internacional</a>
          </div>

          <div className="colombia" data-aos="fade-right">
            <div>
              <img src="/assets/img/colombia.svg" alt="Cancun Img" className="country-image"/>
            </div>
            <h1 className="h1-dicas">Colômbia</h1>
            <a href="https://mystore.mysimtravel.com/dicas-do-mercosul" className="link-webstore-cancun">Clique aqui para garantir seu chip internacional</a>
          </div>

          <div className="Italia" data-aos="fade-left">
            <div>
              <img src="/assets/img/italia.svg" alt="Cancun Img" className="country-image"/>
            </div>
            <h1>Itália</h1>
            <a href="https://mystore.mysimtravel.com/dicas-da-europa" className="link-webstore-cancun">Clique aqui para garantir seu chip internacional</a>
          </div>

          <div className="Chile" data-aos="fade-left">
            <div>
              <img src="/assets/img/chile.svg" alt="Canada Img" className="country-image"/>
            </div>
            <h1>Chile</h1>
            <a href="https://mystore.mysimtravel.com/dicas-do-mercosul" className="link-webstore-canada">Clique aqui para garantir seu chip internacional</a>
          </div>

          <div className="amsterdan" data-aos="fade-left">
            <div>
              <img src="/assets/img/amnsterda.svg" alt="Cancun Img" className="country-image"/>
            </div>
            <h1>Amsterdã</h1>
            <a href="https://mystore.mysimtravel.com/dicas-da-europa" className="link-webstore-cancun">Clique aqui para garantir seu chip internacional</a>
          </div>
        </div>


        <div className="logo-dicas-sg">
          <img src="/assets/img/logo-orange.svg" alt="LOGO"/>
        </div>
      


    <Footer/>
  </div>
  )
}

export default DicasToViagens;