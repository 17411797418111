import './index.css'

function NotFound(){
  return(
    <div>
       <div class="div_main_chipquebrado">
        <div class="chip-img">
            <img src="/assets/img/chip-quebrado.svg" alt="Chip quebrado"/>
        </div>

        <div class="text_chip_quebrado">
            <h1>404</h1>
            <h2>Opa! Parece que alguma coisa deu errado!</h2>
            <p>Nos Desculpe, mas não encontramos a página que você procura</p>
            <a href="/"><button class="button_chip_quebrado">Voltar</button></a>
        </div>
    </div>

    </div>
  )
}

export default NotFound;