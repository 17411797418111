export function creating_box_of_country(selected_country, selected_countries) {

  let newDiv = document.createElement("div");
  newDiv.classList.add("country_box");
  newDiv.setAttribute("id", "country_box");

  let country_name_div = document.createElement("div");
  let new_content = document.createTextNode(selected_country);
  country_name_div.appendChild(new_content);

  let button_close = document.createElement("button");
  button_close.classList.add("button_country_box");
  let image_button = document.createTextNode("X");

  button_close.onclick = () => {
    selected_countries.splice(selected_countries.indexOf(selected_country), 1); //here i'm deleting the country
    newDiv.style.display = "none";
  };
  button_close.appendChild(image_button);

  newDiv.appendChild(country_name_div);
  newDiv.appendChild(button_close);

  let current_div = document.getElementById("before");
  var parentDiv = document.getElementById("selected_countries");
  parentDiv.insertBefore(newDiv, current_div); //a current_div precisa ser filha da parentdiv
}







export function clean_datas_inserted_per_user() {
  let countries = document.getElementsByClassName("country_box");
  for (let item of countries) {
    item.style.display = "none";
  }

  let check_box_virtual_or_physical = document.getElementsByClassName(
    "input-selectmodelchip"
  );
  check_box_virtual_or_physical[0].style.display = "none";

  var cellphone_model = document.getElementById("models");
  cellphone_model.value = "";

  let radios = document.getElementsByName("chip");
  radios.forEach((element) => {
    element.checked = false;
  });

}


export function clear_datas_of_last_search(){
  document.getElementById("search_historic").style.display = "none";
  
  document.getElementById("send-text-physical-chip").style.display = "none";
  document.getElementById("send-text-virtual-chip").style.display = "none";

  document.getElementById("exhibiting_plans").style.display = "none";
}






export function animation(id) {
  const accordionItemHeaders = document.getElementById(`${id}`);

  accordionItemHeaders.classList.toggle("active");
  const accordionItemBody = accordionItemHeaders.nextElementSibling;
  if (accordionItemHeaders.classList.contains("active")) {
    accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
  } else {
    accordionItemBody.style.maxHeight = 0;
  }
}





export function physycal_accordion_home() {
  let accordionFisicoshome = document.querySelector("#section_chip_fisico_home");
  let accordionVirtuaishome = document.querySelector("#section_chip_virtual_home");

  accordionFisicoshome.style.display = "block";
  accordionVirtuaishome.style.display = "none";

  document.getElementById("option-chip-virtual-home").style.borderColor = "#c2c2c2";
  document.getElementById("text-option-virtual-home").style.color = "#c2c2c2";
  document.getElementById("option-chip-fisico-home").style.borderColor ="#ff5000";
  document.getElementById("text-option-fisico-home").style.color = "#ff5000";
}





export function virtual_accordion_home() {
  let accordionFisicoshome = document.querySelector("#section_chip_fisico_home");
  let accordionVirtuaishome = document.querySelector("#section_chip_virtual_home");

  accordionFisicoshome.style.display = "none";
  accordionVirtuaishome.style.display = "block";

  document.getElementById("option-chip-fisico-home").style.borderColor ="#c2c2c2";
  document.getElementById("text-option-fisico-home").style.color = "#c2c2c2";
  document.getElementById("option-chip-virtual-home").style.borderColor ="#ff5000";
  document.getElementById("text-option-virtual-home").style.color = "#ff5000";
}





export function exhibit_chip_checkbox() {
  var selectOponente = document.getElementById("models");

  selectOponente.addEventListener("change", function () {
    if(this.value === "true/S" || this.value === "true/I") {//if the smartphone model equals the true,(if the model of my smartphone accept esim) my code will exhibit the options of chip
      let check_box = document.getElementsByClassName("input-selectmodelchip");
      check_box[0].style.display = "block";

    }else{
      let check_box = document.getElementsByClassName("input-selectmodelchip");
      check_box[0].style.display = "none";
    }
  });
}





export function change_send_text(chip_type) {
  let text_for_virtual_chip = document.getElementById("send-text-virtual-chip");
  let text_for_physical_chip = document.getElementById("send-text-physical-chip");

  chip_type === "Chip virtual (eSIM)" ? (() => { text_for_physical_chip.style.display = "none"; text_for_virtual_chip.style.display = "block";})()
                                      : (() => { text_for_virtual_chip.style.display = "none"; text_for_physical_chip.style.display = "block";})();
}





export function exhibit_search_historic(selected_countries,cellphone_model,chip_type) {
  clear_elements();
  let parent_div = document.getElementById("search_historic");

  createElement(`País(es) pesquisado(s): ${selected_countries}`); //paragraph_to_show_countries_selected
  createElement(`Modelo de celular: ${cellphone_model}`);//paragraph_to_show_cellphone_model
  createElement(`Tipo do chip: ${chip_type}`);//paragraph_to_show_chip_type

  parent_div.style.display = "block";
}



const createElement = (content) => {//THIS FUNCTION WILL CREATE THE ELEMENT THAT CONTAIN THE CONTENT OF SEND TEXT
  let parent_div = document.getElementById("search_historic");

  let paragraph_to_show_the_value = document.createElement("p");
  let paragraph_content = document.createTextNode(content);
  paragraph_to_show_the_value.appendChild(paragraph_content);

  parent_div.appendChild(paragraph_to_show_the_value);
};



const clear_elements = () => {
  let parent_div = document.getElementById("search_historic");
  parent_div.innerHTML = "";
};





export function hide_send_text() {
  document.getElementById("send-text-physical-chip").style.display = "none";
  document.getElementById("send-text-virtual-chip").style.display = "none";
}

export function hide_search_historic() {
  document.getElementById("search_historic").style.display = "none";
}
